import revive_payload_client_FLXygG8xrL from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NPPoX8HRvz from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7olx5wr0Ae from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SfqTNdYWMa from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.0_@types+node@18.19.78_jiti@2.4.2_sass@_bbd58c998d09005b046829176efb2de6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import presets_1E1FiRAPD2 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@_071b26bb18b7f7337cd3835532e9931f/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_I8yvcM1nce from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@_071b26bb18b7f7337cd3835532e9931f/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import payload_client_lHW3Zh0663 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qHQZGsoMCf from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_i8g6Bylhaa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_E9deX6WVhC from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mvj85xSm4B from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_7IiUWf7C0H from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_LUOtx7AgMv from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@unhead+vue@1.11.20_vue@3.5.13_typescript@5.8.2___h3@1.15.1_mag_1805ab412b62c5874197ec1832fe8de2/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_iZMTQlAuPl from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.10_@unhead+vue@1.11.20_vue@3.5.13_typescript@5.8.2___h3@1.15.1_mag_1805ab412b62c5874197ec1832fe8de2/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_MSH4M1hyUg from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.34.9_vite@6.2.0_@types+node@18._866d7050a22b3fda7273de3bc5492c05/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_Otn14GUh9X from "/opt/build/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_magicast@0.3.5_rollup@4.34.9_vite@6.2.0_@types+node@18._866d7050a22b3fda7273de3bc5492c05/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import i18n_dpSJohHndd from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.34.9_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_n1d2dJcPon from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2_61cc00d7f3a972a974767d1f77606fba/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_gzxR4Kc1eB from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2_61cc00d7f3a972a974767d1f77606fba/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_xP25pzuNAE from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@6.2_61cc00d7f3a972a974767d1f77606fba/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_fxr5QpR3De from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_aQXdyAKhyp from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.0_@types+node@18.19.78_jiti@2.4.2_sass@1.85.1_44177effd4bca3a0f62b5a588cee76cb/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_XQWKhlICpu from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@_071b26bb18b7f7337cd3835532e9931f/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import ui_rjNXKDZLQ9 from "/opt/build/repo/plugins/ui.ts";
export default [
  revive_payload_client_FLXygG8xrL,
  unhead_NPPoX8HRvz,
  router_7olx5wr0Ae,
  _0_siteConfig_SfqTNdYWMa,
  presets_1E1FiRAPD2,
  variables_I8yvcM1nce,
  payload_client_lHW3Zh0663,
  navigation_repaint_client_qHQZGsoMCf,
  check_outdated_build_client_i8g6Bylhaa,
  chunk_reload_client_E9deX6WVhC,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7IiUWf7C0H,
  titles_LUOtx7AgMv,
  defaults_iZMTQlAuPl,
  siteConfig_MSH4M1hyUg,
  inferSeoMetaPlugin_Otn14GUh9X,
  i18n_dpSJohHndd,
  slideovers_n1d2dJcPon,
  modals_gzxR4Kc1eB,
  colors_xP25pzuNAE,
  plugin_client_fxr5QpR3De,
  plugin_aQXdyAKhyp,
  scrollbars_client_XQWKhlICpu,
  i18n_VfGcjrvSkj,
  ui_rjNXKDZLQ9
]