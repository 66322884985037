<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import InputWithTokenMetadata from '~/components/pro/molecules/Form/InputWithTokenMetadata.vue';

interface Props {
  title: string;
  subtitle: string;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'outline' | 'none';
  align?: 'left' | 'right' | 'center';
  disabled?: boolean;
  displayValue?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  variant: 'none',
  align: 'left',
  disabled: false
});

const emit = defineEmits(['update:modelValue']);

function handleInputChange (value: number) {
  emit('update:modelValue', value);
}

const amountModel = defineModel();
const modelValue = computed({
  get: () => amountModel.value ? toValue(amountModel) : props.displayValue,
  set: (value: number) => handleInputChange(value)
});
</script>

<template>
  <UFormGroup :class="['u-border-gray-300 w-full rounded-md border p-2', { 'flex flex-col items-center': align === 'center' }]">
    <template #label>
      <div class="px-3 text-base">
        {{ title }}
      </div>
    </template>
    <dl class="w-full">
      <InputWithTokenMetadata
        ref="inputAmount"
        v-model="modelValue"
        :size="size"
        :variant="variant"
        :align="align"
        class="pb-0"
        :disabled="disabled"
        eager-validation
        @changed="handleInputChange"
      />
      <dd :class="['u-text-gray-500 px-3 text-xs font-medium', { 'flex flex-col items-center': align === 'center' }]">
        {{ subtitle }}
      </dd>
    </dl>
  </UFormGroup>
</template>
