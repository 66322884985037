import type { z } from 'zod';
import { defineStore, storeToRefs } from 'pinia';
import {
  Amount,
  SwapStatus,
  PartiallyMatchedOrder,
  initMarket,
  getInitializedMarkets,
  DexEvent,
  DexEventType,
  estimateMarketSwap,
  SwapAmount,
  marketSwap,
  getOrderbookBalances
} from 'hydra-node';
import { AppMode, PiniaStoresId, SwapProgressState } from '~/enums';
import { useSettingsStore } from '~/stores/shared/settings';
import type { MappedDexTradingBalance } from '~/types/asset';
import type { SwapZodContext } from '~/types/ZSchemas/swap';
import { SwapSchema } from '~/constants/ZSchemas/swap';
import { useHydraNodeStore } from '~/stores/hydra-node';
import type { PartialOrder, SwapPairProgress } from '~/types/Swap';

export const useSwapStore = defineStore(PiniaStoresId.SwapStore, () => {
  const settingsStore = useSettingsStore();
  const hydraStore = useHydraNodeStore();

  const { t } = useI18n();
  const toast = useToast();
  const alertBuilder = useAlertBuilder();

  const { uiMode } = storeToRefs(settingsStore);
  const { isDexInitialized } = storeToRefs(hydraStore);
  const lastFetched = ref<number>(0);
  const swapMax = ref<boolean>(false);

  const tradingBalances = ref<MappedDexTradingBalance[]>([]);

  async function fetchTradingBalances () {
    if (!isDexInitialized.value) {
      return;
    }
    try {
      const balances = await getOrderbookBalances();
      tradingBalances.value = Array.from(balances, ([asset, balance]) => ({
        asset,
        balance
      })) as unknown as MappedDexTradingBalance[];
      lastFetched.value = Date.now();
    } catch (error) {
      console.error('Error fetching trading balances:', error);
    }
  }

  // Reactive state
  const pendingSwap = ref<SwapPairProgress>({
    partiallyMatchedOrders: undefined,
    status: undefined,
    fee: 0,
    startingTimestamp: undefined,
    endingTimestamp: undefined
  });
  const isFetchingEstimate = ref(false);

  // Form state
  const formState = reactive({
    selectedFromAsset: null as MappedDexTradingBalance | null,
    selectedToAsset: null as MappedDexTradingBalance | null,
    selectedFromAmount: 0,
    selectedToAmount: 0,
    slippage: 0
  });

  const swapTime = computed(() => {
    return pendingSwap.value.endingTimestamp && pendingSwap.value.startingTimestamp
      ? (pendingSwap.value.endingTimestamp - pendingSwap.value.startingTimestamp) / 1000
      : 0;
  });

  // Computed properties
  const swapStatus = computed(() => {
    if (!pendingSwap) {
      return SwapProgressState.NULL;
    }
    switch (pendingSwap.value?.status) {
      case SwapStatus.OrderMatched:
      case SwapStatus.PayingInvoiceReceived:
      case SwapStatus.PaymentReceived:
      case SwapStatus.PaymentSent:
      case SwapStatus.ReceivingInvoiceCreated:
        return SwapProgressState.STARTED;
      case SwapStatus.SwapFailed:
        return SwapProgressState.FAILED;
      case SwapStatus.PaymentClaimed:
      case SwapStatus.SwapCompleted:
        return SwapProgressState.COMPLETED;
      default:
        return SwapProgressState.NULL;
    }
  });

  const swapToAssetBalancesOptions = computed(() => {
    const selectedFrom = formState.selectedFromAsset;
    return selectedFrom
      ? tradingBalances.value?.filter(asset => asset.asset.id !== selectedFrom.asset.id || asset.asset.network.id !== selectedFrom.asset.network.id)
      : tradingBalances.value;
  });

  const computedSchemaObject = computed(() => {
    const context: SwapZodContext = {
      isEasyMode: uiMode.value === AppMode.EASY,
      fromAsset: formState.selectedFromAsset,
      toAsset: formState.selectedToAsset,
      t
    };
    return SwapSchema(context);
  });

  const watchedValues = computed(() => ({
    fromAsset: formState.selectedFromAsset,
    fromAmount: formState.selectedFromAmount,
    toAsset: formState.selectedToAsset
  }));

  // Utility functions
  function _validateForm () {
    if (!computedSchemaObject.value || !watchedValues.value.fromAsset || !watchedValues.value.toAsset) {
      return false;
    }
    return validateForm<z.infer<typeof computedSchemaObject.value>>(
      formState,
      computedSchemaObject.value,
      t
    );
  }

  function resetFormState () {
    preselectAssets();
    formState.selectedFromAmount = 0;
    formState.selectedToAmount = 0;
    pendingSwap.value = {
      partiallyMatchedOrders: undefined,
      status: undefined,
      startingTimestamp: undefined,
      endingTimestamp: undefined
    };
  }

  function switchAssets () {
    const { selectedFromAsset, selectedToAmount } = formState;
    formState.selectedFromAsset = formState.selectedToAsset;
    formState.selectedToAsset = selectedFromAsset;
    formState.selectedFromAmount = selectedToAmount;
  }

  function preselectAssets () {
    selectFromAsset(tradingBalances.value);
    selectToAsset(tradingBalances.value);
  }

  function convertToPartiallyMatchedOrder (order: PartiallyMatchedOrder): PartialOrder {
    return {
      id: order.swapId,
      payingAmount: order.sendingAmount,
      receivingAmount: Amount.fromFloat(order.receivingAmount.asFloat() - order.receivingFee.asFloat())
    } as PartialOrder;
  }

  function setPendingSwap (event: DexEvent) {
    if (event.eventType === DexEventType.SwapProgress) {
      pendingSwap.value.status = event.swapProgress?.status;
      pendingSwap.value.endingTimestamp = convertSafelyBigIntToNumber(event.timestampMillis);
    }
    if (event.eventType === DexEventType.OrderMatched) {
      pendingSwap.value.startingTimestamp = convertSafelyBigIntToNumber(event.timestampMillis);
      pendingSwap.value.partiallyMatchedOrders = event.matchedOrder?.partiallyMatchedOrders?.map(convertToPartiallyMatchedOrder) || [];
    }
  }

  function selectFromAsset (newBalances: MappedDexTradingBalance[]) {
    formState.selectedFromAsset = findAsset(newBalances, formState.selectedFromAsset, 'ETH');
  }

  function selectToAsset (newBalances: MappedDexTradingBalance[]) {
    formState.selectedToAsset = findAsset(newBalances, formState.selectedToAsset, 'BTC');
  }

  function findAsset (balances: MappedDexTradingBalance[], currentBalance: MappedDexTradingBalance | null, defaultSymbol: string) {
    return (
      balances.find(balance =>
        currentBalance
          ? balance.asset.symbol === currentBalance.asset.symbol &&
            balance.asset.network.symbol === currentBalance.asset.network.symbol
          : balance.asset.symbol === defaultSymbol
      ) || null
    );
  }

  async function setOrder () {
    if (!formState.selectedFromAsset || !formState.selectedToAsset) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.fetch.title'),
        description: t('Something went wrong while fetching market data. Please retry again or resync your wallet.')
      }));
      return;
    }
    const { selectedFromAsset, selectedFromAmount } = formState;
    if (selectedFromAsset.balance.sending.asFloat() <= 0) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.offchain-balance.title'),
        description: t('swap.order.errors.offchain-balance.description')
      }));
      return;
    }

    // not simple swap
    let _amount = null;
    if (swapMax.value) {
      _amount = selectedFromAsset.balance.sending;
    } else {
      _amount = Amount.fromFloat(selectedFromAmount);
    }
    try {
      await marketSwap(
        selectedFromAsset.asset,
        formState.selectedToAsset.asset,
        SwapAmount.newSending(_amount)
      );
    } catch (error) {
      toast.add(alertBuilder.error({
        title: t('swap.order.errors.swap.title'),
        description: t('swap.order.errors.swap.description')
      }));
      resetFormState();
      console.error('Error setting order:', error);
    }
  }

  watch(tradingBalances, preselectAssets, { immediate: true });
  watch(isDexInitialized, fetchTradingBalances, { deep: true });

  watch(
    () => [formState.selectedFromAsset, formState.selectedToAsset, isDexInitialized.value],
    async ([fromAsset, toAsset, initialized]) => {
      if (!fromAsset || !toAsset || !initialized) {
        return;
      }
      formState.selectedFromAmount = 0;
      formState.selectedToAmount = 0;

      try {
        const pairs = await getInitializedMarkets();
        const pairExists = pairs.some(([c1, c2]) =>
          (c1.id === fromAsset.asset.id && c2.id === toAsset.asset.id) ||
          (c1.id === toAsset.asset.id && c2.id === fromAsset.asset.id)
        );

        if (!pairExists) {
          await initMarket(toAsset.asset, fromAsset.asset);
        }
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('swap.order.errors.init-pair.title'),
          description: t('swap.order.errors.init-pair.description')
        }));
        console.error('Error initializing pairs:', error);
      }
    },
    { deep: true }
  );

  watch(
    () => watchedValues.value.fromAmount,
    async (fromAmount) => {
      const { fromAsset, toAsset } = watchedValues.value;
      if (!fromAsset || !toAsset || fromAmount <= 0) {
        formState.selectedToAmount = 0;
        return;
      }
      const fullBalance = formState.selectedFromAsset?.balance.sending.asFloat();
      if (fromAmount !== fullBalance) {
        swapMax.value = false;
      }
      try {
        isFetchingEstimate.value = true;
        const estimate = await estimateMarketSwap(
          fromAsset.asset,
          toAsset.asset,
          SwapAmount.newSending(Amount.fromFloat(formState.selectedFromAmount))
        );
        pendingSwap.value.fee = estimate?.receivingFee.asFloat() || 0;
        formState.selectedToAmount = (estimate?.receivingAmount?.asFloat() || 0) - pendingSwap.value.fee;
        formState.slippage = (estimate?.slippage.asFloat() || 0) * 100;
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('swap.order.errors.estimate.title'),
          description: t('swap.order.errors.estimate.description')
        }));
        console.error('Error estimating market order:', error);
      } finally {
        isFetchingEstimate.value = false;
      }
    }
  );

  return {
    swapMax,
    tradingBalances,
    setPendingSwap,
    swapToAssetBalancesOptions,
    formState,
    reset: resetFormState,
    switchAssets,
    setOrder,
    swapStatus,
    _validateForm,
    isFetchingEstimate,
    pendingSwap,
    computedSchemaObject,
    swapTime,
    fetchTradingBalances,
    lastFetched
  };
});
