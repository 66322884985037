import routerOptions0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@18.19.78_db0@0.2.4_eslint@8.57.1_ioredis@_a2ce977fbd51004815a45671e688f6be/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/opt/build/repo/app/router.options.ts";
import routerOptions2 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_magicast@0.3.5_qrcode@1.5.4_typescript@5.8.2_vite@_071b26bb18b7f7337cd3835532e9931f/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions1.hashMode ?? routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
...routerOptions2,
}