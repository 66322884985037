import type { AlertAction } from '~/types';

export const ALERTS = {
  error: {
    variant: 'subtle',
    icon: 'i-heroicons-exclamation-triangle',
    color: 'red',
    actions: [] as AlertAction[]
  },
  warning: {
    variant: 'subtle',
    icon: 'i-heroicons-exclamation-circle',
    color: 'yellow',
    actions: [] as AlertAction[]
  },
  info: {
    variant: 'subtle',
    icon: 'i-heroicons-information-circle',
    color: 'blue',
    actions: [] as AlertAction[]
  },
  success: {
    variant: 'subtle',
    icon: 'i-heroicons-check-circle',
    color: 'green',
    actions: [] as AlertAction[]
  }
};
