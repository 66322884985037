import { defineStore } from 'pinia';
import { useFuse } from '@vueuse/integrations/useFuse';
import { useCurrencyStore } from './currency';
import { AssetBalance, getBalances, Network } from 'hydra-node';
import { filterFns, getNetworkName } from '~/utils';
import type { NetworkSelectionOption } from '~/types/filters';

import type { MappedAssetBalance } from '~/types/asset';
import { PiniaStoresId } from '~/enums';

export const useWalletStore = defineStore(PiniaStoresId.WalletStore, () => {
  const assetsBalances = ref<MappedAssetBalance[] | null>([]);
  const selectedAsset = ref<MappedAssetBalance | null>(null);
  const isAssetDetailsSliderOpen = ref(false);
  const filters = reactive({
    networks: [] as NetworkSelectionOption[],
    globalSearch: ''
  });
  const hasFetched = ref(false);
  const lastFetched = ref<number>(0);

  const hasActiveFilters = computed(() => {
    return Object.values(filters).some(x => x?.length);
  });

  const filteredAssets = computed(() => {
    let dataToFilter = assetsBalances.value;

    if (filters.globalSearch.length) {
      const { results } = useFuse(filters.globalSearch, dataToFilter, {
        fuseOptions: {
          keys: ['asset.symbol', 'asset.name', 'fiatTotal'],
          threshold: 0.3
        }
      });

      dataToFilter = results.value.map(x => x.item);
    }

    return dataToFilter
      .map((row) => {
        let isMatchingNetwork = true;
        if (filters.networks && filters.networks.length > 0) {
          isMatchingNetwork = filterFns.arrIncludesSome(
            filters.networks.map(x => x.label),
            [getNetworkName(row.asset.network)]
          );
        }

        if (isMatchingNetwork) {
          return row;
        }
        return null;
      })
      .filter(Boolean) as MappedAssetBalance[];
  });

  const balance = computed(() => {
    return assetsBalances.value?.reduce((acc, asset) => acc + asset.fiatTotal, 0);
  });

  function findAssetBalanceBySymbolAndNetwork (symbol: string, network: Network): AssetBalance | undefined {
    return assetsBalances.value?.find(x => x.asset.symbol === symbol && x.asset.network.id === network.id);
  }

  function showAssetDetails (asset: MappedAssetBalance) {
    if (asset) {
      selectedAsset.value = asset;
      isAssetDetailsSliderOpen.value = true;
    }
  }

  function resetFilters () {
    filters.networks = [];
    filters.globalSearch = '';
  }

  async function fetchWalletAssets () {
    const assetBalances = await getBalances();
    hasFetched.value = true;
    lastFetched.value = Date.now();
    const mappedAssetBalances = mapWalletAssets(assetBalances);
    assetsBalances.value = mappedAssetBalances || [];
  }

  function mapWalletAssets (assetBalances: AssetBalance[]): MappedAssetBalance[] {
    const { resolveFiatValue } = useCurrencyStore();
    return assetBalances.map((row) => {
      const fiatOnchain = resolveFiatValue(row.onchain_balance.usable.asFloat(), row.asset);
      const fiatPendingOnchain = resolveFiatValue(row.onchain_balance.pending.asFloat(), row.asset);
      const fiatOffchain = resolveFiatValue(row.offchain_balance.free_local.asFloat(), row.asset);
      const fiatTotal = fiatOnchain + fiatOffchain;
      const portion = Math.abs(fiatTotal / balance.value!);
      return {
        asset: row.asset,
        onchain_balance: row.onchain_balance,
        offchain_balance: row.offchain_balance,
        fiatTotal,
        fiatOnchain,
        fiatOffchain,
        fiatPendingOnchain,
        portion,
        sortName: row.asset.name + row.asset.network.name,
        free: row.free
      };
    });
  }

  return {
    assetsBalances,
    selectedAsset,
    isAssetDetailsSliderOpen,
    filters,
    balance,
    hasFetched,
    lastFetched,
    hasActiveFilters,
    filteredAssets,
    findAssetBalanceBySymbolAndNetwork,
    showAssetDetails,
    resetFilters,
    fetchWalletAssets,
    mapWalletAssets
  };
});
