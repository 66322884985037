<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import NetworkSelection from '~/components/easy/molecules/NetworkSelection.vue';
import InputWithTokenMetadataAndFiat from '~/components/pro/molecules/Form/InputWithTokenMetadataAndFiat.vue';
import SlideoverWrapper from '~/components/easy/molecules/wrapper/SlideoverWrapper.vue';
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import TokenSkeleton from '~/components/shared/templates/Skeletons/TokenSkeleton.vue';
import type { NetworkSelectionOption } from '~/types/filters';
import { Breakpoints } from '~/config';
import type { MappedDexTradingBalance } from '~/types/asset';
import EasyDexBalanceList from '~/components/easy/molecules/list/EasyDexBalanceList.vue';

interface Props {
  options?: MappedDexTradingBalance[];
  size: 'sm' | 'md' | 'lg';
  amountDisabled? : boolean;
  amountDisplay? : boolean;
  showNetwork?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showNetwork: true,
  amountDisplay: true
});

const emit = defineEmits(['change']);

const { width } = useWindowSize();
const { formatFiat } = useIntlFormats();

const tradingBalance = defineModel<MappedDexTradingBalance>('balance');
const amount = defineModel<number>('amount');
const fiatAmount = defineModel<number>('fiatAmount');

const isOpen = ref(false);
const inputAmount = ref<InstanceType<typeof InputWithTokenMetadataAndFiat> | null>(null);

const selectedNetwork = ref<NetworkSelectionOption | null>();

const filteredAssetBalances = computed(() => {
  if (!selectedNetwork.value || selectedNetwork.value.value === 'all') {
    return props.options;
  }
  return props.options.filter(tradingBalance => tradingBalance.asset.network.symbol === selectedNetwork.value?.symbol);
});

const isMobile = computed(() => width.value < Breakpoints.lg);

const slideoverSide = computed(() => {
  return isMobile.value ? 'bottom' : 'right';
});

function handleInputChange (value: number) {
  emit('change', value);
}

function handleAssetChange (selectedTradingBalance: MappedDexTradingBalance) {
  tradingBalance.value = selectedTradingBalance;
  isOpen.value = false;
}

function handleNetworkChange (selectedNetworkOption: NetworkSelectionOption) {
  selectedNetwork.value = selectedNetworkOption;
}

function resetSelection () {
  tradingBalance.value = undefined;
  isOpen.value = false;
};

watch(amount, () => {
  if ((amount.value ?? 0) > 0) {
    inputAmount.value?.setValue(amount.value ?? 0);
  }
}, { deep: true });

const networkProp = computed(() => {
  return !props.showNetwork ? undefined : tradingBalance.value?.asset.network;
});

</script>

<template>
  <div class="u-border-gray-200 u-bg-main flex items-center rounded border p-2">
    <div class="flex w-full">
      <div :class="[!amountDisplay ? 'w-full' : 'w-3/5 md:w-1/2']">
        <!-- Button to open the slideover -->
        <UButton :disabled="!options" variant="ghost" class="justify-start" block @click="isOpen = true">
          <EasyToken
            v-if="tradingBalance"
            :name="tradingBalance?.asset.name"
            :symbol="tradingBalance?.asset.symbol"
            :logo-url="tradingBalance?.asset.logoUrl"
            :size="size"
            :network="networkProp"
          />
          <TokenSkeleton v-else />
          <template v-if="options" #trailing>
            <UIcon name="i-heroicons-chevron-down" class="text-gray-secondary size-5" />
          </template>
        </UButton>

        <!-- Slideover Background -->
        <SlideoverWrapper v-model="isOpen" title="Tokens" :side="slideoverSide">
          <template #right>
            <UButton variant="link" @click="resetSelection">
              {{ $t('generics.modals.reset') }}
            </UButton>
          </template>
          <template #body>
            <div class="flex flex-col gap-y-3">
              <NetworkSelection :side="slideoverSide" @change:value="handleNetworkChange" />
              <EasyDexBalanceList :trading-balances="filteredAssetBalances" @change:value="handleAssetChange" />
            </div>
          </template>

          <template #footer>
            <UButton variant="solid" block size="lg" @click="isOpen = false">
              <span class="text-xl font-medium">{{ $t('generics.modals.save') }}</span>
            </UButton>
          </template>
        </SlideoverWrapper>
      </div>
      <!-- Token Input & Fiat Display -->
      <div v-if="amountDisplay" class="flex w-2/5 items-center text-right md:w-1/2">
        <dl class="w-full">
          <InputWithTokenMetadataAndFiat
            ref="inputAmount"
            v-model="amount"
            :size="size"
            variant="none"
            eager-validation
            :disabled="!tradingBalance || amountDisabled"
            @changed="handleInputChange"
          />
          <!-- Fiat Amount Display -->
          <dd v-if="fiatAmount" class="u-text-gray-500 px-3.5 text-xs font-medium capitalize">
            {{ formatFiat(fiatAmount) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
