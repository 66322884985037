<script lang="ts" setup>
import type { Asset } from 'hydra-node';

interface Props {
  value: number
  max: number
  isSend?: boolean
  asset?: Asset
}

const props = withDefaults(defineProps<Props>(),
  {
    isSend: true
  });

const { formatCrypto } = useIntlFormats();

// Computed Properties
const totalSegments = 40; // Fixed number of bars
const filledSegments = computed(() => Math.round((props.value / props.max) * totalSegments));
const progressPercentage = computed(() => (props.value / props.max) * 100);

const progressColor = computed(() => {
  if (progressPercentage.value > 100) { return 'bg-red-500'; }
  if (progressPercentage.value === 100) { return 'bg-orange-500'; }
  if (progressPercentage.value > 50) { return 'bg-yellow-500'; }
  return 'bg-green-500';
});
</script>
<template>
  <div class="w-full">
    <!-- Full-width Progress Bar -->
    <div class="flex w-full space-x-0.5">
      <div
        v-for="n in totalSegments"
        :key="n"
        class="h-4 grow rounded transition-all duration-300"
        :class="n <= filledSegments ? progressColor : 'bg-gray-300'"
      />
    </div>
    <!-- Text Below -->
    <p class="text-gray-primary mt-2">
      {{ isSend ? $t('liquidity-page.display-balance-range.can-send', {amount: formatCrypto(max, asset || '', 5)}) : $t('liquidity-page.display-balance-range.can-receive', {amount: formatCrypto(max, asset || '', 5)}) }}
    </p>
  </div>
</template>
