import { AssetBalance, Layer } from 'hydra-node';
import type { MappedDexTradingBalance } from '~/types/asset';

export function hasEnoughBalance (layer:Layer, balance: AssetBalance, amount: number, fee?: number): boolean {
  if (layer === Layer.Onchain) {
    return hasEnoughAvailableOnChainBalance(balance, amount, fee);
  }
  if (layer === Layer.Offchain) {
    return hasEnoughAvailableOffChainBalance(balance, amount, fee);
  }
}

export function hasEnoughAvailableOnChainBalance (
  balance: AssetBalance,
  amount: number,
  fee?: number
): boolean {
  if (fee) {
    // we have to determine now if he has enough balance for the tx when native asset
    if (balance.asset.symbol === balance.asset.network.coinSymbol) {
      return amount <= (balance.onchain_balance.usable.asFloat() - fee);
    }
  }
  return amount <= balance.onchain_balance.usable.asFloat();
}

export function hasEnoughAvailableOffChainBalance (
  balance: AssetBalance,
  amount: number,
  fee?: number
): boolean {
  if (fee) {
    return amount <= (balance.offchain_balance.localBalance().asFloat() - fee);
  }
  return amount <= balance.offchain_balance.free_local.asFloat();
}

export function hasEnoughReceivingOffChainBalance (
  balance: AssetBalance,
  amount: number
): boolean {
  return amount <= balance.offchain_balance.free_remote.asFloat();
}

export function hasEnoughAvailableOffChainBalanceForBackToOnchain (
  balance: AssetBalance,
  amount: number
): boolean {
  return amount <= balance.offchain_balance.localBalance().asFloat();
}

export function hasEnoughSendingTradingBalance (
  balance: MappedDexTradingBalance,
  amount: number
): boolean {
  return amount <= balance.balance.sending.asFloat();
}

export function hasEnoughReceivingTradingBalance (
  balance: MappedDexTradingBalance,
  amount: number
): boolean {
  return amount <= balance.balance.receiving.asFloat();
}

export function getAvailableSendOnChainBalance (balance: AssetBalance): number {
  return balance.onchain_balance.usable.asFloat();
}

export function getAvailableSendOffChainBalance (balance: AssetBalance): number {
  return balance.offchain_balance.free_local.asFloat();
}

export function getAvailableReceiveOffChainBalance (balance: AssetBalance): number {
  return balance.offchain_balance.free_remote.asFloat();
}

export function getReceivingOffChainBalance (balance: AssetBalance): number {
  return balance.offchain_balance.paying_remote.asFloat();
}

export function getSendingOffChainBalance (balance: AssetBalance): number {
  return balance.offchain_balance.paying_local.asFloat();
}

export function getWalletOnchainBalance (balance: AssetBalance): number {
  if (!balance || !balance.onchain_balance) {
    return 0;
  }

  return balance.onchain_balance.pending.asFloat() + balance.onchain_balance.usable.asFloat();
}

export function getWalletTotalCryptoBalance (balance: AssetBalance): number {
  return getWalletOnchainBalance(balance) + balance.offchain_balance.localBalance().asFloat();
}
