import { defineStore } from 'pinia';
import isNil from 'lodash/isNil';
import { useSettingsStore } from './settings';
import {
  getAssets,
  type Asset,
  getAssetFiatPrice,
  FiatCurrency
} from 'hydra-node';
import { FetchFiatPriceError } from '~/types/Error';
import { PiniaStoresId } from '~/enums';

export const useCurrencyStore = defineStore(PiniaStoresId.CurrencyStore, () => {
  const { preferences } = useSettingsStore();
  const currentCurrencyRef = ref<FiatCurrency|null>(null);
  const currenciesRates = ref(new Map());
  const toast = useToast();
  const isFetchingCurrencyRates = ref(false);
  const { t } = useI18n();

  async function fetchCurrencies (currencyRef: FiatCurrency = preferences.currency?.enumCodeReference || FiatCurrency.USD) {
    isFetchingCurrencyRates.value = true;
    currenciesRates.value = new Map();
    const assets = await getAssets();
    for (const asset of assets) {
      const fiatValue = await getAssetFiatPrice(asset, currencyRef);
      if (!fiatValue) {
        isFetchingCurrencyRates.value = false;
        throw new FetchFiatPriceError(`Failed to fetch fiat price for asset: ${asset.symbol}`, asset.symbol);
      }
      currenciesRates.value.set(asset.symbol, fiatValue);
    }
    currentCurrencyRef.value = currencyRef;

    isFetchingCurrencyRates.value = false;
  }

  function getFiatPrice (asset: Asset | string): number {
    if (!isNil(currentCurrencyRef.value)) {
      const assetSymbol = typeof asset === 'string' ? asset : asset.symbol;
      const fetchedCurrency = currenciesRates.value.get(assetSymbol);
      if (!fetchedCurrency && !isFetchingCurrencyRates.value) {
        toast.add({
          id: 'get-fiat-price',
          title: t('generics.currency.not-found'),
          color: 'red',
          icon: 'i-heroicons-exclamation-triangle',
          closeButton: {
            icon: ''
          }
        });
      }
      return fetchedCurrency;
    }
  }

  function resolveFiatValue (cryptoValue: number, asset: Asset | string): number {
    if (!asset) {
      return 0;
    }
    const fiatPrice = getFiatPrice(asset);
    return cryptoValue * fiatPrice;
  }

  function resolveCryptoValue (fiatValue: number, asset: Asset | string): number {
    if (!asset) {
      return 0;
    }
    const fiatPrice = getFiatPrice(asset);
    return fiatValue / fiatPrice;
  }

  return {
    isFetchingCurrencyRates,
    currentCurrencyRef,
    currenciesRates,
    fetchCurrencies,
    resolveFiatValue,
    resolveCryptoValue,
    getFiatPrice
  };
});
