<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useEventListener } from '@vueuse/core';
import { EventType, BitcoinEvent, EvmEvent, AppEventType, DexEventType, DexEvent, SimpleSwapUpdate } from 'hydra-node';
import { useTransactionsHistoryStore } from '~/stores/shared/transactions-history';
import { useChannelsStore } from '~/stores/shared/channels';
import { useLiquidityStore } from '~/stores/pro/liquidity';
import { useWalletStore } from '~/stores/shared/wallet';
import { useSwapStore } from '~/stores/shared/swap';
import { usePeerStore } from '~/stores/peers';
import { convertSafelySecondsToMilliseconds, convertSafelyBigIntToNumber } from '~/utils';

const peersStore = usePeerStore();
const { fetchChannels } = useChannelsStore();
const transactionHistoryStore = useTransactionsHistoryStore();
const walletStore = useWalletStore();
const swapStore = useSwapStore();
const liquidityStore = useLiquidityStore();
const { evmLastSynced, btcLastSynced } = storeToRefs(peersStore);
const { fetchWalletAssets } = walletStore;
const { fetchTransactions } = transactionHistoryStore;

// Add Eventlistener for node-event at onMounted to make
// sure, that the package is initialized
// Register all Listeners
onMounted(() => {
  useEventListener(window, 'evm-event', (evt: any) => {
    handleEvmEvent(evt);
  });
  useEventListener(window, 'bitcoin-event', (evt: any) => {
    handleBitcoinEvent(evt);
  });
  useEventListener(window, 'app-event', (evt: any) => {
    handleAppEvent(evt);
  });
  useEventListener(window, 'dex-event', (evt: any) => {
    handleDexEvent(evt);
  });
  useEventListener(window, 'simple-swap-update', (evt: any) => {
    handleSimpleSwapEvent(evt);
  });
});

function handleEvmEvent (evt: any) {
  const now = new Date();
  const evmEvent: EvmEvent = evt.detail;
  console.debug('NodeEventHelper received an EVM Event from node: ', EventType[evmEvent.eventType], '=>', (evmEvent.eventType));

  switch (evmEvent.eventType) {
    case EventType.BalanceUpdate:
      fetchWalletAssets();
      break;
    case EventType.NodeSynced:
      peersStore.connectToEvmPeer(evmEvent.network);
      fetchData();
      break;
    case EventType.ClientSynced:
      fetchData();
      evmLastSynced.value = now.getTime();
      break;
    case EventType.ClientSyncing:
      evmLastSynced.value = 0;
      break;
    case EventType.NewBlock:
      evmLastSynced.value = convertSafelySecondsToMilliseconds(
        convertSafelyBigIntToNumber(evmEvent.blockHeader!.timestamp)
      );
      break;
    case EventType.TransactionUpdate:
      console.debug(evmEvent);
      fetchChannels();
      fetchTransactions();
      break;
    case EventType.ChannelUpdate || EventType.PaymentUpdate:
      fetchData();
      swapStore.fetchTradingBalances();
      break;
    case EventType.PeerConnected:
      fetchChannels();
      peersStore.addEvmPeer(evmEvent.peerId!);
      break;
    case EventType.PeerDisconnected:
      peersStore.removeEvmPeer();
      peersStore.connectToEvmPeer(evmEvent.network);
      break;
    default:
      console.warn('Unknown EVM NodeEvent:', evmEvent);
  }
}

function handleDexEvent (evt: any) {
  const dexEvent: DexEvent = evt.detail;
  console.debug('NodeEventHelper received Dex Event from node: ', DexEventType[dexEvent.eventType], '=>', (dexEvent.eventType));
  switch (dexEvent.eventType) {
    case DexEventType.OrderMatched:
      console.debug('order matched');
      swapStore.setPendingSwap(dexEvent);
      break;
    case DexEventType.OrderUpdate:
      console.debug('order update:', dexEvent);
      liquidityStore.fetchOrders();
      break;
    case DexEventType.SwapProgress:
      swapStore.setPendingSwap(dexEvent);
      break;
    case DexEventType.BalanceUpdate:
      swapStore.fetchTradingBalances();
      break;
    case DexEventType.Synced:
      swapStore.fetchTradingBalances();
      break;
    default:
      console.warn('Unknown Dex NodeEvent:', dexEvent);
  }
}

function handleSimpleSwapEvent (evt: any) {
  const swapEvent: SimpleSwapUpdate = evt.detail;
  console.debug('NodeEventHelper received swap Event from node: ', '=>', (swapEvent));
}

function handleBitcoinEvent (evt: any) {
  const now = new Date();
  const btcEvent: BitcoinEvent = evt.detail;
  console.debug('NodeEventHelper received Bitcoin Event from node: ', EventType[btcEvent.eventType], '=>', (btcEvent.eventType));

  switch (btcEvent.eventType) {
    case EventType.BalanceUpdate:
      fetchWalletAssets();
      break;
    case EventType.NodeSynced:
      peersStore.connectToBitcoinPeer();
      fetchData();
      break;
    case EventType.ClientSyncing:
      btcLastSynced.value = 0;
      break;
    case EventType.ClientSynced:
      fetchData();
      btcLastSynced.value = now.getTime();
      break;
    case EventType.ChannelUpdate:
    case EventType.PaymentUpdate:
      fetchData();
      swapStore.fetchTradingBalances();
      break;
    case EventType.TransactionUpdate:
      fetchChannels();
      fetchTransactions();
      break;
    case EventType.NewBlock:
      btcLastSynced.value = convertSafelySecondsToMilliseconds(
        convertSafelyBigIntToNumber(btcEvent.blockHeader!.timestamp)
      );
      break;
    case EventType.PeerConnected:
      fetchChannels();
      peersStore.addBitcoinPeer(btcEvent.peerId!);
      break;
    case EventType.PeerDisconnected:
      peersStore.removeBitcoinPeer();
      peersStore.connectToBitcoinPeer();
      break;
    default:
      console.warn('Unknown Bitcoin NodeEvent:', btcEvent);
  }
}

function handleAppEvent (evt: { eventType: AppEventType }) {
  switch (evt.eventType) {
    case AppEventType.BalanceUpdate:
    case AppEventType.TransactionUpdate:
    case AppEventType.ChannelUpdate:
    case AppEventType.ChannelClosed:
    case AppEventType.ChannelRentalUpdated:
      fetchData();
      swapStore.fetchTradingBalances();
      break;
    default:
      console.warn('Unknown App Event:', evt);
  }
}
function fetchData () {
  fetchWalletAssets();
  fetchTransactions();
  fetchChannels();
}

</script>
<template>
  <div />
</template>
