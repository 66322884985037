<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLiquidityStore } from '~/stores/pro/liquidity';
import PriceRangeInput from '~/components/pro/molecules/Form/PriceRangeInput.vue';
import DisplayBalanceRange from '~/components/pro/molecules/Form/DisplayBalanceRange.vue';
import { useCurrencyStore } from '~/stores/shared/currency';
import { LiquiditySteps } from '~/enums';
import { ORDER_TYPES } from '~/enums/orders';
import DexBalanceSelectMenu from '~/components/pro/atoms/SelectOptions/DexBalanceSelectMenu.vue';

const liquidityStore = useLiquidityStore();
const { formState, tradingBalances, secondLiquidityAssetBalancesOptions, currentStep, orderType, computedSchemaObject, adjustingBase } = storeToRefs(liquidityStore);
const { resolveFiatValue } = useCurrencyStore();

const { formatCrypto } = useIntlFormats();

const isOutOfRange = computed(() =>
  formState.value.selectedFromPrice > formState.value.currentPrice ||
  formState.value.selectedToPrice < formState.value.currentPrice
);

// Generate subtitles for price inputs
const generatePriceSubtitle = (baseSymbol?: string, quoteSymbol?: string) =>
  `${quoteSymbol} per ${baseSymbol}`;

const calculateRequiredCanReceive = (value: number, isBase: boolean) => {
  if (value === 0) { return 0; }
  if (!isBase) {
    const averagePrice = (formState.value.currentPrice + formState.value.selectedToPrice) / 2;
    return value * averagePrice;
  } else {
    const averagePrice = (formState.value.currentPrice + formState.value.selectedFromPrice) / 2;
    return value / averagePrice;
  }
};
</script>

<template>
  <!-- STEP 1: ASSETS -->
  <div v-if="currentStep.id === LiquiditySteps.ASSETS">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.pairs.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.pairs.description') }}
      </span>
    </div>
    <div class="flex gap-x-4">
      <DexBalanceSelectMenu
        v-model:balance="formState.selectedFromAsset"
        size="md"
        :options="tradingBalances"
        class="w-1/2"
        :amount-display="false"
      />
      <DexBalanceSelectMenu
        v-model:balance="formState.selectedToAsset"
        size="md"
        :options="secondLiquidityAssetBalancesOptions"
        class="w-1/2"
        :amount-display="false"
      />
    </div>
  </div>

  <!-- STEP 2: RANGE -->
  <UForm v-if="currentStep.id === LiquiditySteps.RANGE" :state="formState">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.range.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.range.description') }}
      </span>
    </div>
    <UAlert
      v-if="isOutOfRange"
      class="my-4"
      variant="subtle"
      icon="i-heroicons-exclamation-circle"
      color="yellow"
      :title="$t('liquidity-page.modal.alert.range.title')"
      :description="$t('liquidity-page.modal.alert.range.description')"
    />
    <UAlert
      v-else
      class="my-4"
      variant="subtle"
      icon="i-heroicons-check-circle"
      color="green"
      :title="$t('liquidity-page.modal.alert.in-range.title')"
      :description="$t('liquidity-page.modal.alert.in-range.description')"
    />
    <div class="my-4 flex items-center justify-between gap-x-4">
      <PriceRangeInput
        v-model="formState.selectedFromPrice"
        :title="$t('liquidity-page.range-input.minimum-price.title')"
        :subtitle="generatePriceSubtitle(formState.selectedBaseAsset?.asset.symbol, formState.selectedQuoteAsset?.asset.symbol)"
      />
      <PriceRangeInput
        v-model="formState.selectedToPrice"
        :title="$t('liquidity-page.range-input.maximum-price.title')"
        :subtitle="generatePriceSubtitle(formState.selectedBaseAsset?.asset.symbol, formState.selectedQuoteAsset?.asset.symbol)"
      />
    </div>
    <div class="flex w-full items-center justify-center gap-x-2">
      <UIcon name="i-heroicons-exclamation-circle" class="text-primary size-5" /> {{ $t('liquidity-page.steps.cards.range.current-price', {base: formatCrypto(1, formState.selectedBaseAsset?.asset?.symbol), quote: formatCrypto(formState.currentPrice, formState.selectedQuoteAsset?.asset?.symbol)}) }}
    </div>
  </UForm>

  <!-- STEP 3: AMOUNT -->
  <UForm v-if="currentStep.id === LiquiditySteps.AMOUNT" :state="formState" :schema="computedSchemaObject">
    <div class="flex flex-col pb-5">
      <span class="text-gray-primary text-lg font-medium">
        {{ $t('liquidity-page.steps.cards.amount.title') }}
      </span>
      <span class="text-gray-primary text-base">
        {{ $t('liquidity-page.steps.cards.amount.description') }}
      </span>
    </div>
    <div class="flex flex-col items-center gap-y-4">
      <!-- Show Base Asset SelectMenu if Sell Liquidity Order -->
      <div v-if="orderType === ORDER_TYPES.SELL || orderType === ORDER_TYPES.BOTH" class="flex w-full flex-col gap-y-4">
        <UFormGroup name="selectedBaseAmount" class="w-full" eager-validation>
          <DexBalanceSelectMenu
            v-model:amount="formState.selectedBaseAmount"
            v-model:balance="formState.selectedBaseAsset"
            :fiat-amount="resolveFiatValue(formState.selectedBaseAmount, formState.selectedBaseAsset?.asset)"
            size="md"
            @click="adjustingBase = true"
            @change="liquidityStore.adjustAmounts(true)"
          />
        </UFormGroup>
        <div class="flex w-full justify-between gap-x-2">
          <DisplayBalanceRange :value="formState.selectedBaseAmount" :is-send="true" :asset="formState.selectedBaseAsset?.asset" :max="formState.selectedBaseAsset?.balance.sending.asFloat()" />
          <DisplayBalanceRange :value="calculateRequiredCanReceive(formState.selectedBaseAmount, false)" :is-send="false" :asset="formState.selectedQuoteAsset?.asset" :max="formState.selectedQuoteAsset?.balance.receiving.asFloat()" />
        </div>
      </div>
      <!-- Show Quote Asset SelectMenu if Buy Liquidity Order -->
      <div v-if="orderType === ORDER_TYPES.BUY || orderType === ORDER_TYPES.BOTH" class="flex w-full flex-col gap-y-4">
        <UFormGroup name="selectedQuoteAmount" class="w-full" eager-validation>
          <DexBalanceSelectMenu
            v-model:amount="formState.selectedQuoteAmount"
            v-model:balance="formState.selectedQuoteAsset"
            :fiat-amount="resolveFiatValue(formState.selectedQuoteAmount, formState.selectedQuoteAsset?.asset!)"
            size="md"
            @click="adjustingBase = false"
            @change="liquidityStore.adjustAmounts(false)"
          />
        </UFormGroup>
        <div class="flex w-full justify-between gap-x-2">
          <DisplayBalanceRange :value="formState.selectedQuoteAmount" :is-send="true" :asset="formState.selectedQuoteAsset?.asset" :max="formState.selectedQuoteAsset?.balance.sending.asFloat()" />
          <DisplayBalanceRange :value="calculateRequiredCanReceive(formState.selectedQuoteAmount, true)" :is-send="false" :asset="formState.selectedBaseAsset?.asset" :max="formState.selectedBaseAsset?.balance.receiving.asFloat()" />
        </div>
      </div>
    </div>
  </UForm>
</template>
