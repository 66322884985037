<script setup lang="ts">
import type { Asset } from 'hydra-node';
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import { useCurrencyStore } from '~/stores/shared/currency';
import PriceRangeInput from '~/components/pro/molecules/Form/PriceRangeInput.vue';
import { ORDER_TYPES } from '~/enums/orders';

interface Props {
  baseAsset: Asset;
  baseAmoint: number;
  quoteAsset: Asset;
  quoteAmount: number;
  fromPrice: number;
  toPrice: number;
  currentPrice: number;
  orderType?: ORDER_TYPES;
}

defineProps<Props>();

defineEmits<{
  close: [];
  confirm: [];
}>();

const { resolveFiatValue } = useCurrencyStore();
const { formatCrypto } = useIntlFormats();

</script>

<template>
  <UCard class="flex flex-1 flex-col justify-between" :ui="{ body: { base: 'flex-1' }}">
    <!-- Header Slot -->
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <UButton
            color="gray"
            variant="ghost"
            icon="i-heroicons-arrow-left"
            size="lg"
            class="-my-1"
            @click="$emit('close')"
          />
          <span class="text-gray-primary text-xl font-medium">
            {{ $t('liquidity-page.modal.header.title') }}
          </span>
        </div>
        <slot name="right" />
      </div>
    </template>

    <!-- Main Body Slot -->
    <div class="flex-1 overflow-y-auto">
      <slot name="body">
        <div class="flex flex-col gap-4">
          <EasyToken
            v-show="orderType === ORDER_TYPES.SELL || orderType === ORDER_TYPES.BOTH"
            :name="baseAsset?.name"
            :symbol="baseAsset?.symbol"
            :logo-url="baseAsset?.logoUrl"
            :network="baseAsset?.network"
            :token-value="baseAmoint"
            :fiat-value="resolveFiatValue(baseAmoint, baseAsset)"
            size="lg"
          />
          <EasyToken
            v-show="orderType === ORDER_TYPES.BUY || orderType === ORDER_TYPES.BOTH"
            :name="quoteAsset?.name"
            :symbol="quoteAsset?.symbol"
            :logo-url="quoteAsset?.logoUrl"
            :network="quoteAsset?.network"
            :token-value="quoteAmount"
            :fiat-value="resolveFiatValue(quoteAmount, quoteAsset)"
            size="lg"
          />
          <UDivider orientation="horizontal" />
          <div class="flex justify-between">
            <span class="text-gray-primary text-lg font-medium">
              {{ $t('liquidity-page.modal.selected-pair.title') }}
            </span>
            <span class="text-gray-primary text-lg font-medium">
              {{ $t('liquidity-page.modal.selected-pair.description',{from: baseAsset?.symbol, to: quoteAsset?.symbol}) }}
            </span>
          </div>
          <UDivider orientation="horizontal" />
          <div class="my-4 flex items-center justify-between gap-x-4">
            <PriceRangeInput
              :display-value="fromPrice"
              disabled
              :title="$t('liquidity-page.range-input.minimum-price.title')"
              :subtitle="$t('liquidity-page.range-input.subtitle', {quote: baseAsset?.symbol, base: quoteAsset?.symbol})"
            />
            <PriceRangeInput
              :display-value="toPrice"
              disabled
              :title="$t('liquidity-page.range-input.maximum-price.title')"
              :subtitle="$t('liquidity-page.range-input.subtitle', {quote: baseAsset?.symbol, base: quoteAsset?.symbol})"
            />
          </div>
          <div class="flex w-full items-center justify-center gap-x-2">
            <UIcon name="i-heroicons-exclamation-circle" class="text-primary size-5" />
            {{ $t('liquidity-page.steps.cards.range.current-price', {base: formatCrypto(1, baseAsset?.symbol), quote: formatCrypto(currentPrice, quoteAsset?.symbol)}) }}
          </div>
        </div>
      </slot>
    </div>
    <!-- Footer Slot -->
    <template #footer>
      <slot name="footer">
        <div class="flex flex-col gap-4">
          <UButton
            size="lg"
            block
            class="capitalize"
            @click="$emit('confirm')"
          >
            {{ $t('generics.button.confirm-liquidity') }}
          </UButton>
          <UButton
            color="white"
            size="lg"
            block
            class="capitalize"
            @click="$emit('close')"
          >
            {{ $t('generics.button.go-back') }}
          </UButton>
        </div>
      </slot>
    </template>
  </UCard>
</template>
