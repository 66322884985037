<script setup lang="ts">
import EasyToken from '~/components/easy/molecules/EasyToken.vue';
import { useCurrencyStore } from '~/stores/shared/currency';
import type { MappedDexTradingBalance } from '~/types/asset';
import { ALERTS } from '~/constants/alert';

interface Props {
  tradingBalances: MappedDexTradingBalance[],
};

defineProps<Props>();

const { resolveFiatValue } = useCurrencyStore();

const emit = defineEmits<{
  'change:value': [value: MappedDexTradingBalance]
}>();

function emitSelectedAssetBalanceOption (value: MappedDexTradingBalance) {
  emit('change:value', value as MappedDexTradingBalance);
}

function tradingBalanceValues (tradingBalance: MappedDexTradingBalance) {
  const cryptoValue = tradingBalance?.balance.sending.asFloat();
  return {
    fiatValue: resolveFiatValue(cryptoValue, tradingBalance.asset),
    tokenValue: cryptoValue
  };
}
</script>

<template>
  <div v-if="tradingBalances.length > 0">
    <UButton
      v-for="(tradingBalance, index) in tradingBalances"
      :key="index"
      variant="ghost"
      class="flex w-full flex-col"
      @click="emitSelectedAssetBalanceOption(tradingBalance)"
    >
      <EasyToken
        :logo-url="tradingBalance.asset.logoUrl"
        :name="tradingBalance.asset.name"
        :network="tradingBalance.asset.network"
        :symbol="tradingBalance.asset.symbol"
        size="md"
        :fiat-value="tradingBalanceValues(tradingBalance).fiatValue"
        :token-value="tradingBalanceValues(tradingBalance).tokenValue"
      />
    </UButton>
  </div>
  <div v-else>
    <UAlert
      v-bind="{
        ...ALERTS.info,
        title: $t('swap.token-list.empty.title'),
        description: $t('swap.token-list.empty.description')
      }"
    />
  </div>
</template>
