let wasm;

const cachedTextDecoder = (typeof TextDecoder !== 'undefined' ? new TextDecoder('utf-8', { ignoreBOM: true, fatal: true }) : { decode: () => { throw Error('TextDecoder not available') } } );

if (typeof TextDecoder !== 'undefined') { cachedTextDecoder.decode(); };

let cachedUint8ArrayMemory0 = null;

function getUint8ArrayMemory0() {
    if (cachedUint8ArrayMemory0 === null || cachedUint8ArrayMemory0.byteLength === 0) {
        cachedUint8ArrayMemory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8ArrayMemory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8ArrayMemory0().subarray(ptr, ptr + len));
}

function addToExternrefTable0(obj) {
    const idx = wasm.__externref_table_alloc();
    wasm.__wbindgen_export_2.set(idx, obj);
    return idx;
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        const idx = addToExternrefTable0(e);
        wasm.__wbindgen_exn_store(idx);
    }
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let WASM_VECTOR_LEN = 0;

const cachedTextEncoder = (typeof TextEncoder !== 'undefined' ? new TextEncoder('utf-8') : { encode: () => { throw Error('TextEncoder not available') } } );

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8ArrayMemory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8ArrayMemory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8ArrayMemory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedDataViewMemory0 = null;

function getDataViewMemory0() {
    if (cachedDataViewMemory0 === null || cachedDataViewMemory0.buffer.detached === true || (cachedDataViewMemory0.buffer.detached === undefined && cachedDataViewMemory0.buffer !== wasm.memory.buffer)) {
        cachedDataViewMemory0 = new DataView(wasm.memory.buffer);
    }
    return cachedDataViewMemory0;
}

function getArrayU8FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getUint8ArrayMemory0().subarray(ptr / 1, ptr / 1 + len);
}

const CLOSURE_DTORS = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(state => {
    wasm.__wbindgen_export_6.get(state.dtor)(state.a, state.b)
});

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_6.get(state.dtor)(a, state.b);
                CLOSURE_DTORS.unregister(state);
            } else {
                state.a = a;
            }
        }
    };
    real.original = state;
    CLOSURE_DTORS.register(real, state, state);
    return real;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches && builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}
/**
 * @returns {Promise<void>}
 */
export function main_js() {
    wasm.main_js();
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
}
/**
 * @param {Mnemonic} mnemonic
 * @param {string} password
 * @param {Config} config
 * @returns {Promise<void>}
 */
export function init(mnemonic, password, config) {
    _assertClass(mnemonic, Mnemonic);
    const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    _assertClass(config, Config);
    const ret = wasm.init(mnemonic.__wbg_ptr, ptr0, len0, config.__wbg_ptr);
    return ret;
}

/**
 * @param {Mnemonic} mnemonic
 * @param {string} password
 * @param {Config} config
 * @returns {Promise<void>}
 */
export function initFresh(mnemonic, password, config) {
    _assertClass(mnemonic, Mnemonic);
    const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    _assertClass(config, Config);
    const ret = wasm.initFresh(mnemonic.__wbg_ptr, ptr0, len0, config.__wbg_ptr);
    return ret;
}

/**
 * @param {Config} config
 * @returns {Promise<void>}
 */
export function changeConfig(config) {
    _assertClass(config, Config);
    const ret = wasm.changeConfig(config.__wbg_ptr);
    return ret;
}

/**
 * @returns {Promise<Array<Network>>}
 */
export function getNetworks() {
    const ret = wasm.getNetworks();
    return ret;
}

/**
 * @returns {Promise<Array<Asset>>}
 */
export function getAssets() {
    const ret = wasm.getAssets();
    return ret;
}

/**
 * @returns {Promise<Array<AssetBalance>>}
 */
export function getBalances() {
    const ret = wasm.getBalances();
    return ret;
}

/**
 * @returns {Promise<Array<Transaction>>}
 */
export function getTransactions() {
    const ret = wasm.getTransactions();
    return ret;
}

/**
 * @returns {Promise<Array<Channel>>}
 */
export function getChannels() {
    const ret = wasm.getChannels();
    return ret;
}

/**
 * @param {Network} network
 * @param {string} channel_id
 * @returns {Promise<Channel | undefined>}
 */
export function getChannel(network, channel_id) {
    _assertClass(network, Network);
    const ptr0 = passStringToWasm0(channel_id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.getChannel(network.__wbg_ptr, ptr0, len0);
    return ret;
}

/**
 * @param {Asset} asset
 * @param {FiatCurrency} fiat_currency
 * @returns {Promise<number>}
 */
export function getAssetFiatPrice(asset, fiat_currency) {
    _assertClass(asset, Asset);
    const ret = wasm.getAssetFiatPrice(asset.__wbg_ptr, fiat_currency);
    return ret;
}

/**
 * @param {FiatCurrency} fiat_currency
 * @returns {Promise<FiatBalance>}
 */
export function getFiatBalance(fiat_currency) {
    const ret = wasm.getFiatBalance(fiat_currency);
    return ret;
}

/**
 * Get information about the rental duration and capacity limits of a rental node
 *
 * # Returns
 *
 * * `RentalNodeInfo` - Information about the channel rental duration and capacity limits of a rental node
 * @returns {Promise<RentalNodeInfo>}
 */
export function getRentalNodeInfo() {
    const ret = wasm.getRentalNodeInfo();
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinConfig} config
 * @returns {Promise<void>}
 */
export function bitcoinInit(network, config) {
    _assertClass(config, BitcoinConfig);
    const ret = wasm.bitcoinInit(network, config.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @returns {Promise<boolean>}
 */
export function bitcoinIsWatchOnly(network) {
    const ret = wasm.bitcoinIsWatchOnly(network);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @returns {Promise<BitcoinBlockHeader | undefined>}
 */
export function bitcoinLastSynced(network) {
    const ret = wasm.bitcoinLastSynced(network);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @returns {Promise<BitcoinAddress>}
 */
export function bitcoinGetDepositAddress(network) {
    const ret = wasm.bitcoinGetDepositAddress(network);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @returns {Promise<BitcoinPublicKey>}
 */
export function bitcoinGetNodeId(network) {
    const ret = wasm.bitcoinGetNodeId(network);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @returns {Promise<BitcoinFeeEstimate>}
 */
export function bitcoinGetFeeEstimates(network) {
    const ret = wasm.bitcoinGetFeeEstimates(network);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinAsset} asset
 * @param {BitcoinAddress} to
 * @param {SendAmount} amount
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<BitcoinTransactionRequest>}
 */
export function bitcoinCreateSendTx(network, asset, to, amount, fee_rate, legacy) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(to, BitcoinAddress);
    _assertClass(amount, SendAmount);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinCreateSendTx(network, asset.__wbg_ptr, to.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinTransactionRequest} tx_request
 * @returns {Promise<BitcoinSignedTransactionRequest>}
 */
export function bitcoinSignTransaction(network, tx_request) {
    _assertClass(tx_request, BitcoinTransactionRequest);
    const ret = wasm.bitcoinSignTransaction(network, tx_request.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinSignedTransactionRequest} tx_request
 * @returns {Promise<BitcoinTxid>}
 */
export function bitcoinFinalizeAndBroadcastTx(network, tx_request) {
    _assertClass(tx_request, BitcoinSignedTransactionRequest);
    const ret = wasm.bitcoinFinalizeAndBroadcastTx(network, tx_request.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinTxid} txid
 * @param {bigint | null} [confirmations]
 * @returns {Promise<void>}
 */
export function bitcoinWaitForConfirmations(network, txid, confirmations) {
    _assertClass(txid, BitcoinTxid);
    const ret = wasm.bitcoinWaitForConfirmations(network, txid.__wbg_ptr, !isLikeNone(confirmations), isLikeNone(confirmations) ? BigInt(0) : confirmations);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinLightningPeer} peer
 * @returns {Promise<void>}
 */
export function bitcoinConnectToPeer(network, peer) {
    _assertClass(peer, BitcoinLightningPeer);
    const ret = wasm.bitcoinConnectToPeer(network, peer.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinPublicKey} peer_id
 * @param {Map<BitcoinAsset, SendAmount>} asset_amounts
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimateOpenChannelFee(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, BitcoinPublicKey);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinEstimateOpenChannelFee(network, peer_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinPublicKey} peer_id
 * @param {Map<BitcoinAsset, SendAmount>} asset_amounts
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<BitcoinTxid>}
 */
export function bitcoinOpenChannel(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, BitcoinPublicKey);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinOpenChannel(network, peer_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {LightningChannelId} channel_id
 * @param {Array<BitcoinAsset>} assets
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimateCloseChannelFee(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinEstimateCloseChannelFee(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {LightningChannelId} channel_id
 * @param {Array<BitcoinAsset>} assets
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<BitcoinTxid>}
 */
export function bitcoinCloseChannel(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinCloseChannel(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {LightningChannelId} channel_id
 * @param {Array<BitcoinAsset>} assets
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimateForceCloseChannelFee(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinEstimateForceCloseChannelFee(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {LightningChannelId} channel_id
 * @param {Array<BitcoinAsset>} assets
 * @param {BitcoinFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<BitcoinTxid>}
 */
export function bitcoinForceCloseChannel(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LightningChannelId);
    _assertClass(fee_rate, BitcoinFeeRate);
    const ret = wasm.bitcoinForceCloseChannel(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {LightningChannelId} channel_id
 * @param {Map<BitcoinAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<void>}
 */
export function bitcoinSendChannelPayment(network, channel_id, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(channel_id, LightningChannelId);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinSendChannelPayment(network, channel_id.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinPublicKey} recipient
 * @param {Map<BitcoinAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<Map<BitcoinAsset, Amount>>}
 */
export function bitcoinEstimateSendPaymentFee(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, BitcoinPublicKey);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinEstimateSendPaymentFee(network, recipient.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinPublicKey} recipient
 * @param {Map<BitcoinAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<void>}
 */
export function bitcoinSendPayment(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, BitcoinPublicKey);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinSendPayment(network, recipient.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {BitcoinNetwork} network
 * @param {BitcoinAsset} asset
 * @param {Amount | null} [amount]
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<BitcoinLightningInvoice>}
 */
export function bitcoinCreateInvoice(network, asset, amount, hashlock, expiry_timeout_secs) {
    _assertClass(asset, BitcoinAsset);
    let ptr0 = 0;
    if (!isLikeNone(amount)) {
        _assertClass(amount, Amount);
        ptr0 = amount.__destroy_into_raw();
    }
    let ptr1 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr1 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.bitcoinCreateInvoice(network, asset.__wbg_ptr, ptr0, ptr1, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {BitcoinLightningInvoice} invoice
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimatePayInvoiceFee(invoice) {
    _assertClass(invoice, BitcoinLightningInvoice);
    const ret = wasm.bitcoinEstimatePayInvoiceFee(invoice.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinLightningInvoice} invoice
 * @returns {Promise<void>}
 */
export function bitcoinPayInvoice(invoice) {
    _assertClass(invoice, BitcoinLightningInvoice);
    const ret = wasm.bitcoinPayInvoice(invoice.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinLightningInvoice} invoice
 * @param {SendAmount} amount
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimatePayEmptyInvoiceFee(invoice, amount) {
    _assertClass(invoice, BitcoinLightningInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.bitcoinEstimatePayEmptyInvoiceFee(invoice.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * @param {BitcoinLightningInvoice} invoice
 * @param {SendAmount} amount
 * @returns {Promise<void>}
 */
export function bitcoinPayEmptyInvoice(invoice, amount) {
    _assertClass(invoice, BitcoinLightningInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.bitcoinPayEmptyInvoice(invoice.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * Get information about the rentable Bitcoin asset
 *
 * # Arguments
 *
 * * `network` - The Bitcoin network of the asset to rent
 * * `asset_id` - The id of the asset to rent
 *
 * # Returns
 *
 * * `RentableAssetInfo` - Information about the rentable asset
 * @param {BitcoinNetwork} network
 * @param {BitcoinAssetId} asset_id
 * @returns {Promise<RentableAssetInfo>}
 */
export function bitcoinGetRentableAssetInfo(network, asset_id) {
    _assertClass(asset_id, BitcoinAssetId);
    const ret = wasm.bitcoinGetRentableAssetInfo(network, asset_id.__wbg_ptr);
    return ret;
}

/**
 * Rent a Bitcoin channel
 *
 * # Arguments
 *
 * * `network` - The Bitcoin network of the rented channel
 * * `asset` - The asset of the rented channel
 * * `lifetime_secs` - The lifetime of the rented channel in seconds
 * * `amount` - The amount to rent the channel for
 * * `option` - The rental payment method
 *
 * # Returns
 *
 * * `[BitcoinTxid, LightningChannelId]` - The transaction ID and channel ID of the rented channel
 * @param {BitcoinNetwork} network
 * @param {BitcoinAsset} asset
 * @param {bigint} lifetime_secs
 * @param {Amount} amount
 * @param {BitcoinRentalOption} option
 * @returns {Promise<[BitcoinTxid, LightningChannelId]>}
 */
export function bitcoinRentChannel(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(amount, Amount);
    _assertClass(option, BitcoinRentalOption);
    const ret = wasm.bitcoinRentChannel(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return ret;
}

/**
 * Estimates the fee to rent a Bitcoin channel
 *
 * # Arguments
 *
 * * `network` - The Bitcoin network of the rented channel
 * * `asset` - The asset of the rented channel
 * * `lifetime_secs` - The lifetime of the rented channel in seconds
 * * `amount` - The amount to rent the channel for
 * * `option` - The rental payment method
 *
 * # Returns
 *
 * * `Amount` - The estimated fee to rent the channel
 * @param {BitcoinNetwork} network
 * @param {BitcoinAsset} asset
 * @param {bigint} lifetime_secs
 * @param {Amount} amount
 * @param {BitcoinRentalOption} option
 * @returns {Promise<Amount>}
 */
export function bitcoinEstimateRentChannelFee(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, BitcoinAsset);
    _assertClass(amount, Amount);
    _assertClass(option, BitcoinRentalOption);
    const ret = wasm.bitcoinEstimateRentChannelFee(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmConfig} config
 * @returns {Promise<void>}
 */
export function evmInit(network, config) {
    _assertClass(config, EvmConfig);
    const ret = wasm.evmInit(network, config.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @returns {Promise<boolean>}
 */
export function evmIsWatchOnly(network) {
    const ret = wasm.evmIsWatchOnly(network);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @returns {Promise<EvmBlockHeader | undefined>}
 */
export function evmLastSynced(network) {
    const ret = wasm.evmLastSynced(network);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAddress} token_id
 * @param {string | null} [logo_url]
 * @returns {Promise<void>}
 */
export function evmAddToken(network, token_id, logo_url) {
    _assertClass(token_id, EvmAddress);
    var ptr0 = isLikeNone(logo_url) ? 0 : passStringToWasm0(logo_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    const ret = wasm.evmAddToken(network, token_id.__wbg_ptr, ptr0, len0);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @returns {Promise<EvmAddress>}
 */
export function evmGetDepositAddress(network) {
    const ret = wasm.evmGetDepositAddress(network);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @returns {Promise<EvmAddress>}
 */
export function evmGetNodeId(network) {
    const ret = wasm.evmGetNodeId(network);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @returns {Promise<EvmFeeEstimate>}
 */
export function evmGetFeeEstimates(network) {
    const ret = wasm.evmGetFeeEstimates(network);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmToken} token
 * @param {EvmAddress} owner
 * @param {EvmAddress} spender
 * @returns {Promise<Amount>}
 */
export function evmGetTokenAllowance(network, token, owner, spender) {
    _assertClass(token, EvmToken);
    _assertClass(owner, EvmAddress);
    _assertClass(spender, EvmAddress);
    const ret = wasm.evmGetTokenAllowance(network, token.__wbg_ptr, owner.__wbg_ptr, spender.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAsset} asset
 * @param {EvmAddress} to
 * @param {SendAmount} amount
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTransactionRequest>}
 */
export function evmCreateSendTx(network, asset, to, amount, fee_rate, legacy) {
    _assertClass(asset, EvmAsset);
    _assertClass(to, EvmAddress);
    _assertClass(amount, SendAmount);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmCreateSendTx(network, asset.__wbg_ptr, to.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmToken} token
 * @param {EvmAddress} spender
 * @param {AllowanceAmount} amount
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTransactionRequest>}
 */
export function evmCreateSetTokenAllowanceTx(network, token, spender, amount, fee_rate, legacy) {
    _assertClass(token, EvmToken);
    _assertClass(spender, EvmAddress);
    _assertClass(amount, AllowanceAmount);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmCreateSetTokenAllowanceTx(network, token.__wbg_ptr, spender.__wbg_ptr, amount.__wbg_ptr, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmTransactionRequest} tx_request
 * @returns {Promise<EvmSignedTransactionRequest>}
 */
export function evmSignTransaction(network, tx_request) {
    _assertClass(tx_request, EvmTransactionRequest);
    const ret = wasm.evmSignTransaction(network, tx_request.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmSignedTransactionRequest} tx_request
 * @returns {Promise<EvmTxid>}
 */
export function evmFinalizeAndBroadcastTx(network, tx_request) {
    _assertClass(tx_request, EvmSignedTransactionRequest);
    const ret = wasm.evmFinalizeAndBroadcastTx(network, tx_request.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmTxid} txid
 * @param {bigint | null} [confirmations]
 * @returns {Promise<void>}
 */
export function evmWaitForConfirmations(network, txid, confirmations) {
    _assertClass(txid, EvmTxid);
    const ret = wasm.evmWaitForConfirmations(network, txid.__wbg_ptr, !isLikeNone(confirmations), isLikeNone(confirmations) ? BigInt(0) : confirmations);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmLithiumPeer} peer
 * @returns {Promise<void>}
 */
export function evmConnectToPeer(network, peer) {
    _assertClass(peer, EvmLithiumPeer);
    const ret = wasm.evmConnectToPeer(network, peer.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAddress} peer_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateOpenChannelFee(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, EvmAddress);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateOpenChannelFee(network, peer_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAddress} peer_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmOpenChannel(network, peer_id, asset_amounts, fee_rate, legacy) {
    _assertClass(peer_id, EvmAddress);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmOpenChannel(network, peer_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateDepositChannelFee(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateDepositChannelFee(network, channel_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmDepositChannel(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmDepositChannel(network, channel_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateWithdrawChannelFee(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateWithdrawChannelFee(network, channel_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmWithdrawChannel(network, channel_id, asset_amounts, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmWithdrawChannel(network, channel_id.__wbg_ptr, asset_amounts, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateCloseChannelFee(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateCloseChannelFee(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmCloseChannel(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmCloseChannel(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateForceCloseChannelFee(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateForceCloseChannelFee(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmForceCloseChannel(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmForceCloseChannel(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<Amount>}
 */
export function evmEstimateRedeemClosedChannelFee(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmEstimateRedeemClosedChannelFee(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Array<EvmAsset>} assets
 * @param {EvmFeeRate} fee_rate
 * @param {boolean} legacy
 * @returns {Promise<EvmTxid>}
 */
export function evmRedeemClosedChannel(network, channel_id, assets, fee_rate, legacy) {
    _assertClass(channel_id, LithiumChannelId);
    _assertClass(fee_rate, EvmFeeRate);
    const ret = wasm.evmRedeemClosedChannel(network, channel_id.__wbg_ptr, assets, fee_rate.__wbg_ptr, legacy);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {LithiumChannelId} channel_id
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<void>}
 */
export function evmSendChannelPayment(network, channel_id, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(channel_id, LithiumChannelId);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmSendChannelPayment(network, channel_id.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAddress} recipient
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<Map<EvmAsset, Amount>>}
 */
export function evmEstimateSendPaymentFee(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, EvmAddress);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmEstimateSendPaymentFee(network, recipient.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAddress} recipient
 * @param {Map<EvmAsset, SendAmount>} asset_amounts
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<void>}
 */
export function evmSendPayment(network, recipient, asset_amounts, hashlock, expiry_timeout_secs) {
    _assertClass(recipient, EvmAddress);
    let ptr0 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr0 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmSendPayment(network, recipient.__wbg_ptr, asset_amounts, ptr0, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {EvmNetwork} network
 * @param {EvmAsset} asset
 * @param {Amount | null} [amount]
 * @param {Hashlock | null} [hashlock]
 * @param {bigint | null} [expiry_timeout_secs]
 * @returns {Promise<EvmLithiumInvoice>}
 */
export function evmCreateInvoice(network, asset, amount, hashlock, expiry_timeout_secs) {
    _assertClass(asset, EvmAsset);
    let ptr0 = 0;
    if (!isLikeNone(amount)) {
        _assertClass(amount, Amount);
        ptr0 = amount.__destroy_into_raw();
    }
    let ptr1 = 0;
    if (!isLikeNone(hashlock)) {
        _assertClass(hashlock, Hashlock);
        ptr1 = hashlock.__destroy_into_raw();
    }
    const ret = wasm.evmCreateInvoice(network, asset.__wbg_ptr, ptr0, ptr1, !isLikeNone(expiry_timeout_secs), isLikeNone(expiry_timeout_secs) ? BigInt(0) : expiry_timeout_secs);
    return ret;
}

/**
 * @param {EvmLithiumInvoice} invoice
 * @returns {Promise<Amount>}
 */
export function evmEstimatePayInvoiceFee(invoice) {
    _assertClass(invoice, EvmLithiumInvoice);
    const ret = wasm.evmEstimatePayInvoiceFee(invoice.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmLithiumInvoice} invoice
 * @returns {Promise<void>}
 */
export function evmPayInvoice(invoice) {
    _assertClass(invoice, EvmLithiumInvoice);
    const ret = wasm.evmPayInvoice(invoice.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmLithiumInvoice} invoice
 * @param {SendAmount} amount
 * @returns {Promise<Amount>}
 */
export function evmEstimatePayEmptyInvoiceFee(invoice, amount) {
    _assertClass(invoice, EvmLithiumInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.evmEstimatePayEmptyInvoiceFee(invoice.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * @param {EvmLithiumInvoice} invoice
 * @param {SendAmount} amount
 * @returns {Promise<void>}
 */
export function evmPayEmptyInvoice(invoice, amount) {
    _assertClass(invoice, EvmLithiumInvoice);
    _assertClass(amount, SendAmount);
    const ret = wasm.evmPayEmptyInvoice(invoice.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * Get information about the rentable Evm asset
 *
 * # Arguments
 *
 * * `network` - The Evm network of the asset to rent
 * * `asset_id` - The id of the asset to rent
 *
 * # Returns
 *
 * * `RentableAssetInfo` - The information about the rentable asset
 * @param {EvmNetwork} network
 * @param {EvmAddress} asset_id
 * @returns {Promise<RentableAssetInfo>}
 */
export function evmGetRentableAssetInfo(network, asset_id) {
    _assertClass(asset_id, EvmAddress);
    const ret = wasm.evmGetRentableAssetInfo(network, asset_id.__wbg_ptr);
    return ret;
}

/**
 * Rent an Evm channel
 *
 * # Arguments
 *
 * * `network` - The Evm network of the rented channel
 * * `asset` - The asset of the rented channel
 * * `lifetime_secs` - The lifetime of the rented channel in seconds
 * * `amount` - The amount to rent the channel for
 * * `option` - The rental payment method
 *
 * # Returns
 *
 * * `[EvmTxid, LithiumChannelId]` - The transaction ID and channel ID of the rented channel
 * @param {EvmNetwork} network
 * @param {EvmAsset} asset
 * @param {bigint} lifetime_secs
 * @param {Amount} amount
 * @param {EvmRentalOption} option
 * @returns {Promise<[EvmTxid, LithiumChannelId]>}
 */
export function evmRentChannel(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, EvmAsset);
    _assertClass(amount, Amount);
    _assertClass(option, EvmRentalOption);
    const ret = wasm.evmRentChannel(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return ret;
}

/**
 * Estimates the fee to rent an Evm channel
 *
 * # Arguments
 *
 * * `network` - The Evm network of the rented channel
 * * `asset` - The asset of the rented channel
 * * `lifetime_secs` - The lifetime of the rented channel in seconds
 * * `amount` - The amount to rent the channel for
 * * `option` - The rental payment method
 *
 * # Returns
 *
 * * `Amount` - The estimated fee to rent the channel
 * @param {EvmNetwork} network
 * @param {EvmAsset} asset
 * @param {bigint} lifetime_secs
 * @param {Amount} amount
 * @param {EvmRentalOption} option
 * @returns {Promise<Amount>}
 */
export function evmEstimateRentChannelFee(network, asset, lifetime_secs, amount, option) {
    _assertClass(asset, EvmAsset);
    _assertClass(amount, Amount);
    _assertClass(option, EvmRentalOption);
    const ret = wasm.evmEstimateRentChannelFee(network, asset.__wbg_ptr, lifetime_secs, amount.__wbg_ptr, option.__wbg_ptr);
    return ret;
}

/**
 * @param {string} orderbook_url
 * @returns {Promise<void>}
 */
export function initDex(orderbook_url) {
    const ptr0 = passStringToWasm0(orderbook_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.initDex(ptr0, len0);
    return ret;
}

/**
 * @param {Asset} first_currency
 * @param {Asset} other_currency
 * @returns {Promise<MarketInfo>}
 */
export function initMarket(first_currency, other_currency) {
    _assertClass(first_currency, Asset);
    _assertClass(other_currency, Asset);
    const ret = wasm.initMarket(first_currency.__wbg_ptr, other_currency.__wbg_ptr);
    return ret;
}

/**
 * @returns {Promise<([Asset, Asset])[]>}
 */
export function getInitializedMarkets() {
    const ret = wasm.getInitializedMarkets();
    return ret;
}

/**
 * @returns {Promise<MarketInfo[]>}
 */
export function getMarketsInfo() {
    const ret = wasm.getMarketsInfo();
    return ret;
}

/**
 * @param {Asset} first_currency
 * @param {Asset} other_currency
 * @returns {Promise<MarketInfo>}
 */
export function getMarketInfo(first_currency, other_currency) {
    _assertClass(first_currency, Asset);
    _assertClass(other_currency, Asset);
    const ret = wasm.getMarketInfo(first_currency.__wbg_ptr, other_currency.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<Orderbook | undefined>}
 */
export function getOrderbook(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getOrderbook(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @param {OrderSide} side
 * @param {OrderAmount} amount
 * @returns {Promise<OrderMatch | undefined>}
 */
export function estimateMarketOrder(base, quote, side, amount) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    _assertClass(amount, OrderAmount);
    const ret = wasm.estimateMarketOrder(base.__wbg_ptr, quote.__wbg_ptr, side, amount.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} sending_asset
 * @param {Asset} receiving_asset
 * @param {SwapAmount} amount
 * @returns {Promise<SwapMatch | undefined>}
 */
export function estimateMarketSwap(sending_asset, receiving_asset, amount) {
    _assertClass(sending_asset, Asset);
    _assertClass(receiving_asset, Asset);
    _assertClass(amount, SwapAmount);
    const ret = wasm.estimateMarketSwap(sending_asset.__wbg_ptr, receiving_asset.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @param {OrderVariant} order_variant
 * @returns {Promise<string>}
 */
export function createOrder(base, quote, order_variant) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    _assertClass(order_variant, OrderVariant);
    const ret = wasm.createOrder(base.__wbg_ptr, quote.__wbg_ptr, order_variant.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} sending_asset
 * @param {Asset} receiving_asset
 * @param {SwapAmount} amount
 * @returns {Promise<string>}
 */
export function marketSwap(sending_asset, receiving_asset, amount) {
    _assertClass(sending_asset, Asset);
    _assertClass(receiving_asset, Asset);
    _assertClass(amount, SwapAmount);
    const ret = wasm.marketSwap(sending_asset.__wbg_ptr, receiving_asset.__wbg_ptr, amount.__wbg_ptr);
    return ret;
}

/**
 * @param {string} order_id
 * @returns {Promise<boolean>}
 */
export function cancelOrder(order_id) {
    const ptr0 = passStringToWasm0(order_id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.cancelOrder(ptr0, len0);
    return ret;
}

/**
 * @returns {Promise<Map<[Asset, Asset], Map<String, Order>>>}
 */
export function getAllOrders() {
    const ret = wasm.getAllOrders();
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<Map<String, Order>>}
 */
export function getOrders(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getOrders(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @param {string} order_id
 * @returns {Promise<Order | undefined>}
 */
export function getOrder(order_id) {
    const ptr0 = passStringToWasm0(order_id, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    const ret = wasm.getOrder(ptr0, len0);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<Array<Trade>>}
 */
export function getTradeHistory(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getTradeHistory(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<Amount | undefined>}
 */
export function getMarketPrice(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getMarketPrice(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<MarketDailyStats>}
 */
export function getMarketDailyStats(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getMarketDailyStats(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @returns {Promise<Map<[Asset, Asset], Array<ClientTrade>>>}
 */
export function getAllTrades() {
    const ret = wasm.getAllTrades();
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @returns {Promise<Array<ClientTrade>>}
 */
export function getTrades(base, quote) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getTrades(base.__wbg_ptr, quote.__wbg_ptr);
    return ret;
}

/**
 * @param {Asset} base
 * @param {Asset} quote
 * @param {bigint | null | undefined} from_timestamp_millis
 * @param {bigint | null | undefined} to_timestamp_millis
 * @param {CandlestickInterval} interval
 * @returns {Promise<Array<Candlestick>>}
 */
export function getCandlesticks(base, quote, from_timestamp_millis, to_timestamp_millis, interval) {
    _assertClass(base, Asset);
    _assertClass(quote, Asset);
    const ret = wasm.getCandlesticks(base.__wbg_ptr, quote.__wbg_ptr, !isLikeNone(from_timestamp_millis), isLikeNone(from_timestamp_millis) ? BigInt(0) : from_timestamp_millis, !isLikeNone(to_timestamp_millis), isLikeNone(to_timestamp_millis) ? BigInt(0) : to_timestamp_millis, interval);
    return ret;
}

/**
 * @returns {Promise<Map<Asset, CurrencyBalance>>}
 */
export function getOrderbookBalances() {
    const ret = wasm.getOrderbookBalances();
    return ret;
}

/**
 * @param {Asset} sending_asset
 * @param {Asset} receiving_asset
 * @param {SwapAmount} amount
 * @param {Amount} price_change_tolerance
 * @param {boolean} withdraw_sending_funds
 * @param {boolean} withdraw_receiving_funds
 * @returns {Promise<SimpleSwapEstimate>}
 */
export function estimateSimpleSwap(sending_asset, receiving_asset, amount, price_change_tolerance, withdraw_sending_funds, withdraw_receiving_funds) {
    _assertClass(sending_asset, Asset);
    _assertClass(receiving_asset, Asset);
    _assertClass(amount, SwapAmount);
    _assertClass(price_change_tolerance, Amount);
    const ret = wasm.estimateSimpleSwap(sending_asset.__wbg_ptr, receiving_asset.__wbg_ptr, amount.__wbg_ptr, price_change_tolerance.__wbg_ptr, withdraw_sending_funds, withdraw_receiving_funds);
    return ret;
}

/**
 * @param {Asset} sending_asset
 * @param {Asset} receiving_asset
 * @param {SwapAmount} amount
 * @param {Amount} price_change_tolerance
 * @param {boolean} withdraw_sending_funds
 * @param {boolean} withdraw_receiving_funds
 * @returns {Promise<SimpleSwapOutput>}
 */
export function simpleSwap(sending_asset, receiving_asset, amount, price_change_tolerance, withdraw_sending_funds, withdraw_receiving_funds) {
    _assertClass(sending_asset, Asset);
    _assertClass(receiving_asset, Asset);
    _assertClass(amount, SwapAmount);
    _assertClass(price_change_tolerance, Amount);
    const ret = wasm.simpleSwap(sending_asset.__wbg_ptr, receiving_asset.__wbg_ptr, amount.__wbg_ptr, price_change_tolerance.__wbg_ptr, withdraw_sending_funds, withdraw_receiving_funds);
    return ret;
}

function takeFromExternrefTable0(idx) {
    const value = wasm.__wbindgen_export_2.get(idx);
    wasm.__externref_table_dealloc(idx);
    return value;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getDataViewMemory0();
    const result = [];
    for (let i = ptr; i < ptr + 4 * len; i += 4) {
        result.push(wasm.__wbindgen_export_2.get(mem.getUint32(i, true)));
    }
    wasm.__externref_drop_slice(ptr, len);
    return result;
}

let cachedInt32ArrayMemory0 = null;

function getInt32ArrayMemory0() {
    if (cachedInt32ArrayMemory0 === null || cachedInt32ArrayMemory0.byteLength === 0) {
        cachedInt32ArrayMemory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32ArrayMemory0;
}

function getArrayI32FromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return getInt32ArrayMemory0().subarray(ptr / 4, ptr / 4 + len);
}
/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
export function sleep_ms(ms) {
    const ret = wasm.sleep_ms(ms);
    return ret;
}

/**
 * @param {number} ms
 */
export function sleep_blocking_ms(ms) {
    wasm.sleep_blocking_ms(ms);
}

/**
 * @param {number} ptr
 */
export function worker_entry_point(ptr) {
    wasm.worker_entry_point(ptr);
}

/**
 * @param {number} ptr
 * @returns {Promise<void>}
 */
export function async_worker_entry_point(ptr) {
    const ret = wasm.async_worker_entry_point(ptr);
    return ret;
}

function __wbg_adapter_62(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h749506e4824796c4(arg0, arg1);
}

function __wbg_adapter_65(arg0, arg1, arg2) {
    wasm.closure7661_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_68(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h2dc78a9c9ade5f87(arg0, arg1);
}

function __wbg_adapter_71(arg0, arg1, arg2) {
    const ret = wasm.closure8917_externref_shim_multivalue_shim(arg0, arg1, arg2);
    if (ret[1]) {
        throw takeFromExternrefTable0(ret[0]);
    }
}

function __wbg_adapter_74(arg0, arg1, arg2) {
    wasm.closure8915_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_77(arg0, arg1, arg2) {
    wasm.closure9012_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_80(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h16b8e19713e1c01e(arg0, arg1);
}

function __wbg_adapter_83(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__hd8d4fc772e984313(arg0, arg1);
}

function __wbg_adapter_86(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h289bc553f6b0c1a7(arg0, arg1);
}

function __wbg_adapter_89(arg0, arg1, arg2) {
    wasm.closure9240_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_96(arg0, arg1, arg2) {
    wasm.closure9270_externref_shim(arg0, arg1, arg2);
}

function __wbg_adapter_1277(arg0, arg1, arg2, arg3) {
    wasm.closure9471_externref_shim(arg0, arg1, arg2, arg3);
}

/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}
 */
export const AppEventType = Object.freeze({
    BalanceUpdate: 0, "0": "BalanceUpdate",
    TransactionUpdate: 1, "1": "TransactionUpdate",
    TransactionRemoved: 2, "2": "TransactionRemoved",
    ChannelUpdate: 3, "3": "ChannelUpdate",
    ChannelClosed: 4, "4": "ChannelClosed",
    AssetChannelUpdate: 5, "5": "AssetChannelUpdate",
    AssetChannelClosed: 6, "6": "AssetChannelClosed",
    ChannelRentalUpdated: 7, "7": "ChannelRentalUpdated",
    ChannelRentalExpired: 8, "8": "ChannelRentalExpired",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7}
 */
export const AssetChannelStatus = Object.freeze({
    /**
     * The asset channel is open but not operational
     */
    Inactive: 0, "0": "Inactive",
    /**
     * The asset channel is open and operational
     */
    Active: 1, "1": "Active",
    /**
     * The channel is in the process of being opened
     */
    Opening: 2, "2": "Opening",
    /**
     * The channel is in the process of being updated to either deposit or withdraw funds
     */
    Updating: 3, "3": "Updating",
    /**
     * The asset channel is in the process of being cooperatively settled
     */
    Closing: 4, "4": "Closing",
    /**
     * The asset channel is in the process of being unilaterally settled
     */
    ForceClosing: 5, "5": "ForceClosing",
    /**
     * The asset channel is settled and the funds can be withdrawn
     */
    ClosedRedeemable: 6, "6": "ClosedRedeemable",
    /**
     * The asset channel is settled
     */
    Closed: 7, "7": "Closed",
});
/**
 * @enum {0 | 1}
 */
export const AssetType = Object.freeze({
    Native: 0, "0": "Native",
    Token: 1, "1": "Token",
});
/**
 * @enum {0 | 1 | 2 | 3}
 */
export const BitcoinNetwork = Object.freeze({
    Mainnet: 0, "0": "Mainnet",
    Testnet: 1, "1": "Testnet",
    Signet: 2, "2": "Signet",
    Regtest: 3, "3": "Regtest",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14}
 */
export const CandlestickInterval = Object.freeze({
    OneMinute: 0, "0": "OneMinute",
    ThreeMinutes: 1, "1": "ThreeMinutes",
    FiveMinutes: 2, "2": "FiveMinutes",
    FifteenMinutes: 3, "3": "FifteenMinutes",
    ThirtyMinutes: 4, "4": "ThirtyMinutes",
    OneHour: 5, "5": "OneHour",
    TwoHours: 6, "6": "TwoHours",
    FourHours: 7, "7": "FourHours",
    SixHours: 8, "8": "SixHours",
    EightHours: 9, "9": "EightHours",
    TwelveHours: 10, "10": "TwelveHours",
    OneDay: 11, "11": "OneDay",
    ThreeDays: 12, "12": "ThreeDays",
    OneWeek: 13, "13": "OneWeek",
    OneMonth: 14, "14": "OneMonth",
});
/**
 * @enum {0 | 1 | 2}
 */
export const ChannelStatus = Object.freeze({
    /**
     * The counterparty isn't connected.
     */
    Inactive: 0, "0": "Inactive",
    /**
     * The channel is ready to be used.
     */
    Active: 1, "1": "Active",
    /**
     * The channel is active but some of the asset channels are undergoing an update
     */
    Updating: 2, "2": "Updating",
});
/**
 * @enum {0 | 1 | 2}
 */
export const DeadlineType = Object.freeze({
    Timestamp: 0, "0": "Timestamp",
    BlockNumber: 1, "1": "BlockNumber",
    BlockDelta: 2, "2": "BlockDelta",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6}
 */
export const DexEventType = Object.freeze({
    Syncing: 0, "0": "Syncing",
    Synced: 1, "1": "Synced",
    OrderUpdate: 2, "2": "OrderUpdate",
    OrderMatched: 3, "3": "OrderMatched",
    SwapProgress: 4, "4": "SwapProgress",
    TradeUpdate: 5, "5": "TradeUpdate",
    BalanceUpdate: 6, "6": "BalanceUpdate",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18}
 */
export const EventType = Object.freeze({
    ClientSyncing: 0, "0": "ClientSyncing",
    ClientSynced: 1, "1": "ClientSynced",
    NodeSyncing: 2, "2": "NodeSyncing",
    NodeSynced: 3, "3": "NodeSynced",
    NewBlock: 4, "4": "NewBlock",
    PeerConnected: 5, "5": "PeerConnected",
    PeerDisconnected: 6, "6": "PeerDisconnected",
    BalanceUpdate: 7, "7": "BalanceUpdate",
    TransactionUpdate: 8, "8": "TransactionUpdate",
    TransactionRemoved: 9, "9": "TransactionRemoved",
    PaymentUpdate: 10, "10": "PaymentUpdate",
    ChannelUpdate: 11, "11": "ChannelUpdate",
    ChannelClosed: 12, "12": "ChannelClosed",
    AssetChannelUpdate: 13, "13": "AssetChannelUpdate",
    AssetChannelClosed: 14, "14": "AssetChannelClosed",
    RentalManagerSyncing: 15, "15": "RentalManagerSyncing",
    RentalManagerSynced: 16, "16": "RentalManagerSynced",
    ChannelRentalUpdated: 17, "17": "ChannelRentalUpdated",
    ChannelRentalExpired: 18, "18": "ChannelRentalExpired",
});
/**
 * @enum {1 | 42161 | 10 | 56 | 137 | 43114 | 250 | 100 | 11155111 | 17000 | 421614 | 11155420 | 97 | 80001 | 43113 | 4002 | 10200 | 1337}
 */
export const EvmNetwork = Object.freeze({
    Ethereum: 1, "1": "Ethereum",
    Arbitrum: 42161, "42161": "Arbitrum",
    Optimism: 10, "10": "Optimism",
    Bsc: 56, "56": "Bsc",
    Polygon: 137, "137": "Polygon",
    Avalanche: 43114, "43114": "Avalanche",
    Fantom: 250, "250": "Fantom",
    Gnosis: 100, "100": "Gnosis",
    EthereumSepolia: 11155111, "11155111": "EthereumSepolia",
    EthereumHolesky: 17000, "17000": "EthereumHolesky",
    ArbitrumSepolia: 421614, "421614": "ArbitrumSepolia",
    OptimismSepolia: 11155420, "11155420": "OptimismSepolia",
    BscTestnet: 97, "97": "BscTestnet",
    PolygonMumbai: 80001, "80001": "PolygonMumbai",
    AvalancheFuji: 43113, "43113": "AvalancheFuji",
    FantomTestnet: 4002, "4002": "FantomTestnet",
    GnosisChiado: 10200, "10200": "GnosisChiado",
    EthereumLocal: 1337, "1337": "EthereumLocal",
});
/**
 * @enum {0 | 1 | 2}
 */
export const EvmTokenVariant = Object.freeze({
    ERC20: 0, "0": "ERC20",
    ERC721: 1, "1": "ERC721",
    ERC1155: 2, "2": "ERC1155",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11}
 */
export const FiatCurrency = Object.freeze({
    USD: 0, "0": "USD",
    EUR: 1, "1": "EUR",
    GBP: 2, "2": "GBP",
    AUD: 3, "3": "AUD",
    CAD: 4, "4": "CAD",
    CHF: 5, "5": "CHF",
    CNY: 6, "6": "CNY",
    JPY: 7, "7": "JPY",
    KRW: 8, "8": "KRW",
    RUB: 9, "9": "RUB",
    TRY: 10, "10": "TRY",
    INR: 11, "11": "INR",
});
/**
 * @enum {0 | 1}
 */
export const Layer = Object.freeze({
    Onchain: 0, "0": "Onchain",
    Offchain: 1, "1": "Offchain",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4}
 */
export const LogLevel = Object.freeze({
    Error: 0, "0": "Error",
    Warn: 1, "1": "Warn",
    Info: 2, "2": "Info",
    Debug: 3, "3": "Debug",
    Trace: 4, "4": "Trace",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5}
 */
export const MarketEventType = Object.freeze({
    Syncing: 0, "0": "Syncing",
    Synced: 1, "1": "Synced",
    OrderbookUpdate: 2, "2": "OrderbookUpdate",
    TradeUpdate: 3, "3": "TradeUpdate",
    DailyStatsUpdate: 4, "4": "DailyStatsUpdate",
    CandlestickUpdate: 5, "5": "CandlestickUpdate",
});
/**
 * @enum {0 | 1 | 2}
 */
export const OffchainOperationType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    Route: 2, "2": "Route",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4}
 */
export const OffchainPaymentStatus = Object.freeze({
    Completed: 0, "0": "Completed",
    Pending: 1, "1": "Pending",
    Failed: 2, "2": "Failed",
    Expired: 3, "3": "Expired",
    Rejected: 4, "4": "Rejected",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4}
 */
export const OffchainPaymentType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    Route: 2, "2": "Route",
    Batched: 3, "3": "Batched",
    Private: 4, "4": "Private",
});
/**
 * @enum {0 | 1}
 */
export const OffchainProtocol = Object.freeze({
    Lightning: 0, "0": "Lightning",
    Lithium: 1, "1": "Lithium",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11}
 */
export const OnchainOperationType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    SelfTransfer: 2, "2": "SelfTransfer",
    ChannelOpening: 3, "3": "ChannelOpening",
    ChannelDeposit: 4, "4": "ChannelDeposit",
    ChannelWithdrawal: 5, "5": "ChannelWithdrawal",
    ChannelClosing: 6, "6": "ChannelClosing",
    ChannelSettlement: 7, "7": "ChannelSettlement",
    ChannelSettlementConfirmed: 8, "8": "ChannelSettlementConfirmed",
    SetTokenAllowance: 9, "9": "SetTokenAllowance",
    ContractCall: 10, "10": "ContractCall",
    ContractDeployment: 11, "11": "ContractDeployment",
});
/**
 * @enum {0 | 1 | 2}
 */
export const OnchainTxStatus = Object.freeze({
    Completed: 0, "0": "Completed",
    Pending: 1, "1": "Pending",
    Failed: 2, "2": "Failed",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13}
 */
export const OnchainTxType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    SelfTransfer: 2, "2": "SelfTransfer",
    ChannelOpening: 3, "3": "ChannelOpening",
    ChannelDeposit: 4, "4": "ChannelDeposit",
    ChannelWithdrawal: 5, "5": "ChannelWithdrawal",
    ChannelClosing: 6, "6": "ChannelClosing",
    ChannelSettlement: 7, "7": "ChannelSettlement",
    ChannelSettlementConfirmed: 8, "8": "ChannelSettlementConfirmed",
    SetTokenAllowance: 9, "9": "SetTokenAllowance",
    ContractCall: 10, "10": "ContractCall",
    ContractDeployment: 11, "11": "ContractDeployment",
    Batched: 12, "12": "Batched",
    Private: 13, "13": "Private",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}
 */
export const OperationType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    SelfTransfer: 2, "2": "SelfTransfer",
    Route: 3, "3": "Route",
    ChannelOpening: 4, "4": "ChannelOpening",
    ChannelDeposit: 5, "5": "ChannelDeposit",
    ChannelWithdrawal: 6, "6": "ChannelWithdrawal",
    ChannelClosing: 7, "7": "ChannelClosing",
    ChannelSettlement: 8, "8": "ChannelSettlement",
    ChannelSettlementConfirmed: 9, "9": "ChannelSettlementConfirmed",
    SetTokenAllowance: 10, "10": "SetTokenAllowance",
    ContractCall: 11, "11": "ContractCall",
    ContractDeployment: 12, "12": "ContractDeployment",
});
/**
 * @enum {0 | 1}
 */
export const OrderSide = Object.freeze({
    Buy: 0, "0": "Buy",
    Sell: 1, "1": "Sell",
});
/**
 * @enum {0 | 1 | 2}
 */
export const OrderType = Object.freeze({
    Liquidity: 0, "0": "Liquidity",
    Limit: 1, "1": "Limit",
    Market: 2, "2": "Market",
});
/**
 * @enum {0 | 1}
 */
export const Protocol = Object.freeze({
    Bitcoin: 0, "0": "Bitcoin",
    Evm: 1, "1": "Evm",
});
/**
 * @enum {0 | 1}
 */
export const RentalPaymentType = Object.freeze({
    /**
     * Pay the rental fee using on-chain funds (only native assets)
     */
    OnChain: 0, "0": "OnChain",
    /**
     * Pay the rental fee using off-chain funds (any asset)
     */
    OffChain: 1, "1": "OffChain",
});
/**
 * @enum {0 | 1 | 2}
 */
export const SimpleSwapOutputType = Object.freeze({
    Instant: 0, "0": "Instant",
    Deferred: 1, "1": "Deferred",
    DualFundDeferred: 2, "2": "DualFundDeferred",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}
 */
export const SimpleSwapUpdateType = Object.freeze({
    SendingChannelReady: 0, "0": "SendingChannelReady",
    ReceivingChannelReady: 1, "1": "ReceivingChannelReady",
    DualFundChannelReady: 2, "2": "DualFundChannelReady",
    OrderCreated: 3, "3": "OrderCreated",
    OrderCompleted: 4, "4": "OrderCompleted",
    SendingFundsWithdrawn: 5, "5": "SendingFundsWithdrawn",
    ReceivingFundsWithdrawn: 6, "6": "ReceivingFundsWithdrawn",
    DualFundFundsWithdrawn: 7, "7": "DualFundFundsWithdrawn",
    SimpleSwapError: 8, "8": "SimpleSwapError",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}
 */
export const SwapStatus = Object.freeze({
    /**
     * step 0
     */
    OrderMatched: 0, "0": "OrderMatched",
    /**
     * Market: step 1
     * Liquidity: step 2
     */
    ReceivingInvoiceCreated: 1, "1": "ReceivingInvoiceCreated",
    /**
     * Market: step 2
     * Liquidity: step 1
     */
    PayingInvoiceReceived: 2, "2": "PayingInvoiceReceived",
    /**
     * Market: step 3
     * Liquidity: step 4
     */
    PaymentSent: 3, "3": "PaymentSent",
    /**
     * Market: step 4
     * Liquidity: step 3
     */
    PaymentReceived: 4, "4": "PaymentReceived",
    /**
     * Market: step 5
     * Liquidity: step 6
     */
    PaymentClaimed: 5, "5": "PaymentClaimed",
    /**
     * Market: step 6
     * Liquidity: step 5
     */
    PaymentClaimedByCounterparty: 6, "6": "PaymentClaimedByCounterparty",
    /**
     * step 7
     */
    SwapCompleted: 7, "7": "SwapCompleted",
    /**
     * Error during swap
     */
    SwapFailed: 8, "8": "SwapFailed",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4}
 */
export const TxStatus = Object.freeze({
    Completed: 0, "0": "Completed",
    Pending: 1, "1": "Pending",
    Failed: 2, "2": "Failed",
    Expired: 3, "3": "Expired",
    Rejected: 4, "4": "Rejected",
});
/**
 * @enum {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14}
 */
export const TxType = Object.freeze({
    Send: 0, "0": "Send",
    Receive: 1, "1": "Receive",
    SelfTransfer: 2, "2": "SelfTransfer",
    Route: 3, "3": "Route",
    ChannelOpening: 4, "4": "ChannelOpening",
    ChannelDeposit: 5, "5": "ChannelDeposit",
    ChannelWithdrawal: 6, "6": "ChannelWithdrawal",
    ChannelClosing: 7, "7": "ChannelClosing",
    ChannelSettlement: 8, "8": "ChannelSettlement",
    ChannelSettlementConfirmed: 9, "9": "ChannelSettlementConfirmed",
    SetTokenAllowance: 10, "10": "SetTokenAllowance",
    ContractCall: 11, "11": "ContractCall",
    ContractDeployment: 12, "12": "ContractDeployment",
    Batched: 13, "13": "Batched",
    Private: 14, "14": "Private",
});

const __wbindgen_enum_BinaryType = ["blob", "arraybuffer"];

const __wbindgen_enum_IdbTransactionMode = ["readonly", "readwrite", "versionchange", "readwriteflush", "cleanup"];

const __wbindgen_enum_ReadableStreamType = ["bytes"];

const __wbindgen_enum_ReferrerPolicy = ["", "no-referrer", "no-referrer-when-downgrade", "origin", "origin-when-cross-origin", "unsafe-url", "same-origin", "strict-origin", "strict-origin-when-cross-origin"];

const __wbindgen_enum_RequestCache = ["default", "no-store", "reload", "no-cache", "force-cache", "only-if-cached"];

const __wbindgen_enum_RequestCredentials = ["omit", "same-origin", "include"];

const __wbindgen_enum_RequestMode = ["same-origin", "no-cors", "cors", "navigate"];

const __wbindgen_enum_RequestRedirect = ["follow", "error", "manual"];

const AllowanceAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_allowanceamount_free(ptr >>> 0, 1));

export class AllowanceAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AllowanceAmount.prototype);
        obj.__wbg_ptr = ptr;
        AllowanceAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AllowanceAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_allowanceamount_free(ptr, 0);
    }
    /**
     * @returns {AllowanceAmount}
     */
    static unlimited() {
        const ret = wasm.allowanceamount_unlimited();
        return AllowanceAmount.__wrap(ret);
    }
    /**
     * @param {Amount} amount
     * @returns {AllowanceAmount}
     */
    static exact(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return AllowanceAmount.__wrap(ret);
    }
    /**
     * @returns {AllowanceAmount}
     */
    static zero() {
        const ret = wasm.allowanceamount_zero();
        return AllowanceAmount.__wrap(ret);
    }
    /**
     * @param {number} value
     * @returns {AllowanceAmount}
     */
    static fromFloat(value) {
        const ret = wasm.allowanceamount_fromFloat(value);
        return AllowanceAmount.__wrap(ret);
    }
    /**
     * @param {U256} value
     * @param {number} decimals
     * @returns {AllowanceAmount}
     */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.allowanceamount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return AllowanceAmount.__wrap(ret);
    }
}

const AmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_amount_free(ptr >>> 0, 1));

export class Amount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Amount.prototype);
        obj.__wbg_ptr = ptr;
        AmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_amount_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    static zero() {
        const ret = wasm.amount_zero();
        return Amount.__wrap(ret);
    }
    /**
     * @param {number} value
     * @returns {Amount}
     */
    static fromFloat(value) {
        const ret = wasm.amount_fromFloat(value);
        return Amount.__wrap(ret);
    }
    /**
     * @param {U256} value
     * @param {number} decimals
     * @returns {Amount}
     */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.amount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {number}
     */
    asFloat() {
        const ret = wasm.amount_asFloat(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {number} decimals
     * @returns {U256}
     */
    asCommonDenomination(decimals) {
        const ret = wasm.amount_asCommonDenomination(this.__wbg_ptr, decimals);
        return U256.__wrap(ret);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.amount_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {Amount}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.amount_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Amount.__wrap(ret[0]);
    }
}

const AppEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_appevent_free(ptr >>> 0, 1));

export class AppEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AppEvent.prototype);
        obj.__wbg_ptr = ptr;
        AppEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AppEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_appevent_free(ptr, 0);
    }
    /**
     * @returns {AppEventType}
     */
    get eventType() {
        const ret = wasm.appevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {AssetBalance | undefined}
     */
    get assetBalance() {
        const ret = wasm.appevent_assetBalance(this.__wbg_ptr);
        return ret === 0 ? undefined : AssetBalance.__wrap(ret);
    }
    /**
     * @returns {Transaction | undefined}
     */
    get transaction() {
        const ret = wasm.appevent_transaction(this.__wbg_ptr);
        return ret === 0 ? undefined : Transaction.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get transactionRemoved() {
        const ret = wasm.appevent_transactionRemoved(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Channel | undefined}
     */
    get channel() {
        const ret = wasm.appevent_channel(this.__wbg_ptr);
        return ret === 0 ? undefined : Channel.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get channelId() {
        const ret = wasm.appevent_channelId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Asset | undefined}
     */
    get asset() {
        const ret = wasm.appevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : Asset.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get assetId() {
        const ret = wasm.appevent_assetId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {AssetChannel | undefined}
     */
    get assetChannel() {
        const ret = wasm.appevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : AssetChannel.__wrap(ret);
    }
}

const AssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_asset_free(ptr >>> 0, 1));

export class Asset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Asset.prototype);
        obj.__wbg_ptr = ptr;
        AssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof Asset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_asset_free(ptr, 0);
    }
    /**
     * @returns {Network}
     */
    get network() {
        const ret = wasm.asset_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
     * @returns {string}
     */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.asset_id(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.asset_name(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.asset_symbol(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {number}
     */
    get decimals() {
        const ret = wasm.asset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get logoUrl() {
        const ret = wasm.asset_logoUrl(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Protocol}
     */
    get type() {
        const ret = wasm.asset_type(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinAsset | undefined}
     */
    asBitcoin() {
        const ret = wasm.asset_asBitcoin(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
     * @returns {EvmAsset | undefined}
     */
    asEvm() {
        const ret = wasm.asset_asEvm(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
    /**
     * @returns {CurrencyInfo}
     */
    currencyInfo() {
        const ret = wasm.asset_currencyInfo(this.__wbg_ptr);
        return CurrencyInfo.__wrap(ret);
    }
}

const AssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetbalance_free(ptr >>> 0, 1));

export class AssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        AssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetbalance_free(ptr, 0);
    }
    /**
     * @returns {Asset}
     */
    get asset() {
        const ret = wasm.__wbg_get_assetbalance_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set asset(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OnchainBalance}
     */
    get onchain_balance() {
        const ret = wasm.__wbg_get_assetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
     * @param {OnchainBalance} arg0
     */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OffchainBalance}
     */
    get offchain_balance() {
        const ret = wasm.__wbg_get_assetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * @param {OffchainBalance} arg0
     */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const AssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetchannel_free(ptr >>> 0, 1));

export class AssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        AssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetchannel_free(ptr, 0);
    }
    /**
     * The asset of the asset channel
     * @returns {Asset}
     */
    get asset() {
        const ret = wasm.assetchannel_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * The status of the asset channel
     * @returns {AssetChannelStatus}
     */
    get status() {
        const ret = wasm.assetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * The balance of the asset channel
     * @returns {OffchainBalance}
     */
    get balance() {
        const ret = wasm.assetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * The txid of the last onchain operation
     * @returns {string | undefined}
     */
    get lastOnchainTxid() {
        const ret = wasm.assetchannel_lastOnchainTxid(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * The number of confirmations since the last onchain operation
     * @returns {bigint}
     */
    get lastOperationConfirmations() {
        const ret = wasm.assetchannel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * The timestamp of the last operation
     * @returns {bigint}
     */
    get lastOperationTimestampSecs() {
        const ret = wasm.assetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * The timestamp of the rental expiry
     * @returns {bigint | undefined}
     */
    get rentalExpiryTimestampSecs() {
        const ret = wasm.assetchannel_rentalExpiryTimestampSecs(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
}

const AssetChannelAmountsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_assetchannelamounts_free(ptr >>> 0, 1));

export class AssetChannelAmounts {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(AssetChannelAmounts.prototype);
        obj.__wbg_ptr = ptr;
        AssetChannelAmountsFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof AssetChannelAmounts)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        AssetChannelAmountsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_assetchannelamounts_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get selfAmount() {
        const ret = wasm.assetchannelamounts_selfAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get counterpartyAmount() {
        const ret = wasm.assetchannelamounts_counterpartyAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const BalanceUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_balanceupdate_free(ptr >>> 0, 1));

export class BalanceUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BalanceUpdate.prototype);
        obj.__wbg_ptr = ptr;
        BalanceUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BalanceUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_balanceupdate_free(ptr, 0);
    }
    /**
     * @returns {Asset}
     */
    get currency() {
        const ret = wasm.__wbg_get_assetbalance_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set currency(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {CurrencyBalance}
     */
    get balance() {
        const ret = wasm.__wbg_get_balanceupdate_balance(this.__wbg_ptr);
        return CurrencyBalance.__wrap(ret);
    }
    /**
     * @param {CurrencyBalance} arg0
     */
    set balance(arg0) {
        _assertClass(arg0, CurrencyBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_balanceupdate_balance(this.__wbg_ptr, ptr0);
    }
}

const BitcoinAddressFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinaddress_free(ptr >>> 0, 1));

export class BitcoinAddress {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAddress.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAddressFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAddressFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinaddress_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinaddress_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinAddress}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinaddress_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinAddress.__wrap(ret[0]);
    }
}

const BitcoinAssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinasset_free(ptr >>> 0, 1));

export class BitcoinAsset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAsset.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof BitcoinAsset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinasset_free(ptr, 0);
    }
    /**
     * @returns {BitcoinAssetId}
     */
    get id() {
        const ret = wasm.bitcoinasset_id(this.__wbg_ptr);
        return BitcoinAssetId.__wrap(ret);
    }
    /**
     * @returns {string}
     */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinasset_name(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinasset_symbol(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {number}
     */
    get decimals() {
        const ret = wasm.bitcoinasset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get logoUrl() {
        const ret = wasm.bitcoinasset_logoUrl(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {AssetType}
     */
    get assetType() {
        const ret = wasm.bitcoinasset_assetType(this.__wbg_ptr);
        return ret;
    }
}

const BitcoinAssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinassetbalance_free(ptr >>> 0, 1));

export class BitcoinAssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinassetbalance_free(ptr, 0);
    }
    /**
     * @returns {BitcoinAsset}
     */
    get asset() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
     * @param {BitcoinAsset} arg0
     */
    set asset(arg0) {
        _assertClass(arg0, BitcoinAsset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OnchainBalance}
     */
    get onchain_balance() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
     * @param {OnchainBalance} arg0
     */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OffchainBalance}
     */
    get offchain_balance() {
        const ret = wasm.__wbg_get_bitcoinassetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * @param {OffchainBalance} arg0
     */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_bitcoinassetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const BitcoinAssetIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinassetid_free(ptr >>> 0, 1));

export class BitcoinAssetId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinAssetId.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinAssetIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinAssetIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinassetid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinassetid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinAssetId}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinassetid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinAssetId.__wrap(ret[0]);
    }
}

const BitcoinBlockHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockhash_free(ptr >>> 0, 1));

export class BitcoinBlockHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockHash.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockhash_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinblockhash_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinBlockHash}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinblockhash_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinBlockHash.__wrap(ret[0]);
    }
}

const BitcoinBlockHeaderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockheader_free(ptr >>> 0, 1));

export class BitcoinBlockHeader {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockHeader.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockHeaderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockHeaderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockheader_free(ptr, 0);
    }
    /**
     * @returns {bigint}
     */
    get number() {
        const ret = wasm.bitcoinblockheader_number(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint}
     */
    get timestamp() {
        const ret = wasm.bitcoinblockheader_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {BitcoinBlockHash}
     */
    get hash() {
        const ret = wasm.bitcoinblockheader_hash(this.__wbg_ptr);
        return BitcoinBlockHash.__wrap(ret);
    }
}

const BitcoinBlockchainConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinblockchainconfig_free(ptr >>> 0, 1));

export class BitcoinBlockchainConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinBlockchainConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinBlockchainConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinBlockchainConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinblockchainconfig_free(ptr, 0);
    }
    /**
     * @param {string} esplora_url
     * @returns {BitcoinBlockchainConfig}
     */
    static newEsplora(esplora_url) {
        const ptr0 = passStringToWasm0(esplora_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinblockchainconfig_newEsplora(ptr0, len0);
        return BitcoinBlockchainConfig.__wrap(ret);
    }
}

const BitcoinConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinconfig_free(ptr >>> 0, 1));

export class BitcoinConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinconfig_free(ptr, 0);
    }
    /**
     * @param {BitcoinBlockchainConfig} blockchain_config
     * @param {BitcoinWalletConfig} wallet_config
     * @returns {BitcoinConfig}
     */
    static newWatchOnlyClient(blockchain_config, wallet_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(wallet_config, BitcoinWalletConfig);
        const ret = wasm.bitcoinconfig_newWatchOnlyClient(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
     * @param {BitcoinBlockchainConfig} blockchain_config
     * @param {BitcoinSignerConfig} signer_config
     * @returns {BitcoinConfig}
     */
    static newClient(blockchain_config, signer_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        const ret = wasm.bitcoinconfig_newClient(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
     * @param {BitcoinBlockchainConfig} blockchain_config
     * @param {BitcoinWalletConfig} wallet_config
     * @param {BitcoinPublicKey} node_id
     * @returns {BitcoinConfig}
     */
    static newWatchOnlyNode(blockchain_config, wallet_config, node_id) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(wallet_config, BitcoinWalletConfig);
        _assertClass(node_id, BitcoinPublicKey);
        const ret = wasm.bitcoinconfig_newWatchOnlyNode(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr, node_id.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
     * @param {BitcoinBlockchainConfig} blockchain_config
     * @param {BitcoinSignerConfig} signer_config
     * @param {BitcoinPublicKey} node_id
     * @returns {BitcoinConfig}
     */
    static newWatchOnlyNodeWithSigner(blockchain_config, signer_config, node_id) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        _assertClass(node_id, BitcoinPublicKey);
        const ret = wasm.bitcoinconfig_newWatchOnlyNodeWithSigner(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_id.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
    /**
     * @param {BitcoinBlockchainConfig} blockchain_config
     * @param {BitcoinSignerConfig} signer_config
     * @param {BitcoinLightningNodeConfig} node_config
     * @returns {BitcoinConfig}
     */
    static newNode(blockchain_config, signer_config, node_config) {
        _assertClass(blockchain_config, BitcoinBlockchainConfig);
        _assertClass(signer_config, BitcoinSignerConfig);
        _assertClass(node_config, BitcoinLightningNodeConfig);
        const ret = wasm.bitcoinconfig_newNode(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_config.__wbg_ptr);
        return BitcoinConfig.__wrap(ret);
    }
}

const BitcoinEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinevent_free(ptr >>> 0, 1));

export class BitcoinEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinEvent.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinevent_free(ptr, 0);
    }
    /**
     * @returns {BitcoinNetwork}
     */
    get network() {
        const ret = wasm.bitcoinevent_network(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EventType}
     */
    get eventType() {
        const ret = wasm.bitcoinevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinBlockHeader | undefined}
     */
    get blockHeader() {
        const ret = wasm.bitcoinevent_blockHeader(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinBlockHeader.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get peerId() {
        const ret = wasm.bitcoinevent_peerId(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {BitcoinAssetBalance | undefined}
     */
    get balanceUpdate() {
        const ret = wasm.bitcoinevent_balanceUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAssetBalance.__wrap(ret);
    }
    /**
     * @returns {BitcoinTransaction | undefined}
     */
    get transactionUpdate() {
        const ret = wasm.bitcoinevent_transactionUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTransaction.__wrap(ret);
    }
    /**
     * @returns {BitcoinTxid | undefined}
     */
    get transactionRemoved() {
        const ret = wasm.bitcoinevent_transactionRemoved(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTxid.__wrap(ret);
    }
    /**
     * @returns {BitcoinLightningChannel | undefined}
     */
    get channelUpdate() {
        const ret = wasm.bitcoinevent_channelUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningChannel.__wrap(ret);
    }
    /**
     * @returns {LightningChannelId | undefined}
     */
    get channelId() {
        const ret = wasm.bitcoinevent_channelId(this.__wbg_ptr);
        return ret === 0 ? undefined : LightningChannelId.__wrap(ret);
    }
    /**
     * @returns {BitcoinAsset | undefined}
     */
    get asset() {
        const ret = wasm.bitcoinevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
     * @returns {BitcoinAssetId | undefined}
     */
    get assetId() {
        const ret = wasm.bitcoinevent_assetId(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAssetId.__wrap(ret);
    }
    /**
     * @returns {BitcoinAssetId[] | undefined}
     */
    get assetIds() {
        const ret = wasm.bitcoinevent_assetIds(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        }
        return v1;
    }
    /**
     * @returns {BitcoinLightningAssetChannel | undefined}
     */
    get assetChannel() {
        const ret = wasm.bitcoinevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningAssetChannel.__wrap(ret);
    }
}

const BitcoinFeeEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinfeeestimate_free(ptr >>> 0, 1));

export class BitcoinFeeEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinFeeEstimate.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinFeeEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinFeeEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinfeeestimate_free(ptr, 0);
    }
    /**
     * @returns {BitcoinFeeRate}
     */
    get low() {
        const ret = wasm.bitcoinfeeestimate_low(this.__wbg_ptr);
        return BitcoinFeeRate.__wrap(ret);
    }
    /**
     * @returns {BitcoinFeeRate}
     */
    get medium() {
        const ret = wasm.bitcoinfeeestimate_medium(this.__wbg_ptr);
        return BitcoinFeeRate.__wrap(ret);
    }
    /**
     * @returns {BitcoinFeeRate}
     */
    get high() {
        const ret = wasm.bitcoinfeeestimate_high(this.__wbg_ptr);
        return BitcoinFeeRate.__wrap(ret);
    }
}

const BitcoinFeeRateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinfeerate_free(ptr >>> 0, 1));

export class BitcoinFeeRate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinFeeRate.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinFeeRateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinFeeRateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinfeerate_free(ptr, 0);
    }
    /**
     * @param {bigint} sat_per_kwu
     */
    constructor(sat_per_kwu) {
        const ret = wasm.bitcoinfeerate_new(sat_per_kwu);
        this.__wbg_ptr = ret >>> 0;
        BitcoinFeeRateFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @returns {bigint}
     */
    get satPerKwu() {
        const ret = wasm.bitcoinfeerate_satPerKwu(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
}

const BitcoinLightningAssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningassetchannel_free(ptr >>> 0, 1));

export class BitcoinLightningAssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningAssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningAssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningAssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningassetchannel_free(ptr, 0);
    }
    /**
     * @returns {BitcoinAsset}
     */
    get asset() {
        const ret = wasm.bitcoinlightningassetchannel_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
     * @returns {AssetChannelStatus}
     */
    get status() {
        const ret = wasm.bitcoinlightningassetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OffchainBalance}
     */
    get balance() {
        const ret = wasm.bitcoinlightningassetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * @returns {BitcoinTxid | undefined}
     */
    get lastOnchainTxid() {
        const ret = wasm.bitcoinlightningassetchannel_lastOnchainTxid(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTxid.__wrap(ret);
    }
    /**
     * @returns {bigint}
     */
    get lastOperationConfirmations() {
        const ret = wasm.bitcoinlightningassetchannel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint}
     */
    get lastOperationTimestampSecs() {
        const ret = wasm.bitcoinlightningassetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint | undefined}
     */
    get rentalExpiryTimestampSecs() {
        const ret = wasm.bitcoinlightningassetchannel_rentalExpiryTimestampSecs(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
}

const BitcoinLightningChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningchannel_free(ptr >>> 0, 1));

export class BitcoinLightningChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningChannel.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningchannel_free(ptr, 0);
    }
    /**
     * @returns {LightningChannelId}
     */
    get id() {
        const ret = wasm.bitcoinlightningchannel_id(this.__wbg_ptr);
        return LightningChannelId.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey}
     */
    get counterparty() {
        const ret = wasm.bitcoinlightningchannel_counterparty(this.__wbg_ptr);
        return BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {ChannelStatus}
     */
    get status() {
        const ret = wasm.bitcoinlightningchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinLightningAssetChannel[]}
     */
    get assetChannels() {
        const ret = wasm.bitcoinlightningchannel_assetChannels(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
}

const BitcoinLightningInvoiceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightninginvoice_free(ptr >>> 0, 1));

export class BitcoinLightningInvoice {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningInvoice.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningInvoiceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningInvoiceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightninginvoice_free(ptr, 0);
    }
    /**
     * @returns {BitcoinNetwork}
     */
    get network() {
        const ret = wasm.bitcoinlightninginvoice_network(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {PaymentHash}
     */
    get paymentHash() {
        const ret = wasm.bitcoinlightninginvoice_paymentHash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
     * @returns {BitcoinAssetId}
     */
    get assetId() {
        const ret = wasm.bitcoinlightninginvoice_assetId(this.__wbg_ptr);
        return BitcoinAssetId.__wrap(ret);
    }
    /**
     * @returns {U256 | undefined}
     */
    get amount() {
        const ret = wasm.bitcoinlightninginvoice_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : U256.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey}
     */
    get recipient() {
        const ret = wasm.bitcoinlightninginvoice_recipient(this.__wbg_ptr);
        return BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {bigint | undefined}
     */
    get expiryTimestamp() {
        const ret = wasm.bitcoinlightninginvoice_expiryTimestamp(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinlightninginvoice_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinLightningInvoice}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinlightninginvoice_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinLightningInvoice.__wrap(ret[0]);
    }
}

const BitcoinLightningNodeConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningnodeconfig_free(ptr >>> 0, 1));

export class BitcoinLightningNodeConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningNodeConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningnodeconfig_free(ptr, 0);
    }
    /**
     * @param {string} lightning_gossip_sync_url
     * @param {string} websocket_proxy_url
     */
    constructor(lightning_gossip_sync_url, websocket_proxy_url) {
        const ptr0 = passStringToWasm0(lightning_gossip_sync_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(websocket_proxy_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinlightningnodeconfig_new(ptr0, len0, ptr1, len1);
        this.__wbg_ptr = ret >>> 0;
        BitcoinLightningNodeConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const BitcoinLightningPaymentFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningpayment_free(ptr >>> 0, 1));

export class BitcoinLightningPayment {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningPayment.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningPaymentFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningPaymentFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningpayment_free(ptr, 0);
    }
    /**
     * @returns {LightningPaymentId}
     */
    get id() {
        const ret = wasm.bitcoinlightningpayment_id(this.__wbg_ptr);
        return LightningPaymentId.__wrap(ret);
    }
    /**
     * @returns {PaymentHash | undefined}
     */
    get hash() {
        const ret = wasm.bitcoinlightningpayment_hash(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentHash.__wrap(ret);
    }
    /**
     * @returns {PaymentPreimage | undefined}
     */
    get preimage() {
        const ret = wasm.bitcoinlightningpayment_preimage(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentPreimage.__wrap(ret);
    }
    /**
     * @returns {OffchainPaymentStatus}
     */
    get status() {
        const ret = wasm.bitcoinlightningpayment_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OffchainPaymentType}
     */
    get paymentType() {
        const ret = wasm.bitcoinlightningpayment_paymentType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint}
     */
    get timestamp() {
        const ret = wasm.bitcoinlightningpayment_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {BitcoinOffchainOperation[]}
     */
    get operations() {
        const ret = wasm.bitcoinlightningpayment_operations(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get spent() {
        const ret = wasm.bitcoinlightningpayment_spent(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get received() {
        const ret = wasm.bitcoinlightningpayment_received(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get balanceChanges() {
        const ret = wasm.bitcoinlightningpayment_balanceChanges(this.__wbg_ptr);
        return ret;
    }
}

const BitcoinLightningPeerFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinlightningpeer_free(ptr >>> 0, 1));

export class BitcoinLightningPeer {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinLightningPeer.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinLightningPeerFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinLightningPeerFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinlightningpeer_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinlightningpeer_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinLightningPeer}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinlightningpeer_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinLightningPeer.__wrap(ret[0]);
    }
}

const BitcoinOffchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinoffchainoperation_free(ptr >>> 0, 1));

export class BitcoinOffchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinOffchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinOffchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinOffchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinoffchainoperation_free(ptr, 0);
    }
    /**
     * @returns {OffchainOperationType}
     */
    get operationType() {
        const ret = wasm.bitcoinoffchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinAsset}
     */
    get asset() {
        const ret = wasm.bitcoinoffchainoperation_asset(this.__wbg_ptr);
        return BitcoinAsset.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.bitcoinoffchainoperation_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get from() {
        const ret = wasm.bitcoinoffchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get to() {
        const ret = wasm.bitcoinoffchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get routedFrom() {
        const ret = wasm.bitcoinoffchainoperation_routedFrom(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get routedTo() {
        const ret = wasm.bitcoinoffchainoperation_routedTo(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get earnedFee() {
        const ret = wasm.bitcoinoffchainoperation_earnedFee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const BitcoinOnchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinonchainoperation_free(ptr >>> 0, 1));

export class BitcoinOnchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinOnchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinOnchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinOnchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinonchainoperation_free(ptr, 0);
    }
    /**
     * @returns {OnchainOperationType}
     */
    get operationType() {
        const ret = wasm.bitcoinonchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinAsset | undefined}
     */
    get asset() {
        const ret = wasm.bitcoinonchainoperation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAsset.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get amount() {
        const ret = wasm.bitcoinonchainoperation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {BitcoinAddress | undefined}
     */
    get to() {
        const ret = wasm.bitcoinonchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAddress.__wrap(ret);
    }
    /**
     * @returns {BitcoinAddress | undefined}
     */
    get from() {
        const ret = wasm.bitcoinonchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinAddress.__wrap(ret);
    }
    /**
     * @returns {LightningChannelId | undefined}
     */
    get channel_id() {
        const ret = wasm.bitcoinonchainoperation_channel_id(this.__wbg_ptr);
        return ret === 0 ? undefined : LightningChannelId.__wrap(ret);
    }
    /**
     * @returns {BitcoinPublicKey | undefined}
     */
    get counterparty() {
        const ret = wasm.bitcoinonchainoperation_counterparty(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinPublicKey.__wrap(ret);
    }
    /**
     * @returns {Map<BitcoinAsset, AssetChannelAmounts> | undefined}
     */
    get assetChannelAmounts() {
        const ret = wasm.bitcoinonchainoperation_assetChannelAmounts(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Deadline | undefined}
     */
    get disputeDeadline() {
        const ret = wasm.bitcoinonchainoperation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
}

const BitcoinPublicKeyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinpublickey_free(ptr >>> 0, 1));

export class BitcoinPublicKey {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinPublicKey.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinPublicKeyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinPublicKeyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinpublickey_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcoinpublickey_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinPublicKey}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinpublickey_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinPublicKey.__wrap(ret[0]);
    }
}

const BitcoinRentalOptionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinrentaloption_free(ptr >>> 0, 1));
/**
 * Option to specify the rental payment method
 */
export class BitcoinRentalOption {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinRentalOption.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinRentalOptionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinRentalOptionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinrentaloption_free(ptr, 0);
    }
    /**
     * Option to rent the channel by paying with a Bitcoin asset
     *
     * # Arguments
     *
     * * `payment_network` - The Bitcoin network to pay the rental fee with
     * * `payment_asset` - The Bitcoin asset to pay the rental fee with
     * * `rental_tx_fee_rate` - The fee rate that the rental node will use to open the channel
     * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
     * @param {BitcoinNetwork} payment_network
     * @param {BitcoinAsset} payment_asset
     * @param {BitcoinFeeRate} rental_tx_fee_rate
     * @param {RentalPaymentType} payment_type
     * @returns {BitcoinRentalOption}
     */
    static newBitcoinPayment(payment_network, payment_asset, rental_tx_fee_rate, payment_type) {
        _assertClass(payment_asset, BitcoinAsset);
        _assertClass(rental_tx_fee_rate, BitcoinFeeRate);
        const ret = wasm.bitcoinrentaloption_newBitcoinPayment(payment_network, payment_asset.__wbg_ptr, rental_tx_fee_rate.__wbg_ptr, payment_type);
        return BitcoinRentalOption.__wrap(ret);
    }
    /**
     * Option to rent the channel by paying with an Evm asset
     *
     * # Arguments
     *
     * * `payment_network` - The Evm network to pay the rental fee with
     * * `payment_asset` - The Evm asset to pay the rental fee with
     * * `rental_tx_fee_rate` - The fee rate that the rental node will use to open the channel
     * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
     * @param {EvmNetwork} payment_network
     * @param {EvmAsset} payment_asset
     * @param {BitcoinFeeRate} rental_tx_fee_rate
     * @param {RentalPaymentType} payment_type
     * @returns {BitcoinRentalOption}
     */
    static newEvmPayment(payment_network, payment_asset, rental_tx_fee_rate, payment_type) {
        _assertClass(payment_asset, EvmAsset);
        _assertClass(rental_tx_fee_rate, BitcoinFeeRate);
        const ret = wasm.bitcoinrentaloption_newEvmPayment(payment_network, payment_asset.__wbg_ptr, rental_tx_fee_rate.__wbg_ptr, payment_type);
        return BitcoinRentalOption.__wrap(ret);
    }
    /**
     * Option to rent the channel by dual funding
     *
     * # Arguments
     *
     * * `self_amount` - The amount to be deposited on the local side of the channel
     * @param {Amount} self_amount
     * @returns {BitcoinRentalOption}
     */
    static newDualFund(self_amount) {
        _assertClass(self_amount, Amount);
        const ret = wasm.bitcoinrentaloption_newDualFund(self_amount.__wbg_ptr);
        return BitcoinRentalOption.__wrap(ret);
    }
}

const BitcoinSignedTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinsignedtransactionrequest_free(ptr >>> 0, 1));

export class BitcoinSignedTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinSignedTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinSignedTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinSignedTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinsignedtransactionrequest_free(ptr, 0);
    }
}

const BitcoinSignerConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinsignerconfig_free(ptr >>> 0, 1));

export class BitcoinSignerConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinSignerConfig.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinSignerConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinSignerConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinsignerconfig_free(ptr, 0);
    }
    /**
     * @param {Mnemonic} mnemonic
     * @param {string} password
     * @returns {BitcoinSignerConfig}
     */
    static newHot(mnemonic, password) {
        _assertClass(mnemonic, Mnemonic);
        const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinsignerconfig_newHot(mnemonic.__wbg_ptr, ptr0, len0);
        return BitcoinSignerConfig.__wrap(ret);
    }
}

const BitcoinTransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointransaction_free(ptr >>> 0, 1));

export class BitcoinTransaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTransaction.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointransaction_free(ptr, 0);
    }
    /**
     * @returns {BitcoinTxid}
     */
    get id() {
        const ret = wasm.bitcointransaction_id(this.__wbg_ptr);
        return BitcoinTxid.__wrap(ret);
    }
    /**
     * @returns {OnchainTxStatus}
     */
    get status() {
        const ret = wasm.bitcointransaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint | undefined}
     */
    get blockHeight() {
        const ret = wasm.bitcointransaction_blockHeight(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get timestamp() {
        const ret = wasm.bitcointransaction_timestamp(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint}
     */
    get confirmations() {
        const ret = wasm.bitcointransaction_confirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.bitcointransaction_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get spent() {
        const ret = wasm.bitcointransaction_spent(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get received() {
        const ret = wasm.bitcointransaction_received(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinOnchainOperation[]}
     */
    get operations() {
        const ret = wasm.bitcointransaction_operations(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Map<BitcoinAsset, Amount>}
     */
    get balanceChanges() {
        const ret = wasm.bitcointransaction_balanceChanges(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OnchainTxType}
     */
    get transactionType() {
        const ret = wasm.bitcointransaction_transactionType(this.__wbg_ptr);
        return ret;
    }
}

const BitcoinTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointransactionrequest_free(ptr >>> 0, 1));

export class BitcoinTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointransactionrequest_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.bitcointransactionrequest_fee(this.__wbg_ptr);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Amount.__wrap(ret[0]);
    }
}

const BitcoinTxidFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcointxid_free(ptr >>> 0, 1));

export class BitcoinTxid {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(BitcoinTxid.prototype);
        obj.__wbg_ptr = ptr;
        BitcoinTxidFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinTxidFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcointxid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.bitcointxid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {BitcoinTxid}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcointxid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return BitcoinTxid.__wrap(ret[0]);
    }
}

const BitcoinWalletConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_bitcoinwalletconfig_free(ptr >>> 0, 1));

export class BitcoinWalletConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BitcoinWalletConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_bitcoinwalletconfig_free(ptr, 0);
    }
    /**
     * @param {ExtendedPubKey} xpub
     * @param {Fingerprint} master_fingerprint
     */
    constructor(xpub, master_fingerprint) {
        _assertClass(xpub, ExtendedPubKey);
        _assertClass(master_fingerprint, Fingerprint);
        const ret = wasm.bitcoinwalletconfig_new(xpub.__wbg_ptr, master_fingerprint.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        BitcoinWalletConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const CandlestickFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_candlestick_free(ptr >>> 0, 1));

export class Candlestick {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Candlestick.prototype);
        obj.__wbg_ptr = ptr;
        CandlestickFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CandlestickFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_candlestick_free(ptr, 0);
    }
    /**
     * @returns {bigint}
     */
    get timestampMillis() {
        const ret = wasm.candlestick_timestampMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {Amount}
     */
    get open() {
        const ret = wasm.candlestick_open(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get high() {
        const ret = wasm.candlestick_high(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get low() {
        const ret = wasm.candlestick_low(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get close() {
        const ret = wasm.candlestick_close(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get baseVolume() {
        const ret = wasm.candlestick_baseVolume(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get quoteVolume() {
        const ret = wasm.candlestick_quoteVolume(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const CandlestickUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_candlestickupdate_free(ptr >>> 0, 1));

export class CandlestickUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(CandlestickUpdate.prototype);
        obj.__wbg_ptr = ptr;
        CandlestickUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CandlestickUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_candlestickupdate_free(ptr, 0);
    }
    /**
     * @returns {CandlestickInterval}
     */
    get interval() {
        const ret = wasm.__wbg_get_candlestickupdate_interval(this.__wbg_ptr);
        return ret;
    }
    /**
     * @param {CandlestickInterval} arg0
     */
    set interval(arg0) {
        wasm.__wbg_set_candlestickupdate_interval(this.__wbg_ptr, arg0);
    }
    /**
     * @returns {Candlestick}
     */
    get candlestick() {
        const ret = wasm.__wbg_get_candlestickupdate_candlestick(this.__wbg_ptr);
        return Candlestick.__wrap(ret);
    }
    /**
     * @param {Candlestick} arg0
     */
    set candlestick(arg0) {
        _assertClass(arg0, Candlestick);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_candlestickupdate_candlestick(this.__wbg_ptr, ptr0);
    }
}

const ChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_channel_free(ptr >>> 0, 1));

export class Channel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Channel.prototype);
        obj.__wbg_ptr = ptr;
        ChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_channel_free(ptr, 0);
    }
    /**
     * The protocol of the channel
     * @returns {Protocol}
     */
    get protocol() {
        const ret = wasm.channel_offchainProtocol(this.__wbg_ptr);
        return ret;
    }
    /**
     * The offchain protocol of the channel
     * @returns {OffchainProtocol}
     */
    get offchainProtocol() {
        const ret = wasm.channel_offchainProtocol(this.__wbg_ptr);
        return ret;
    }
    /**
     * The network of the channel
     * @returns {Network}
     */
    get network() {
        const ret = wasm.channel_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
     * The id of the channel
     * @returns {string}
     */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.channel_id(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * The counterparty of the channel
     * @returns {string}
     */
    get counterparty() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.channel_counterparty(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * The status of the channel
     * @returns {ChannelStatus}
     */
    get status() {
        const ret = wasm.channel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * The asset channels of the channel
     * @returns {AssetChannel[]}
     */
    get assetChannels() {
        const ret = wasm.channel_assetChannels(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {BitcoinLightningChannel | undefined}
     */
    asBitcoinLightningChannel() {
        const ret = wasm.channel_asBitcoinLightningChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningChannel.__wrap(ret);
    }
    /**
     * @returns {EvmLithiumChannel | undefined}
     */
    asEvmLithiumChannel() {
        const ret = wasm.channel_asEvmLithiumChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumChannel.__wrap(ret);
    }
}

const ClientTradeFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_clienttrade_free(ptr >>> 0, 1));

export class ClientTrade {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ClientTrade.prototype);
        obj.__wbg_ptr = ptr;
        ClientTradeFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ClientTradeFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_clienttrade_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get swapId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.clienttrade_swapId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get orderId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.clienttrade_orderId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {Amount}
     */
    get baseAmount() {
        const ret = wasm.clienttrade_baseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get quoteAmount() {
        const ret = wasm.clienttrade_quoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get price() {
        const ret = wasm.clienttrade_price(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {bigint}
     */
    get timestampMillis() {
        const ret = wasm.clienttrade_timestampMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {OrderSide}
     */
    get orderSide() {
        const ret = wasm.clienttrade_orderSide(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OrderType}
     */
    get orderType() {
        const ret = wasm.clienttrade_orderType(this.__wbg_ptr);
        return ret;
    }
}

const ClientTradeUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_clienttradeupdate_free(ptr >>> 0, 1));

export class ClientTradeUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ClientTradeUpdate.prototype);
        obj.__wbg_ptr = ptr;
        ClientTradeUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ClientTradeUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_clienttradeupdate_free(ptr, 0);
    }
    /**
     * @returns {Asset}
     */
    get base() {
        const ret = wasm.__wbg_get_clienttradeupdate_base(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set base(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_clienttradeupdate_base(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Asset}
     */
    get quote() {
        const ret = wasm.__wbg_get_clienttradeupdate_quote(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set quote(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_clienttradeupdate_quote(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {ClientTrade}
     */
    get trade() {
        const ret = wasm.__wbg_get_clienttradeupdate_trade(this.__wbg_ptr);
        return ClientTrade.__wrap(ret);
    }
    /**
     * @param {ClientTrade} arg0
     */
    set trade(arg0) {
        _assertClass(arg0, ClientTrade);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_clienttradeupdate_trade(this.__wbg_ptr, ptr0);
    }
}

const ConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_config_free(ptr >>> 0, 1));

export class Config {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_config_free(ptr, 0);
    }
    /**
     * @param {string} db_name
     * @param {string | null | undefined} cmc_url
     * @param {string | null | undefined} cmc_api_key
     * @param {Array<FiatCurrency> | null | undefined} fiat_currencies
     * @param {string | null | undefined} auth_grpc_url
     * @param {string | null | undefined} auth_ws_url
     * @param {string | null | undefined} backup_grpc_url
     * @param {string | null | undefined} rental_url
     * @param {LogLevel} log_level
     */
    constructor(db_name, cmc_url, cmc_api_key, fiat_currencies, auth_grpc_url, auth_ws_url, backup_grpc_url, rental_url, log_level) {
        const ptr0 = passStringToWasm0(db_name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        var ptr1 = isLikeNone(cmc_url) ? 0 : passStringToWasm0(cmc_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        var ptr2 = isLikeNone(cmc_api_key) ? 0 : passStringToWasm0(cmc_api_key, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len2 = WASM_VECTOR_LEN;
        var ptr3 = isLikeNone(auth_grpc_url) ? 0 : passStringToWasm0(auth_grpc_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len3 = WASM_VECTOR_LEN;
        var ptr4 = isLikeNone(auth_ws_url) ? 0 : passStringToWasm0(auth_ws_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len4 = WASM_VECTOR_LEN;
        var ptr5 = isLikeNone(backup_grpc_url) ? 0 : passStringToWasm0(backup_grpc_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len5 = WASM_VECTOR_LEN;
        var ptr6 = isLikeNone(rental_url) ? 0 : passStringToWasm0(rental_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len6 = WASM_VECTOR_LEN;
        const ret = wasm.config_new(ptr0, len0, ptr1, len1, ptr2, len2, isLikeNone(fiat_currencies) ? 0 : addToExternrefTable0(fiat_currencies), ptr3, len3, ptr4, len4, ptr5, len5, ptr6, len6, log_level);
        this.__wbg_ptr = ret >>> 0;
        ConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const CurrencyBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_currencybalance_free(ptr >>> 0, 1));

export class CurrencyBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(CurrencyBalance.prototype);
        obj.__wbg_ptr = ptr;
        CurrencyBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CurrencyBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_currencybalance_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get sending() {
        const ret = wasm.currencybalance_sending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get receiving() {
        const ret = wasm.currencybalance_receiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get lockedSending() {
        const ret = wasm.currencybalance_lockedSending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get lockedReceiving() {
        const ret = wasm.currencybalance_lockedReceiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const CurrencyInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_currencyinfo_free(ptr >>> 0, 1));

export class CurrencyInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(CurrencyInfo.prototype);
        obj.__wbg_ptr = ptr;
        CurrencyInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CurrencyInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_currencyinfo_free(ptr, 0);
    }
    /**
     * @returns {Protocol}
     */
    get protocol() {
        const ret = wasm.currencyinfo_protocol(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string}
     */
    get networkId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.currencyinfo_networkId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get assetId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.currencyinfo_assetId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {number}
     */
    get decimals() {
        const ret = wasm.currencyinfo_decimals(this.__wbg_ptr);
        return ret >>> 0;
    }
}

const DeadlineFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_deadline_free(ptr >>> 0, 1));

export class Deadline {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Deadline.prototype);
        obj.__wbg_ptr = ptr;
        DeadlineFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        DeadlineFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_deadline_free(ptr, 0);
    }
    /**
     * @returns {DeadlineType}
     */
    get eventType() {
        const ret = wasm.deadline_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint | undefined}
     */
    get timestampSecs() {
        const ret = wasm.deadline_timestampSecs(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get blockNumber() {
        const ret = wasm.deadline_blockNumber(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get blockDelta() {
        const ret = wasm.deadline_blockDelta(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
}

const DexEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_dexevent_free(ptr >>> 0, 1));

export class DexEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(DexEvent.prototype);
        obj.__wbg_ptr = ptr;
        DexEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        DexEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_dexevent_free(ptr, 0);
    }
    /**
     * @returns {DexEventType}
     */
    get eventType() {
        const ret = wasm.dexevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint}
     */
    get timestampMillis() {
        const ret = wasm.dexevent_timestampMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {string | undefined}
     */
    get orderId() {
        const ret = wasm.dexevent_orderId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {MatchedOrder | undefined}
     */
    get matchedOrder() {
        const ret = wasm.dexevent_matchedOrder(this.__wbg_ptr);
        return ret === 0 ? undefined : MatchedOrder.__wrap(ret);
    }
    /**
     * @returns {SwapProgress | undefined}
     */
    get swapProgress() {
        const ret = wasm.dexevent_swapProgress(this.__wbg_ptr);
        return ret === 0 ? undefined : SwapProgress.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get swapId() {
        const ret = wasm.dexevent_swapId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {ClientTradeUpdate | undefined}
     */
    get tradeUpdate() {
        const ret = wasm.dexevent_tradeUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : ClientTradeUpdate.__wrap(ret);
    }
    /**
     * @returns {BalanceUpdate | undefined}
     */
    get balanceUpdate() {
        const ret = wasm.dexevent_balanceUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : BalanceUpdate.__wrap(ret);
    }
}

const DualFundDepositFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_dualfunddeposit_free(ptr >>> 0, 1));

export class DualFundDeposit {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(DualFundDeposit.prototype);
        obj.__wbg_ptr = ptr;
        DualFundDepositFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        DualFundDepositFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_dualfunddeposit_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get txid() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.dualfunddeposit_txid(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get channelId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.dualfunddeposit_channelId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {Amount}
     */
    get selfAmount() {
        const ret = wasm.dualfunddeposit_selfAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get counterpartyAmount() {
        const ret = wasm.dualfunddeposit_counterpartyAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const DualFundDepositEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_dualfunddepositestimate_free(ptr >>> 0, 1));

export class DualFundDepositEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(DualFundDepositEstimate.prototype);
        obj.__wbg_ptr = ptr;
        DualFundDepositEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        DualFundDepositEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_dualfunddepositestimate_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get selfAmount() {
        const ret = wasm.dualfunddepositestimate_selfAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get counterpartyAmount() {
        const ret = wasm.dualfunddepositestimate_counterpartyAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.dualfunddepositestimate_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    get isOpening() {
        const ret = wasm.dualfunddepositestimate_isOpening(this.__wbg_ptr);
        return ret !== 0;
    }
}

const EvmAddressFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmaddress_free(ptr >>> 0, 1));

export class EvmAddress {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAddress.prototype);
        obj.__wbg_ptr = ptr;
        EvmAddressFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAddressFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmaddress_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmaddress_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {EvmAddress}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.evmaddress_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return EvmAddress.__wrap(ret[0]);
    }
}

const EvmAssetFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmasset_free(ptr >>> 0, 1));

export class EvmAsset {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAsset.prototype);
        obj.__wbg_ptr = ptr;
        EvmAssetFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof EvmAsset)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAssetFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmasset_free(ptr, 0);
    }
    /**
     * @returns {EvmAddress}
     */
    get id() {
        const ret = wasm.evmasset_id(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
     * @returns {string}
     */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmasset_name(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmasset_symbol(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {number}
     */
    get decimals() {
        const ret = wasm.evmasset_decimals(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get logoUrl() {
        const ret = wasm.evmasset_logoUrl(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {AssetType}
     */
    get assetType() {
        const ret = wasm.evmasset_assetType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmToken | undefined}
     */
    get token() {
        const ret = wasm.evmasset_token(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmToken.__wrap(ret);
    }
}

const EvmAssetBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmassetbalance_free(ptr >>> 0, 1));

export class EvmAssetBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmAssetBalance.prototype);
        obj.__wbg_ptr = ptr;
        EvmAssetBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmAssetBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmassetbalance_free(ptr, 0);
    }
    /**
     * @returns {EvmAsset}
     */
    get asset() {
        const ret = wasm.__wbg_get_evmassetbalance_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
     * @param {EvmAsset} arg0
     */
    set asset(arg0) {
        _assertClass(arg0, EvmAsset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OnchainBalance}
     */
    get onchain_balance() {
        const ret = wasm.__wbg_get_evmassetbalance_onchain_balance(this.__wbg_ptr);
        return OnchainBalance.__wrap(ret);
    }
    /**
     * @param {OnchainBalance} arg0
     */
    set onchain_balance(arg0) {
        _assertClass(arg0, OnchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_onchain_balance(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {OffchainBalance}
     */
    get offchain_balance() {
        const ret = wasm.__wbg_get_evmassetbalance_offchain_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * @param {OffchainBalance} arg0
     */
    set offchain_balance(arg0) {
        _assertClass(arg0, OffchainBalance);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_evmassetbalance_offchain_balance(this.__wbg_ptr, ptr0);
    }
}

const EvmBlockHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockhash_free(ptr >>> 0, 1));

export class EvmBlockHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmBlockHash.prototype);
        obj.__wbg_ptr = ptr;
        EvmBlockHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockhash_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmblockhash_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {EvmBlockHash}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.evmblockhash_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return EvmBlockHash.__wrap(ret[0]);
    }
}

const EvmBlockHeaderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockheader_free(ptr >>> 0, 1));

export class EvmBlockHeader {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmBlockHeader.prototype);
        obj.__wbg_ptr = ptr;
        EvmBlockHeaderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockHeaderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockheader_free(ptr, 0);
    }
    /**
     * @returns {bigint}
     */
    get number() {
        const ret = wasm.evmblockheader_number(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint}
     */
    get timestamp() {
        const ret = wasm.evmblockheader_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {EvmBlockHash}
     */
    get hash() {
        const ret = wasm.evmblockheader_hash(this.__wbg_ptr);
        return EvmBlockHash.__wrap(ret);
    }
}

const EvmBlockchainConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmblockchainconfig_free(ptr >>> 0, 1));

export class EvmBlockchainConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmBlockchainConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmblockchainconfig_free(ptr, 0);
    }
    /**
     * @param {string} provider_url
     * @param {string | null} [etherscan_url]
     * @param {string | null} [alchemy_ws_url]
     */
    constructor(provider_url, etherscan_url, alchemy_ws_url) {
        const ptr0 = passStringToWasm0(provider_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        var ptr1 = isLikeNone(etherscan_url) ? 0 : passStringToWasm0(etherscan_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        var ptr2 = isLikeNone(alchemy_ws_url) ? 0 : passStringToWasm0(alchemy_ws_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len2 = WASM_VECTOR_LEN;
        const ret = wasm.evmblockchainconfig_new(ptr0, len0, ptr1, len1, ptr2, len2);
        this.__wbg_ptr = ret >>> 0;
        EvmBlockchainConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const EvmConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmconfig_free(ptr >>> 0, 1));

export class EvmConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmConfig.prototype);
        obj.__wbg_ptr = ptr;
        EvmConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmconfig_free(ptr, 0);
    }
    /**
     * @param {EvmBlockchainConfig} blockchain_config
     * @param {EvmWalletConfig} wallet_config
     * @returns {EvmConfig}
     */
    static newWatchOnlyClient(blockchain_config, wallet_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(wallet_config, EvmWalletConfig);
        const ret = wasm.evmconfig_newWatchOnlyClient(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
     * @param {EvmBlockchainConfig} blockchain_config
     * @param {EvmSignerConfig} signer_config
     * @returns {EvmConfig}
     */
    static newClient(blockchain_config, signer_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        const ret = wasm.evmconfig_newClient(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
     * @param {EvmBlockchainConfig} blockchain_config
     * @param {EvmWalletConfig} wallet_config
     * @param {EvmAddress} node_id
     * @returns {EvmConfig}
     */
    static newWatchOnlyNode(blockchain_config, wallet_config, node_id) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(wallet_config, EvmWalletConfig);
        _assertClass(node_id, EvmAddress);
        const ret = wasm.evmconfig_newWatchOnlyNode(blockchain_config.__wbg_ptr, wallet_config.__wbg_ptr, node_id.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
     * @param {EvmBlockchainConfig} blockchain_config
     * @param {EvmSignerConfig} signer_config
     * @param {EvmAddress} node_id
     * @returns {EvmConfig}
     */
    static newWatchOnlyNodeWithSigner(blockchain_config, signer_config, node_id) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        _assertClass(node_id, EvmAddress);
        const ret = wasm.evmconfig_newWatchOnlyNodeWithSigner(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_id.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
    /**
     * @param {EvmBlockchainConfig} blockchain_config
     * @param {EvmSignerConfig} signer_config
     * @param {EvmLithiumNodeConfig} node_config
     * @returns {EvmConfig}
     */
    static newNode(blockchain_config, signer_config, node_config) {
        _assertClass(blockchain_config, EvmBlockchainConfig);
        _assertClass(signer_config, EvmSignerConfig);
        _assertClass(node_config, EvmLithiumNodeConfig);
        const ret = wasm.evmconfig_newNode(blockchain_config.__wbg_ptr, signer_config.__wbg_ptr, node_config.__wbg_ptr);
        return EvmConfig.__wrap(ret);
    }
}

const EvmEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmevent_free(ptr >>> 0, 1));

export class EvmEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmEvent.prototype);
        obj.__wbg_ptr = ptr;
        EvmEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmevent_free(ptr, 0);
    }
    /**
     * @returns {EvmNetwork}
     */
    get network() {
        const ret = wasm.evmevent_network(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EventType}
     */
    get eventType() {
        const ret = wasm.evmevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmBlockHeader | undefined}
     */
    get blockHeader() {
        const ret = wasm.evmevent_blockHeader(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmBlockHeader.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get peerId() {
        const ret = wasm.evmevent_peerId(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAssetBalance | undefined}
     */
    get balanceUpdate() {
        const ret = wasm.evmevent_balanceUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAssetBalance.__wrap(ret);
    }
    /**
     * @returns {EvmTransaction | undefined}
     */
    get transactionUpdate() {
        const ret = wasm.evmevent_transactionUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTransaction.__wrap(ret);
    }
    /**
     * @returns {EvmTxid | undefined}
     */
    get transactionRemoved() {
        const ret = wasm.evmevent_transactionRemoved(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTxid.__wrap(ret);
    }
    /**
     * @returns {EvmLithiumChannel | undefined}
     */
    get channelUpdate() {
        const ret = wasm.evmevent_channelUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumChannel.__wrap(ret);
    }
    /**
     * @returns {LithiumChannelId | undefined}
     */
    get channelId() {
        const ret = wasm.evmevent_channelId(this.__wbg_ptr);
        return ret === 0 ? undefined : LithiumChannelId.__wrap(ret);
    }
    /**
     * @returns {EvmAsset | undefined}
     */
    get asset() {
        const ret = wasm.evmevent_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get assetId() {
        const ret = wasm.evmevent_assetId(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress[] | undefined}
     */
    get assetIds() {
        const ret = wasm.evmevent_assetIds(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        }
        return v1;
    }
    /**
     * @returns {EvmLithiumAssetChannel | undefined}
     */
    get assetChannel() {
        const ret = wasm.evmevent_assetChannel(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumAssetChannel.__wrap(ret);
    }
}

const EvmFeeEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmfeeestimate_free(ptr >>> 0, 1));

export class EvmFeeEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmFeeEstimate.prototype);
        obj.__wbg_ptr = ptr;
        EvmFeeEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmFeeEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmfeeestimate_free(ptr, 0);
    }
    /**
     * @returns {EvmFeeRate}
     */
    get low() {
        const ret = wasm.evmfeeestimate_low(this.__wbg_ptr);
        return EvmFeeRate.__wrap(ret);
    }
    /**
     * @returns {EvmFeeRate}
     */
    get medium() {
        const ret = wasm.evmfeeestimate_medium(this.__wbg_ptr);
        return EvmFeeRate.__wrap(ret);
    }
    /**
     * @returns {EvmFeeRate}
     */
    get high() {
        const ret = wasm.evmfeeestimate_high(this.__wbg_ptr);
        return EvmFeeRate.__wrap(ret);
    }
}

const EvmFeeRateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmfeerate_free(ptr >>> 0, 1));

export class EvmFeeRate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmFeeRate.prototype);
        obj.__wbg_ptr = ptr;
        EvmFeeRateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmFeeRateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmfeerate_free(ptr, 0);
    }
    /**
     * @param {U256} base_fee
     * @param {U256} max_priority_fee_per_gas
     * @param {U256} max_fee_per_gas
     */
    constructor(base_fee, max_priority_fee_per_gas, max_fee_per_gas) {
        _assertClass(base_fee, U256);
        _assertClass(max_priority_fee_per_gas, U256);
        _assertClass(max_fee_per_gas, U256);
        const ret = wasm.evmfeerate_new(base_fee.__wbg_ptr, max_priority_fee_per_gas.__wbg_ptr, max_fee_per_gas.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        EvmFeeRateFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
    /**
     * @returns {U256}
     */
    get baseFee() {
        const ret = wasm.evmfeerate_baseFee(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
    /**
     * @returns {U256}
     */
    get maxPriorityFeePerGas() {
        const ret = wasm.evmfeerate_maxPriorityFeePerGas(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
    /**
     * @returns {U256}
     */
    get maxFeePerGas() {
        const ret = wasm.evmfeerate_maxFeePerGas(this.__wbg_ptr);
        return U256.__wrap(ret);
    }
}

const EvmLithiumAssetChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumassetchannel_free(ptr >>> 0, 1));

export class EvmLithiumAssetChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumAssetChannel.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumAssetChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumAssetChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumassetchannel_free(ptr, 0);
    }
    /**
     * @returns {EvmAsset}
     */
    get asset() {
        const ret = wasm.evmlithiumassetchannel_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
     * @returns {AssetChannelStatus}
     */
    get status() {
        const ret = wasm.evmlithiumassetchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OffchainBalance}
     */
    get balance() {
        const ret = wasm.evmlithiumassetchannel_balance(this.__wbg_ptr);
        return OffchainBalance.__wrap(ret);
    }
    /**
     * @returns {EvmTxid | undefined}
     */
    get lastOnchainTxid() {
        const ret = wasm.evmlithiumassetchannel_lastOnchainTxid(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTxid.__wrap(ret);
    }
    /**
     * @returns {bigint}
     */
    get lastOperationConfirmations() {
        const ret = wasm.evmlithiumassetchannel_lastOperationConfirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint}
     */
    get lastOperationTimestampSecs() {
        const ret = wasm.evmlithiumassetchannel_lastOperationTimestampSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {bigint | undefined}
     */
    get rentalExpiryTimestampSecs() {
        const ret = wasm.evmlithiumassetchannel_rentalExpiryTimestampSecs(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
}

const EvmLithiumChannelFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumchannel_free(ptr >>> 0, 1));

export class EvmLithiumChannel {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumChannel.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumChannelFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumChannelFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumchannel_free(ptr, 0);
    }
    /**
     * @returns {LithiumChannelId}
     */
    get id() {
        const ret = wasm.evmlithiumchannel_id(this.__wbg_ptr);
        return LithiumChannelId.__wrap(ret);
    }
    /**
     * @returns {EvmAddress}
     */
    get counterparty() {
        const ret = wasm.evmlithiumchannel_counterparty(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
     * @returns {ChannelStatus}
     */
    get status() {
        const ret = wasm.evmlithiumchannel_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmLithiumAssetChannel[]}
     */
    get assetChannels() {
        const ret = wasm.evmlithiumchannel_assetChannels(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
}

const EvmLithiumInvoiceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiuminvoice_free(ptr >>> 0, 1));

export class EvmLithiumInvoice {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumInvoice.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumInvoiceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumInvoiceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiuminvoice_free(ptr, 0);
    }
    /**
     * @returns {EvmNetwork}
     */
    get network() {
        const ret = wasm.evmlithiuminvoice_network(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {PaymentHash}
     */
    get paymentHash() {
        const ret = wasm.evmlithiuminvoice_paymentHash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
     * @returns {EvmAddress}
     */
    get assetId() {
        const ret = wasm.evmlithiuminvoice_assetId(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
     * @returns {U256 | undefined}
     */
    get amount() {
        const ret = wasm.evmlithiuminvoice_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : U256.__wrap(ret);
    }
    /**
     * @returns {EvmAddress}
     */
    get recipient() {
        const ret = wasm.evmlithiuminvoice_recipient(this.__wbg_ptr);
        return EvmAddress.__wrap(ret);
    }
    /**
     * @returns {bigint | undefined}
     */
    get expiryTimestamp() {
        const ret = wasm.evmlithiuminvoice_expiryTimestamp(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmlithiuminvoice_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {EvmLithiumInvoice}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.evmlithiuminvoice_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return EvmLithiumInvoice.__wrap(ret[0]);
    }
}

const EvmLithiumNodeConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumnodeconfig_free(ptr >>> 0, 1));

export class EvmLithiumNodeConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumNodeConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumnodeconfig_free(ptr, 0);
    }
    /**
     * @param {EvmAddress} contract_address
     * @param {string} lithium_subgraph_url
     * @param {string} websocket_proxy_url
     */
    constructor(contract_address, lithium_subgraph_url, websocket_proxy_url) {
        _assertClass(contract_address, EvmAddress);
        const ptr0 = passStringToWasm0(lithium_subgraph_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ptr1 = passStringToWasm0(websocket_proxy_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        const ret = wasm.evmlithiumnodeconfig_new(contract_address.__wbg_ptr, ptr0, len0, ptr1, len1);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        this.__wbg_ptr = ret[0] >>> 0;
        EvmLithiumNodeConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const EvmLithiumPaymentFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumpayment_free(ptr >>> 0, 1));

export class EvmLithiumPayment {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumPayment.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumPaymentFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumPaymentFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumpayment_free(ptr, 0);
    }
    /**
     * @returns {LithiumPaymentId}
     */
    get id() {
        const ret = wasm.evmlithiumpayment_id(this.__wbg_ptr);
        return LithiumPaymentId.__wrap(ret);
    }
    /**
     * @returns {PaymentHash | undefined}
     */
    get hash() {
        const ret = wasm.evmlithiumpayment_hash(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentHash.__wrap(ret);
    }
    /**
     * @returns {PaymentPreimage | undefined}
     */
    get preimage() {
        const ret = wasm.evmlithiumpayment_preimage(this.__wbg_ptr);
        return ret === 0 ? undefined : PaymentPreimage.__wrap(ret);
    }
    /**
     * @returns {OffchainPaymentStatus}
     */
    get status() {
        const ret = wasm.evmlithiumpayment_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OffchainPaymentType}
     */
    get paymentType() {
        const ret = wasm.evmlithiumpayment_paymentType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint}
     */
    get timestamp() {
        const ret = wasm.evmlithiumpayment_timestamp(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {EvmOffchainOperation[]}
     */
    get operations() {
        const ret = wasm.evmlithiumpayment_operations(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get spent() {
        const ret = wasm.evmlithiumpayment_spent(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get received() {
        const ret = wasm.evmlithiumpayment_received(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get balanceChanges() {
        const ret = wasm.evmlithiumpayment_balanceChanges(this.__wbg_ptr);
        return ret;
    }
}

const EvmLithiumPeerFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmlithiumpeer_free(ptr >>> 0, 1));

export class EvmLithiumPeer {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmLithiumPeer.prototype);
        obj.__wbg_ptr = ptr;
        EvmLithiumPeerFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmLithiumPeerFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmlithiumpeer_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmlithiumpeer_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {EvmLithiumPeer}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.evmlithiumpeer_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return EvmLithiumPeer.__wrap(ret[0]);
    }
}

const EvmOffchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmoffchainoperation_free(ptr >>> 0, 1));

export class EvmOffchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmOffchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        EvmOffchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmOffchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmoffchainoperation_free(ptr, 0);
    }
    /**
     * @returns {OffchainOperationType}
     */
    get operationType() {
        const ret = wasm.evmoffchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmAsset}
     */
    get asset() {
        const ret = wasm.evmoffchainoperation_asset(this.__wbg_ptr);
        return EvmAsset.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.evmoffchainoperation_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get from() {
        const ret = wasm.evmoffchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get to() {
        const ret = wasm.evmoffchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get routedFrom() {
        const ret = wasm.evmoffchainoperation_routedFrom(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get routedTo() {
        const ret = wasm.evmoffchainoperation_routedTo(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get earnedFee() {
        const ret = wasm.evmoffchainoperation_earnedFee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const EvmOnchainOperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmonchainoperation_free(ptr >>> 0, 1));

export class EvmOnchainOperation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmOnchainOperation.prototype);
        obj.__wbg_ptr = ptr;
        EvmOnchainOperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmOnchainOperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmonchainoperation_free(ptr, 0);
    }
    /**
     * @returns {OnchainOperationType}
     */
    get operationType() {
        const ret = wasm.evmonchainoperation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmAsset | undefined}
     */
    get asset() {
        const ret = wasm.evmonchainoperation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAsset.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get amount() {
        const ret = wasm.evmonchainoperation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get to() {
        const ret = wasm.evmonchainoperation_to(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get from() {
        const ret = wasm.evmonchainoperation_from(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get spender() {
        const ret = wasm.evmonchainoperation_spender(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get address() {
        const ret = wasm.evmonchainoperation_address(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {LithiumChannelId | undefined}
     */
    get channel_id() {
        const ret = wasm.evmonchainoperation_channel_id(this.__wbg_ptr);
        return ret === 0 ? undefined : LithiumChannelId.__wrap(ret);
    }
    /**
     * @returns {EvmAddress | undefined}
     */
    get counterparty() {
        const ret = wasm.evmonchainoperation_counterparty(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmAddress.__wrap(ret);
    }
    /**
     * @returns {Map<EvmAsset, AssetChannelAmounts> | undefined}
     */
    get assetChannelAmounts() {
        const ret = wasm.evmonchainoperation_assetChannelAmounts(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Deadline | undefined}
     */
    get disputeDeadline() {
        const ret = wasm.evmonchainoperation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
}

const EvmRentalOptionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmrentaloption_free(ptr >>> 0, 1));
/**
 * Option to specify the rental payment method
 */
export class EvmRentalOption {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmRentalOption.prototype);
        obj.__wbg_ptr = ptr;
        EvmRentalOptionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmRentalOptionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmrentaloption_free(ptr, 0);
    }
    /**
     * Option to rent the channel by paying with a Bitcoin asset
     *
     * # Arguments
     *
     * * `payment_network` - The Bitcoin network to pay the rental fee with
     * * `payment_asset` - The Bitcoin asset to pay the rental fee with
     * * `rental_tx_fee_rate` - The fee rate that the rental node will use to open the channel
     * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
     * @param {BitcoinNetwork} payment_network
     * @param {BitcoinAsset} payment_asset
     * @param {EvmFeeRate} rental_tx_fee_rate
     * @param {RentalPaymentType} payment_type
     * @returns {EvmRentalOption}
     */
    static newBitcoinPayment(payment_network, payment_asset, rental_tx_fee_rate, payment_type) {
        _assertClass(payment_asset, BitcoinAsset);
        _assertClass(rental_tx_fee_rate, EvmFeeRate);
        const ret = wasm.evmrentaloption_newBitcoinPayment(payment_network, payment_asset.__wbg_ptr, rental_tx_fee_rate.__wbg_ptr, payment_type);
        return EvmRentalOption.__wrap(ret);
    }
    /**
     * Option to rent the channel by paying with an Evm asset
     *
     * # Arguments
     *
     * * `payment_network` - The Evm network to pay the rental fee with
     * * `payment_asset` - The Evm asset to pay the rental fee with
     * * `rental_tx_fee_rate` - The fee rate that the rental node will use to open the channel
     * * `payment_type` - Whether to pay the rental fee using on-chain or off-chain funds
     * @param {EvmNetwork} payment_network
     * @param {EvmAsset} payment_asset
     * @param {EvmFeeRate} rental_tx_fee_rate
     * @param {RentalPaymentType} payment_type
     * @returns {EvmRentalOption}
     */
    static newEvmPayment(payment_network, payment_asset, rental_tx_fee_rate, payment_type) {
        _assertClass(payment_asset, EvmAsset);
        _assertClass(rental_tx_fee_rate, EvmFeeRate);
        const ret = wasm.evmrentaloption_newEvmPayment(payment_network, payment_asset.__wbg_ptr, rental_tx_fee_rate.__wbg_ptr, payment_type);
        return EvmRentalOption.__wrap(ret);
    }
    /**
     * Option to rent the channel by dual funding
     *
     * # Arguments
     *
     * * `self_amount` - The amount to be deposited on the local side of the channel
     * @param {Amount} self_amount
     * @returns {EvmRentalOption}
     */
    static newDualFund(self_amount) {
        _assertClass(self_amount, Amount);
        const ret = wasm.evmrentaloption_newDualFund(self_amount.__wbg_ptr);
        return EvmRentalOption.__wrap(ret);
    }
}

const EvmSignedTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmsignedtransactionrequest_free(ptr >>> 0, 1));

export class EvmSignedTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmSignedTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        EvmSignedTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmSignedTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmsignedtransactionrequest_free(ptr, 0);
    }
}

const EvmSignerConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmsignerconfig_free(ptr >>> 0, 1));

export class EvmSignerConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmSignerConfig.prototype);
        obj.__wbg_ptr = ptr;
        EvmSignerConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmSignerConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmsignerconfig_free(ptr, 0);
    }
    /**
     * @param {Mnemonic} mnemonic
     * @param {string} password
     * @returns {EvmSignerConfig}
     */
    static newHot(mnemonic, password) {
        _assertClass(mnemonic, Mnemonic);
        const ptr0 = passStringToWasm0(password, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.bitcoinsignerconfig_newHot(mnemonic.__wbg_ptr, ptr0, len0);
        return EvmSignerConfig.__wrap(ret);
    }
}

const EvmTokenFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtoken_free(ptr >>> 0, 1));

export class EvmToken {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmToken.prototype);
        obj.__wbg_ptr = ptr;
        EvmTokenFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTokenFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtoken_free(ptr, 0);
    }
    /**
     * @returns {EvmTokenVariant}
     */
    get variant() {
        const ret = wasm.evmtoken_variant(this.__wbg_ptr);
        return ret;
    }
}

const EvmTransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtransaction_free(ptr >>> 0, 1));

export class EvmTransaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTransaction.prototype);
        obj.__wbg_ptr = ptr;
        EvmTransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtransaction_free(ptr, 0);
    }
    /**
     * @returns {EvmTxid}
     */
    get id() {
        const ret = wasm.evmtransaction_id(this.__wbg_ptr);
        return EvmTxid.__wrap(ret);
    }
    /**
     * @returns {OnchainTxStatus}
     */
    get status() {
        const ret = wasm.evmtransaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint | undefined}
     */
    get blockHeight() {
        const ret = wasm.evmtransaction_blockHeight(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get timestamp() {
        const ret = wasm.evmtransaction_timestamp(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint}
     */
    get confirmations() {
        const ret = wasm.evmtransaction_confirmations(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.evmtransaction_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get spent() {
        const ret = wasm.evmtransaction_spent(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get received() {
        const ret = wasm.evmtransaction_received(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {EvmOnchainOperation[]}
     */
    get operations() {
        const ret = wasm.evmtransaction_operations(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Map<EvmAsset, Amount>}
     */
    get balanceChanges() {
        const ret = wasm.evmtransaction_balanceChanges(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OnchainTxType}
     */
    get transactionType() {
        const ret = wasm.evmtransaction_transactionType(this.__wbg_ptr);
        return ret;
    }
}

const EvmTransactionRequestFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtransactionrequest_free(ptr >>> 0, 1));

export class EvmTransactionRequest {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTransactionRequest.prototype);
        obj.__wbg_ptr = ptr;
        EvmTransactionRequestFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTransactionRequestFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtransactionrequest_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.evmtransactionrequest_fee(this.__wbg_ptr);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Amount.__wrap(ret[0]);
    }
    /**
     * @param {EvmFeeRate} fee_rate
     */
    changeFeeRate(fee_rate) {
        _assertClass(fee_rate, EvmFeeRate);
        const ret = wasm.evmtransactionrequest_changeFeeRate(this.__wbg_ptr, fee_rate.__wbg_ptr);
        if (ret[1]) {
            throw takeFromExternrefTable0(ret[0]);
        }
    }
}

const EvmTxidFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmtxid_free(ptr >>> 0, 1));

export class EvmTxid {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(EvmTxid.prototype);
        obj.__wbg_ptr = ptr;
        EvmTxidFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmTxidFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmtxid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.evmtxid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {EvmTxid}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.evmtxid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return EvmTxid.__wrap(ret[0]);
    }
}

const EvmWalletConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_evmwalletconfig_free(ptr >>> 0, 1));

export class EvmWalletConfig {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        EvmWalletConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_evmwalletconfig_free(ptr, 0);
    }
    /**
     * @param {EvmAddress} address
     */
    constructor(address) {
        _assertClass(address, EvmAddress);
        const ret = wasm.evmwalletconfig_new(address.__wbg_ptr);
        this.__wbg_ptr = ret >>> 0;
        EvmWalletConfigFinalization.register(this, this.__wbg_ptr, this);
        return this;
    }
}

const ExtendedPubKeyFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_extendedpubkey_free(ptr >>> 0, 1));

export class ExtendedPubKey {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ExtendedPubKey.prototype);
        obj.__wbg_ptr = ptr;
        ExtendedPubKeyFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ExtendedPubKeyFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_extendedpubkey_free(ptr, 0);
    }
    /**
     * @param {string} value
     * @returns {ExtendedPubKey}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.extendedpubkey_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return ExtendedPubKey.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toMainnetString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.extendedpubkey_toMainnetString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    toTestnetString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.extendedpubkey_toTestnetString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const FiatBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_fiatbalance_free(ptr >>> 0, 1));

export class FiatBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(FiatBalance.prototype);
        obj.__wbg_ptr = ptr;
        FiatBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FiatBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_fiatbalance_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get onchain() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set onchain(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get offchain() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set offchain(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
}

const FilledFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_filled_free(ptr >>> 0, 1));

export class Filled {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Filled.prototype);
        obj.__wbg_ptr = ptr;
        FilledFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FilledFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_filled_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get soldBaseAmount() {
        const ret = wasm.currencybalance_sending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get boughtQuoteAmount() {
        const ret = wasm.currencybalance_receiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get soldQuoteAmount() {
        const ret = wasm.currencybalance_lockedSending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get boughtBaseAmount() {
        const ret = wasm.currencybalance_lockedReceiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const FingerprintFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_fingerprint_free(ptr >>> 0, 1));

export class Fingerprint {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Fingerprint.prototype);
        obj.__wbg_ptr = ptr;
        FingerprintFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        FingerprintFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_fingerprint_free(ptr, 0);
    }
    /**
     * @param {string} value
     * @returns {Fingerprint}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.fingerprint_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Fingerprint.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.fingerprint_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const HashlockFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_hashlock_free(ptr >>> 0, 1));

export class Hashlock {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Hashlock.prototype);
        obj.__wbg_ptr = ptr;
        HashlockFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        HashlockFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_hashlock_free(ptr, 0);
    }
    /**
     * @param {PaymentPreimage} payment_preimage
     * @returns {Hashlock}
     */
    static newKnown(payment_preimage) {
        _assertClass(payment_preimage, PaymentPreimage);
        const ret = wasm.hashlock_newKnown(payment_preimage.__wbg_ptr);
        return Hashlock.__wrap(ret);
    }
    /**
     * @param {PaymentHash} payment_hash
     * @returns {Hashlock}
     */
    static newUnknown(payment_hash) {
        _assertClass(payment_hash, PaymentHash);
        const ret = wasm.hashlock_newUnknown(payment_hash.__wbg_ptr);
        return Hashlock.__wrap(ret);
    }
    /**
     * @returns {PaymentHash}
     */
    get hash() {
        const ret = wasm.hashlock_hash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
}

const IntoUnderlyingByteSourceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingbytesource_free(ptr >>> 0, 1));

export class IntoUnderlyingByteSource {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingByteSourceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingbytesource_free(ptr, 0);
    }
    /**
     * @returns {ReadableStreamType}
     */
    get type() {
        const ret = wasm.intounderlyingbytesource_type(this.__wbg_ptr);
        return __wbindgen_enum_ReadableStreamType[ret];
    }
    /**
     * @returns {number}
     */
    get autoAllocateChunkSize() {
        const ret = wasm.intounderlyingbytesource_autoAllocateChunkSize(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @param {ReadableByteStreamController} controller
     */
    start(controller) {
        wasm.intounderlyingbytesource_start(this.__wbg_ptr, controller);
    }
    /**
     * @param {ReadableByteStreamController} controller
     * @returns {Promise<any>}
     */
    pull(controller) {
        const ret = wasm.intounderlyingbytesource_pull(this.__wbg_ptr, controller);
        return ret;
    }
    cancel() {
        const ptr = this.__destroy_into_raw();
        wasm.intounderlyingbytesource_cancel(ptr);
    }
}

const IntoUnderlyingSinkFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingsink_free(ptr >>> 0, 1));

export class IntoUnderlyingSink {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingSinkFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingsink_free(ptr, 0);
    }
    /**
     * @param {any} chunk
     * @returns {Promise<any>}
     */
    write(chunk) {
        const ret = wasm.intounderlyingsink_write(this.__wbg_ptr, chunk);
        return ret;
    }
    /**
     * @returns {Promise<any>}
     */
    close() {
        const ptr = this.__destroy_into_raw();
        const ret = wasm.intounderlyingsink_close(ptr);
        return ret;
    }
    /**
     * @param {any} reason
     * @returns {Promise<any>}
     */
    abort(reason) {
        const ptr = this.__destroy_into_raw();
        const ret = wasm.intounderlyingsink_abort(ptr, reason);
        return ret;
    }
}

const IntoUnderlyingSourceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_intounderlyingsource_free(ptr >>> 0, 1));

export class IntoUnderlyingSource {

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        IntoUnderlyingSourceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_intounderlyingsource_free(ptr, 0);
    }
    /**
     * @param {ReadableStreamDefaultController} controller
     * @returns {Promise<any>}
     */
    pull(controller) {
        const ret = wasm.intounderlyingsource_pull(this.__wbg_ptr, controller);
        return ret;
    }
    cancel() {
        const ptr = this.__destroy_into_raw();
        wasm.intounderlyingsource_cancel(ptr);
    }
}

const LightningChannelIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lightningchannelid_free(ptr >>> 0, 1));

export class LightningChannelId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LightningChannelId.prototype);
        obj.__wbg_ptr = ptr;
        LightningChannelIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LightningChannelIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lightningchannelid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.lightningchannelid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {LightningChannelId}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.lightningchannelid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return LightningChannelId.__wrap(ret[0]);
    }
}

const LightningPaymentIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lightningpaymentid_free(ptr >>> 0, 1));

export class LightningPaymentId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LightningPaymentId.prototype);
        obj.__wbg_ptr = ptr;
        LightningPaymentIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LightningPaymentIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lightningpaymentid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.lightningpaymentid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {LightningPaymentId}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.lightningpaymentid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return LightningPaymentId.__wrap(ret[0]);
    }
}

const LiquidityFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_liquidity_free(ptr >>> 0, 1));

export class Liquidity {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Liquidity.prototype);
        obj.__wbg_ptr = ptr;
        LiquidityFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LiquidityFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_liquidity_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.liquidity_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get minPrice() {
        const ret = wasm.liquidity_minPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get maxPrice() {
        const ret = wasm.liquidity_maxPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const LiquidityOrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_liquidityorder_free(ptr >>> 0, 1));

export class LiquidityOrder {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LiquidityOrder.prototype);
        obj.__wbg_ptr = ptr;
        LiquidityOrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LiquidityOrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_liquidityorder_free(ptr, 0);
    }
    /**
     * @returns {bigint}
     */
    get createdAtMillis() {
        const ret = wasm.liquidityorder_createdAtMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {Amount}
     */
    get minBuyPrice() {
        const ret = wasm.liquidityorder_minBuyPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get maxBuyPrice() {
        const ret = wasm.liquidityorder_maxBuyPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get minSellPrice() {
        const ret = wasm.liquidityorder_minSellPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get maxSellPrice() {
        const ret = wasm.liquidityorder_maxSellPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get remainingBaseAmount() {
        const ret = wasm.liquidityorder_remainingBaseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get remainingQuoteAmount() {
        const ret = wasm.liquidityorder_remainingQuoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get paidBaseFee() {
        const ret = wasm.liquidityorder_paidBaseFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get paidQuoteFee() {
        const ret = wasm.liquidityorder_paidQuoteFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Filled | undefined}
     */
    get filled() {
        const ret = wasm.liquidityorder_filled(this.__wbg_ptr);
        return ret === 0 ? undefined : Filled.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    isLimit() {
        const ret = wasm.liquidityorder_isLimit(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
     * @returns {boolean}
     */
    isCompleted() {
        const ret = wasm.liquidityorder_isCompleted(this.__wbg_ptr);
        return ret !== 0;
    }
}

const LithiumChannelIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lithiumchannelid_free(ptr >>> 0, 1));

export class LithiumChannelId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LithiumChannelId.prototype);
        obj.__wbg_ptr = ptr;
        LithiumChannelIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LithiumChannelIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lithiumchannelid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.lithiumchannelid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {LithiumChannelId}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.lithiumchannelid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return LithiumChannelId.__wrap(ret[0]);
    }
}

const LithiumPaymentIdFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_lithiumpaymentid_free(ptr >>> 0, 1));

export class LithiumPaymentId {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(LithiumPaymentId.prototype);
        obj.__wbg_ptr = ptr;
        LithiumPaymentIdFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        LithiumPaymentIdFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_lithiumpaymentid_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.lithiumpaymentid_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @param {string} value
     * @returns {LithiumPaymentId}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.lithiumpaymentid_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return LithiumPaymentId.__wrap(ret[0]);
    }
}

const MarketDailyStatsFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketdailystats_free(ptr >>> 0, 1));

export class MarketDailyStats {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketDailyStats.prototype);
        obj.__wbg_ptr = ptr;
        MarketDailyStatsFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketDailyStatsFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketdailystats_free(ptr, 0);
    }
    /**
     * @returns {MarketVolatility}
     */
    get volatility() {
        const ret = wasm.marketdailystats_volatility(this.__wbg_ptr);
        return MarketVolatility.__wrap(ret);
    }
}

const MarketEventFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketevent_free(ptr >>> 0, 1));

export class MarketEvent {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketEvent.prototype);
        obj.__wbg_ptr = ptr;
        MarketEventFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketEventFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketevent_free(ptr, 0);
    }
    /**
     * @returns {Asset}
     */
    get base() {
        const ret = wasm.marketevent_base(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @returns {Asset}
     */
    get quote() {
        const ret = wasm.marketevent_quote(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @returns {MarketEventType}
     */
    get eventType() {
        const ret = wasm.marketevent_eventType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Trade | undefined}
     */
    get trade() {
        const ret = wasm.marketevent_trade(this.__wbg_ptr);
        return ret === 0 ? undefined : Trade.__wrap(ret);
    }
    /**
     * @returns {MarketDailyStats | undefined}
     */
    get dailyStats() {
        const ret = wasm.marketevent_dailyStats(this.__wbg_ptr);
        return ret === 0 ? undefined : MarketDailyStats.__wrap(ret);
    }
    /**
     * @returns {CandlestickUpdate | undefined}
     */
    get candlestickUpdate() {
        const ret = wasm.marketevent_candlestickUpdate(this.__wbg_ptr);
        return ret === 0 ? undefined : CandlestickUpdate.__wrap(ret);
    }
}

const MarketInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketinfo_free(ptr >>> 0, 1));

export class MarketInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketInfo.prototype);
        obj.__wbg_ptr = ptr;
        MarketInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketinfo_free(ptr, 0);
    }
    /**
     * @returns {Asset}
     */
    get base() {
        const ret = wasm.__wbg_get_assetbalance_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set base(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_assetbalance_asset(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Asset}
     */
    get quote() {
        const ret = wasm.__wbg_get_marketinfo_quote(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * @param {Asset} arg0
     */
    set quote(arg0) {
        _assertClass(arg0, Asset);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_marketinfo_quote(this.__wbg_ptr, ptr0);
    }
}

const MarketOrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketorder_free(ptr >>> 0, 1));

export class MarketOrder {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketOrder.prototype);
        obj.__wbg_ptr = ptr;
        MarketOrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketOrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketorder_free(ptr, 0);
    }
    /**
     * @returns {OrderSide}
     */
    get side() {
        const ret = wasm.marketorder_side(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint}
     */
    get createdAtMillis() {
        const ret = wasm.marketorder_createdAtMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {OrderAmount}
     */
    get remainingAmount() {
        const ret = wasm.marketorder_remainingAmount(this.__wbg_ptr);
        return OrderAmount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get boughtBaseAmount() {
        const ret = wasm.marketorder_boughtBaseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get soldQuoteAmount() {
        const ret = wasm.marketorder_soldQuoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get paidBaseFee() {
        const ret = wasm.marketorder_paidBaseFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get soldBaseAmount() {
        const ret = wasm.marketorder_soldBaseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get boughtQuoteAmount() {
        const ret = wasm.marketorder_boughtQuoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get paidQuoteFee() {
        const ret = wasm.marketorder_paidQuoteFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    isCompleted() {
        const ret = wasm.marketorder_isCompleted(this.__wbg_ptr);
        return ret !== 0;
    }
}

const MarketVolatilityFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_marketvolatility_free(ptr >>> 0, 1));

export class MarketVolatility {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MarketVolatility.prototype);
        obj.__wbg_ptr = ptr;
        MarketVolatilityFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MarketVolatilityFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_marketvolatility_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get firstPrice() {
        const ret = wasm.marketvolatility_firstPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get lastPrice() {
        const ret = wasm.marketvolatility_lastPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get highPrice() {
        const ret = wasm.marketvolatility_highPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get lowPrice() {
        const ret = wasm.marketvolatility_lowPrice(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get baseVolume() {
        const ret = wasm.marketvolatility_baseVolume(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get quoteVolume() {
        const ret = wasm.marketvolatility_quoteVolume(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    priceChange() {
        const ret = wasm.marketvolatility_priceChange(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const MatchedOrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_matchedorder_free(ptr >>> 0, 1));

export class MatchedOrder {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(MatchedOrder.prototype);
        obj.__wbg_ptr = ptr;
        MatchedOrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MatchedOrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_matchedorder_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get ownOrderId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.matchedorder_ownOrderId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {OrderSide}
     */
    get ownOrderSide() {
        const ret = wasm.matchedorder_ownOrderSide(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {OrderType}
     */
    get ownOrderType() {
        const ret = wasm.matchedorder_ownOrderType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {PartiallyMatchedOrder[]}
     */
    get partiallyMatchedOrders() {
        const ret = wasm.matchedorder_partiallyMatchedOrders(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {boolean}
     */
    get isTaker() {
        const ret = wasm.matchedorder_isTaker(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
     * @returns {Amount}
     */
    sendingAmount() {
        const ret = wasm.matchedorder_sendingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    receivingAmount() {
        const ret = wasm.matchedorder_receivingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    receivingFee() {
        const ret = wasm.matchedorder_receivingFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const MnemonicFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_mnemonic_free(ptr >>> 0, 1));

export class Mnemonic {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Mnemonic.prototype);
        obj.__wbg_ptr = ptr;
        MnemonicFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        MnemonicFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_mnemonic_free(ptr, 0);
    }
    /**
     * @param {number} num_words
     * @returns {Mnemonic}
     */
    static generateNew(num_words) {
        const ret = wasm.mnemonic_generateNew(num_words);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Mnemonic.__wrap(ret[0]);
    }
    /**
     * @param {string} value
     * @returns {Mnemonic}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.mnemonic_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return Mnemonic.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.mnemonic_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const NetworkFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_network_free(ptr >>> 0, 1));

export class Network {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Network.prototype);
        obj.__wbg_ptr = ptr;
        NetworkFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        NetworkFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_network_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_id(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get name() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_name(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get symbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_symbol(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get logoUrl() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_logoUrl(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get coinSymbol() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_coinSymbol(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get coinName() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_coinName(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get coinId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_coinId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get explorerUrl() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.network_explorerUrl(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {number}
     */
    get requiredConfirmations() {
        const ret = wasm.network_requiredConfirmations(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @returns {Protocol}
     */
    get type() {
        const ret = wasm.network_type(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {BitcoinNetwork | undefined}
     */
    asBitcoin() {
        const ret = wasm.network_asBitcoin(this.__wbg_ptr);
        return ret === 4 ? undefined : ret;
    }
    /**
     * @returns {EvmNetwork | undefined}
     */
    asEvm() {
        const ret = wasm.network_asEvm(this.__wbg_ptr);
        return ret === 0 ? undefined : ret;
    }
}

const OffchainBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_offchainbalance_free(ptr >>> 0, 1));

export class OffchainBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OffchainBalance.prototype);
        obj.__wbg_ptr = ptr;
        OffchainBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OffchainBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_offchainbalance_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get free_local() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set free_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get free_remote() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set free_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get unavailable_local() {
        const ret = wasm.__wbg_get_offchainbalance_unavailable_local(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set unavailable_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unavailable_local(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get unavailable_remote() {
        const ret = wasm.__wbg_get_offchainbalance_unavailable_remote(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set unavailable_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unavailable_remote(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get paying_local() {
        const ret = wasm.__wbg_get_offchainbalance_paying_local(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set paying_local(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_paying_local(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get paying_remote() {
        const ret = wasm.__wbg_get_offchainbalance_paying_remote(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set paying_remote(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_paying_remote(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get unspendable_local_reserve() {
        const ret = wasm.__wbg_get_offchainbalance_unspendable_local_reserve(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set unspendable_local_reserve(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unspendable_local_reserve(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get unspendable_remote_reserve() {
        const ret = wasm.__wbg_get_offchainbalance_unspendable_remote_reserve(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set unspendable_remote_reserve(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_offchainbalance_unspendable_remote_reserve(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    localBalance() {
        const ret = wasm.offchainbalance_localBalance(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    remoteBalance() {
        const ret = wasm.offchainbalance_remoteBalance(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const OnchainBalanceFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_onchainbalance_free(ptr >>> 0, 1));

export class OnchainBalance {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OnchainBalance.prototype);
        obj.__wbg_ptr = ptr;
        OnchainBalanceFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OnchainBalanceFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_onchainbalance_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get usable() {
        const ret = wasm.__wbg_get_fiatbalance_onchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set usable(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_onchain(this.__wbg_ptr, ptr0);
    }
    /**
     * @returns {Amount}
     */
    get pending() {
        const ret = wasm.__wbg_get_fiatbalance_offchain(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @param {Amount} arg0
     */
    set pending(arg0) {
        _assertClass(arg0, Amount);
        var ptr0 = arg0.__destroy_into_raw();
        wasm.__wbg_set_fiatbalance_offchain(this.__wbg_ptr, ptr0);
    }
}

const OperationFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_operation_free(ptr >>> 0, 1));

export class Operation {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Operation.prototype);
        obj.__wbg_ptr = ptr;
        OperationFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OperationFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_operation_free(ptr, 0);
    }
    /**
     * @returns {OperationType}
     */
    get operationType() {
        const ret = wasm.operation_operationType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Asset | undefined}
     */
    get asset() {
        const ret = wasm.operation_asset(this.__wbg_ptr);
        return ret === 0 ? undefined : Asset.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get amount() {
        const ret = wasm.operation_amount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get to() {
        const ret = wasm.operation_to(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get from() {
        const ret = wasm.operation_from(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get spender() {
        const ret = wasm.operation_spender(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get address() {
        const ret = wasm.operation_address(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get channel_id() {
        const ret = wasm.operation_channel_id(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get counterparty() {
        const ret = wasm.operation_counterparty(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Map<Asset, AssetChannelAmounts> | undefined}
     */
    get assetChannelAmounts() {
        const ret = wasm.operation_assetChannelAmounts(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Deadline | undefined}
     */
    get disputeDeadline() {
        const ret = wasm.operation_disputeDeadline(this.__wbg_ptr);
        return ret === 0 ? undefined : Deadline.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get routed_from() {
        const ret = wasm.operation_routed_from(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get routed_to() {
        const ret = wasm.operation_routed_to(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Amount | undefined}
     */
    get earned_fee() {
        const ret = wasm.operation_earned_fee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
}

const OrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_order_free(ptr >>> 0, 1));

export class Order {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Order.prototype);
        obj.__wbg_ptr = ptr;
        OrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_order_free(ptr, 0);
    }
    /**
     * @returns {OrderType}
     */
    get orderType() {
        const ret = wasm.order_orderType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint}
     */
    get createdAtMillis() {
        const ret = wasm.order_createdAtMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {LiquidityOrder | undefined}
     */
    get liquidityOrder() {
        const ret = wasm.order_liquidityOrder(this.__wbg_ptr);
        return ret === 0 ? undefined : LiquidityOrder.__wrap(ret);
    }
    /**
     * @returns {MarketOrder | undefined}
     */
    get marketOrder() {
        const ret = wasm.order_marketOrder(this.__wbg_ptr);
        return ret === 0 ? undefined : MarketOrder.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    isCompleted() {
        const ret = wasm.order_isCompleted(this.__wbg_ptr);
        return ret !== 0;
    }
}

const OrderAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_orderamount_free(ptr >>> 0, 1));

export class OrderAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OrderAmount.prototype);
        obj.__wbg_ptr = ptr;
        OrderAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_orderamount_free(ptr, 0);
    }
    /**
     * @param {Amount} amount
     * @returns {OrderAmount}
     */
    static newBase(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.orderamount_newBase(amount.__wbg_ptr);
        return OrderAmount.__wrap(ret);
    }
    /**
     * @param {Amount} amount
     * @returns {OrderAmount}
     */
    static newQuote(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return OrderAmount.__wrap(ret);
    }
}

const OrderMatchFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_ordermatch_free(ptr >>> 0, 1));

export class OrderMatch {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OrderMatch.prototype);
        obj.__wbg_ptr = ptr;
        OrderMatchFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderMatchFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_ordermatch_free(ptr, 0);
    }
    /**
     * The matched base amount (paid or received by the taker)
     * @returns {Amount}
     */
    get baseAmount() {
        const ret = wasm.ordermatch_baseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The matched quote amount (paid or received by the taker)
     * @returns {Amount}
     */
    get quoteAmount() {
        const ret = wasm.ordermatch_quoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The fee paid by the taker in the base currency (if negative, the taker earns the fee)
     * @returns {Amount}
     */
    get baseFee() {
        const ret = wasm.ordermatch_baseFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The fee paid by the taker in the quote currency (if negative, the taker earns the fee)
     * @returns {Amount}
     */
    get quoteFee() {
        const ret = wasm.ordermatch_quoteFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The slippage of the order ((average price of order - best price of order) / best price of order)
     * @returns {Amount}
     */
    get slippage() {
        const ret = wasm.ordermatch_slippage(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const OrderVariantFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_ordervariant_free(ptr >>> 0, 1));

export class OrderVariant {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(OrderVariant.prototype);
        obj.__wbg_ptr = ptr;
        OrderVariantFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderVariantFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_ordervariant_free(ptr, 0);
    }
    /**
     * Create a new order to add liquidity to the orderbook
     *
     * # Arguments
     *
     * * `amount` - The amount of either the base or quote currency to add to the orderbook.
     *              The other currency will be calculated based on the price range
     * * `min_buy_price` - The minimum buy price of the liquidity range
     * * `mid_price` - The maximum buy price and minimum sell price of the liquidity range
     * * `max_sell_price` - The maximum sell price of the liquidity range
     * * `remove_on_fill` - Whether to remove the liquidity when it gets partially filled
     * @param {OrderAmount} amount
     * @param {Amount} min_buy_price
     * @param {Amount} mid_price
     * @param {Amount} max_sell_price
     * @param {boolean} remove_on_fill
     * @returns {OrderVariant}
     */
    static newAddLiquidity(amount, min_buy_price, mid_price, max_sell_price, remove_on_fill) {
        _assertClass(amount, OrderAmount);
        var ptr0 = amount.__destroy_into_raw();
        _assertClass(min_buy_price, Amount);
        var ptr1 = min_buy_price.__destroy_into_raw();
        _assertClass(mid_price, Amount);
        var ptr2 = mid_price.__destroy_into_raw();
        _assertClass(max_sell_price, Amount);
        var ptr3 = max_sell_price.__destroy_into_raw();
        const ret = wasm.ordervariant_newAddLiquidity(ptr0, ptr1, ptr2, ptr3, remove_on_fill);
        return OrderVariant.__wrap(ret);
    }
    /**
     * Create a new order to add liquidity to the buy side of the orderbook
     *
     * # Arguments
     *
     * * `quote_amount` - The amount of the quote currency to add to the buy side
     * * `min_price` - The minimum price of the liquidity range
     * * `max_price` - The maximum price of the liquidity range
     * * `remove_on_fill` - Whether to remove the liquidity when it gets partially filled
     * @param {Amount} quote_amount
     * @param {Amount} min_price
     * @param {Amount} max_price
     * @param {boolean} remove_on_fill
     * @returns {OrderVariant}
     */
    static newAddBuyLiquidity(quote_amount, min_price, max_price, remove_on_fill) {
        _assertClass(quote_amount, Amount);
        var ptr0 = quote_amount.__destroy_into_raw();
        _assertClass(min_price, Amount);
        var ptr1 = min_price.__destroy_into_raw();
        _assertClass(max_price, Amount);
        var ptr2 = max_price.__destroy_into_raw();
        const ret = wasm.ordervariant_newAddBuyLiquidity(ptr0, ptr1, ptr2, remove_on_fill);
        return OrderVariant.__wrap(ret);
    }
    /**
     * Create a new order to add liquidity to the sell side of the orderbook
     *
     * # Arguments
     *
     * * `base_amount` - The amount of the base currency to add to the sell side
     * * `min_price` - The minimum price of the liquidity range
     * * `max_price` - The maximum price of the liquidity range
     * * `remove_on_fill` - Whether to remove the liquidity when it gets partially filled
     * @param {Amount} base_amount
     * @param {Amount} min_price
     * @param {Amount} max_price
     * @param {boolean} remove_on_fill
     * @returns {OrderVariant}
     */
    static newAddSellLiquidity(base_amount, min_price, max_price, remove_on_fill) {
        _assertClass(base_amount, Amount);
        var ptr0 = base_amount.__destroy_into_raw();
        _assertClass(min_price, Amount);
        var ptr1 = min_price.__destroy_into_raw();
        _assertClass(max_price, Amount);
        var ptr2 = max_price.__destroy_into_raw();
        const ret = wasm.ordervariant_newAddSellLiquidity(ptr0, ptr1, ptr2, remove_on_fill);
        return OrderVariant.__wrap(ret);
    }
    /**
     * Create a new market buy order
     *
     * # Arguments
     *
     * * `amount` - The amount of either the base currency to buy or the quote currency to spend
     * @param {OrderAmount} amount
     * @returns {OrderVariant}
     */
    static newMarketBuy(amount) {
        _assertClass(amount, OrderAmount);
        var ptr0 = amount.__destroy_into_raw();
        const ret = wasm.ordervariant_newMarketBuy(ptr0);
        return OrderVariant.__wrap(ret);
    }
    /**
     * Create a new market sell order
     *
     * # Arguments
     *
     * * `amount` - The amount of either the base currency to sell or the quote currency to receive
     * @param {OrderAmount} amount
     * @returns {OrderVariant}
     */
    static newMarketSell(amount) {
        _assertClass(amount, OrderAmount);
        var ptr0 = amount.__destroy_into_raw();
        const ret = wasm.ordervariant_newMarketSell(ptr0);
        return OrderVariant.__wrap(ret);
    }
    /**
     * @param {number} base_precision
     * @param {number} quote_precision
     * @returns {Order}
     */
    toOrder(base_precision, quote_precision) {
        const ret = wasm.ordervariant_toOrder(this.__wbg_ptr, base_precision, quote_precision);
        return Order.__wrap(ret);
    }
}

const OrderbookFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_orderbook_free(ptr >>> 0, 1));

export class Orderbook {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Orderbook.prototype);
        obj.__wbg_ptr = ptr;
        OrderbookFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        OrderbookFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_orderbook_free(ptr, 0);
    }
    /**
     * @returns {number}
     */
    get basePrecision() {
        const ret = wasm.orderbook_basePrecision(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @returns {number}
     */
    get quotePrecision() {
        const ret = wasm.orderbook_quotePrecision(this.__wbg_ptr);
        return ret >>> 0;
    }
    /**
     * @returns {Liquidity[]}
     */
    get asks() {
        const ret = wasm.orderbook_asks(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Liquidity[]}
     */
    get bids() {
        const ret = wasm.orderbook_bids(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Int32Array}
     */
    getPrecisions() {
        const ret = wasm.orderbook_getPrecisions(this.__wbg_ptr);
        var v1 = getArrayI32FromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @param {number} precision
     * @returns {Liquidity[]}
     */
    getAsksByPrecision(precision) {
        const ret = wasm.orderbook_getAsksByPrecision(this.__wbg_ptr, precision);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @param {number} precision
     * @returns {Liquidity[]}
     */
    getBidsByPrecision(precision) {
        const ret = wasm.orderbook_getBidsByPrecision(this.__wbg_ptr, precision);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
}

const PartiallyMatchedOrderFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_partiallymatchedorder_free(ptr >>> 0, 1));

export class PartiallyMatchedOrder {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PartiallyMatchedOrder.prototype);
        obj.__wbg_ptr = ptr;
        PartiallyMatchedOrderFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PartiallyMatchedOrderFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_partiallymatchedorder_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get swapId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.partiallymatchedorder_swapId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {Amount}
     */
    get sendingAmount() {
        const ret = wasm.partiallymatchedorder_sendingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get receivingAmount() {
        const ret = wasm.partiallymatchedorder_receivingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get receivingFee() {
        const ret = wasm.partiallymatchedorder_receivingFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const PaymentHashFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_paymenthash_free(ptr >>> 0, 1));

export class PaymentHash {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PaymentHash.prototype);
        obj.__wbg_ptr = ptr;
        PaymentHashFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PaymentHashFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_paymenthash_free(ptr, 0);
    }
    /**
     * @param {string} value
     * @returns {PaymentHash}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.paymenthash_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return PaymentHash.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.paymenthash_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const PaymentPreimageFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_paymentpreimage_free(ptr >>> 0, 1));

export class PaymentPreimage {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(PaymentPreimage.prototype);
        obj.__wbg_ptr = ptr;
        PaymentPreimageFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        PaymentPreimageFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_paymentpreimage_free(ptr, 0);
    }
    /**
     * @returns {PaymentPreimage}
     */
    static generateNew() {
        const ret = wasm.paymentpreimage_generateNew();
        return PaymentPreimage.__wrap(ret);
    }
    /**
     * @returns {PaymentHash}
     */
    hash() {
        const ret = wasm.paymentpreimage_hash(this.__wbg_ptr);
        return PaymentHash.__wrap(ret);
    }
    /**
     * @param {string} value
     * @returns {PaymentPreimage}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.paymentpreimage_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return PaymentPreimage.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.paymentpreimage_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

const ReceivingChannelRentalFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_receivingchannelrental_free(ptr >>> 0, 1));

export class ReceivingChannelRental {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ReceivingChannelRental.prototype);
        obj.__wbg_ptr = ptr;
        ReceivingChannelRentalFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ReceivingChannelRentalFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_receivingchannelrental_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get txid() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.receivingchannelrental_txid(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get channelId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.receivingchannelrental_channelId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.receivingchannelrental_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const ReceivingChannelRentalEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_receivingchannelrentalestimate_free(ptr >>> 0, 1));

export class ReceivingChannelRentalEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(ReceivingChannelRentalEstimate.prototype);
        obj.__wbg_ptr = ptr;
        ReceivingChannelRentalEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        ReceivingChannelRentalEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_receivingchannelrentalestimate_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.receivingchannelrentalestimate_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get rentalFee() {
        const ret = wasm.receivingchannelrentalestimate_rentalFee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    get payWithSending() {
        const ret = wasm.receivingchannelrentalestimate_payWithSending(this.__wbg_ptr);
        return ret !== 0;
    }
}

const RentableAssetInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentableassetinfo_free(ptr >>> 0, 1));

export class RentableAssetInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentableAssetInfo.prototype);
        obj.__wbg_ptr = ptr;
        RentableAssetInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentableAssetInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentableassetinfo_free(ptr, 0);
    }
    /**
     * Available liquidity in the channel
     * @returns {Amount}
     */
    get availableLiquidity() {
        const ret = wasm.rentableassetinfo_availableLiquidity(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * Minimum channel capacity
     * @returns {Amount}
     */
    get minCapacity() {
        const ret = wasm.rentableassetinfo_minCapacity(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * Maximum channel capacity
     * @returns {Amount}
     */
    get maxCapacity() {
        const ret = wasm.rentableassetinfo_maxCapacity(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * Minimum rental duration in seconds
     * @returns {bigint}
     */
    get minDurationSecs() {
        const ret = wasm.rentableassetinfo_minDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * Maximum rental duration in seconds
     * @returns {bigint}
     */
    get maxDurationSecs() {
        const ret = wasm.rentableassetinfo_maxDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * Rental fee ratio
     * @returns {Amount}
     */
    get rentalFeeRatio() {
        const ret = wasm.rentableassetinfo_rentalFeeRatio(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const RentalAssetConfigFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentalassetconfig_free(ptr >>> 0, 1));
/**
 * Supported rental asset and its rental configuration
 */
export class RentalAssetConfig {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentalAssetConfig.prototype);
        obj.__wbg_ptr = ptr;
        RentalAssetConfigFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentalAssetConfigFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentalassetconfig_free(ptr, 0);
    }
    /**
     * The rental asset
     * @returns {Asset}
     */
    get asset() {
        const ret = wasm.rentalassetconfig_asset(this.__wbg_ptr);
        return Asset.__wrap(ret);
    }
    /**
     * The rental fee percentage
     * @returns {number}
     */
    get feePercentage() {
        const ret = wasm.rentalassetconfig_feePercentage(this.__wbg_ptr);
        return ret;
    }
}

const RentalNodeInfoFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_rentalnodeinfo_free(ptr >>> 0, 1));
/**
 * Information about the channel rental duration and capacity limits of a rental node
 */
export class RentalNodeInfo {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(RentalNodeInfo.prototype);
        obj.__wbg_ptr = ptr;
        RentalNodeInfoFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        RentalNodeInfoFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rentalnodeinfo_free(ptr, 0);
    }
    /**
     * Minimum rental duration in seconds
     * @returns {bigint}
     */
    get minDurationSecs() {
        const ret = wasm.rentalnodeinfo_minDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * Maximum rental duration in seconds
     * @returns {bigint}
     */
    get maxDurationSecs() {
        const ret = wasm.rentalnodeinfo_maxDurationSecs(this.__wbg_ptr);
        return BigInt.asUintN(64, ret);
    }
    /**
     * Minimum channel capacity in USD
     * @returns {number}
     */
    get minCapacityUsd() {
        const ret = wasm.rentalnodeinfo_minCapacityUsd(this.__wbg_ptr);
        return ret;
    }
    /**
     * Maximum channel capacity in USD
     * @returns {number}
     */
    get maxCapacityUsd() {
        const ret = wasm.rentalnodeinfo_maxCapacityUsd(this.__wbg_ptr);
        return ret;
    }
    /**
     * Supported rental assets and their rental configurations
     * @returns {Array<RentalAssetConfig>}
     */
    get rentalAssets() {
        const ret = wasm.rentalnodeinfo_rentalAssets(this.__wbg_ptr);
        return ret;
    }
}

const SendAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_sendamount_free(ptr >>> 0, 1));

export class SendAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SendAmount.prototype);
        obj.__wbg_ptr = ptr;
        SendAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    static __unwrap(jsValue) {
        if (!(jsValue instanceof SendAmount)) {
            return 0;
        }
        return jsValue.__destroy_into_raw();
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SendAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_sendamount_free(ptr, 0);
    }
    /**
     * @returns {SendAmount}
     */
    static all() {
        const ret = wasm.allowanceamount_unlimited();
        return SendAmount.__wrap(ret);
    }
    /**
     * @param {Amount} amount
     * @returns {SendAmount}
     */
    static exact(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return SendAmount.__wrap(ret);
    }
    /**
     * @returns {SendAmount}
     */
    static zero() {
        const ret = wasm.allowanceamount_zero();
        return SendAmount.__wrap(ret);
    }
    /**
     * @param {number} value
     * @returns {SendAmount}
     */
    static fromFloat(value) {
        const ret = wasm.allowanceamount_fromFloat(value);
        return SendAmount.__wrap(ret);
    }
    /**
     * @param {U256} value
     * @param {number} decimals
     * @returns {SendAmount}
     */
    static fromCommonDenomination(value, decimals) {
        _assertClass(value, U256);
        const ret = wasm.allowanceamount_fromCommonDenomination(value.__wbg_ptr, decimals);
        return SendAmount.__wrap(ret);
    }
}

const SendingChannelDepositFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_sendingchanneldeposit_free(ptr >>> 0, 1));

export class SendingChannelDeposit {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SendingChannelDeposit.prototype);
        obj.__wbg_ptr = ptr;
        SendingChannelDepositFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SendingChannelDepositFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_sendingchanneldeposit_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get txid() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.sendingchanneldeposit_txid(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string}
     */
    get channelId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.sendingchanneldeposit_channelId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.sendingchanneldeposit_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    get isOpening() {
        const ret = wasm.sendingchanneldeposit_isOpening(this.__wbg_ptr);
        return ret !== 0;
    }
}

const SendingChannelDepositEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_sendingchanneldepositestimate_free(ptr >>> 0, 1));

export class SendingChannelDepositEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SendingChannelDepositEstimate.prototype);
        obj.__wbg_ptr = ptr;
        SendingChannelDepositEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SendingChannelDepositEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_sendingchanneldepositestimate_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get amount() {
        const ret = wasm.sendingchanneldepositestimate_amount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get fee() {
        const ret = wasm.sendingchanneldepositestimate_fee(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {string}
     */
    get counterparty() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.sendingchanneldepositestimate_counterparty(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {string | undefined}
     */
    get depositChannelId() {
        const ret = wasm.sendingchanneldepositestimate_depositChannelId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
}

const SimpleSwapEstimateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_simpleswapestimate_free(ptr >>> 0, 1));

export class SimpleSwapEstimate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SimpleSwapEstimate.prototype);
        obj.__wbg_ptr = ptr;
        SimpleSwapEstimateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SimpleSwapEstimateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simpleswapestimate_free(ptr, 0);
    }
    /**
     * @returns {SimpleSwapOutputType}
     */
    get outputType() {
        const ret = wasm.simpleswapestimate_outputType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {SwapMatch}
     */
    get swapMatch() {
        const ret = wasm.simpleswapestimate_swapMatch(this.__wbg_ptr);
        return SwapMatch.__wrap(ret);
    }
    /**
     * @returns {SendingChannelDepositEstimate | undefined}
     */
    get sendingChannelDepositEstimate() {
        const ret = wasm.simpleswapestimate_sendingChannelDepositEstimate(this.__wbg_ptr);
        return ret === 0 ? undefined : SendingChannelDepositEstimate.__wrap(ret);
    }
    /**
     * @returns {ReceivingChannelRentalEstimate | undefined}
     */
    get receivingChannelRentalEstimate() {
        const ret = wasm.simpleswapestimate_receivingChannelRentalEstimate(this.__wbg_ptr);
        return ret === 0 ? undefined : ReceivingChannelRentalEstimate.__wrap(ret);
    }
    /**
     * @returns {DualFundDepositEstimate | undefined}
     */
    get dualFundDepositEstimate() {
        const ret = wasm.simpleswapestimate_dualFundDepositEstimate(this.__wbg_ptr);
        return ret === 0 ? undefined : DualFundDepositEstimate.__wrap(ret);
    }
}

const SimpleSwapOutputFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_simpleswapoutput_free(ptr >>> 0, 1));

export class SimpleSwapOutput {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SimpleSwapOutput.prototype);
        obj.__wbg_ptr = ptr;
        SimpleSwapOutputFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SimpleSwapOutputFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simpleswapoutput_free(ptr, 0);
    }
    /**
     * @returns {string}
     */
    get simpleSwapId() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.simpleswapoutput_simpleSwapId(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {SimpleSwapOutputType}
     */
    get outputType() {
        const ret = wasm.simpleswapoutput_outputType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get orderId() {
        const ret = wasm.simpleswapoutput_orderId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {SendingChannelDeposit | undefined}
     */
    get sendingChannelDeposit() {
        const ret = wasm.simpleswapoutput_sendingChannelDeposit(this.__wbg_ptr);
        return ret === 0 ? undefined : SendingChannelDeposit.__wrap(ret);
    }
    /**
     * @returns {ReceivingChannelRental | undefined}
     */
    get receivingChannelRental() {
        const ret = wasm.simpleswapoutput_receivingChannelRental(this.__wbg_ptr);
        return ret === 0 ? undefined : ReceivingChannelRental.__wrap(ret);
    }
    /**
     * @returns {DualFundDeposit | undefined}
     */
    get dualFundDeposit() {
        const ret = wasm.simpleswapoutput_dualFundDeposit(this.__wbg_ptr);
        return ret === 0 ? undefined : DualFundDeposit.__wrap(ret);
    }
}

const SimpleSwapUpdateFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_simpleswapupdate_free(ptr >>> 0, 1));

export class SimpleSwapUpdate {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SimpleSwapUpdate.prototype);
        obj.__wbg_ptr = ptr;
        SimpleSwapUpdateFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SimpleSwapUpdateFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simpleswapupdate_free(ptr, 0);
    }
    /**
     * @returns {SimpleSwapUpdateType}
     */
    get updateType() {
        const ret = wasm.simpleswapupdate_updateType(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get channelId() {
        const ret = wasm.simpleswapupdate_channelId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {bigint}
     */
    get timestampMillis() {
        const ret = wasm.simpleswapupdate_timestampMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {string | undefined}
     */
    get orderId() {
        const ret = wasm.simpleswapupdate_orderId(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {string | undefined}
     */
    get txid() {
        const ret = wasm.simpleswapupdate_txid(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {Amount | undefined}
     */
    get selfAmount() {
        const ret = wasm.simpleswapupdate_selfAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get counterpartyAmount() {
        const ret = wasm.simpleswapupdate_counterpartyAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get sendingSelfAmount() {
        const ret = wasm.simpleswapupdate_sendingSelfAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get sendingCounterpartyAmount() {
        const ret = wasm.simpleswapupdate_sendingCounterpartyAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get receivingSelfAmount() {
        const ret = wasm.simpleswapupdate_receivingSelfAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Amount | undefined}
     */
    get receivingCounterpartyAmount() {
        const ret = wasm.simpleswapupdate_receivingCounterpartyAmount(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {string | undefined}
     */
    get error() {
        const ret = wasm.simpleswapupdate_error(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
}

const SwapAmountFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_swapamount_free(ptr >>> 0, 1));

export class SwapAmount {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SwapAmount.prototype);
        obj.__wbg_ptr = ptr;
        SwapAmountFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SwapAmountFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_swapamount_free(ptr, 0);
    }
    /**
     * @param {Amount} amount
     * @returns {SwapAmount}
     */
    static newSending(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.orderamount_newBase(amount.__wbg_ptr);
        return SwapAmount.__wrap(ret);
    }
    /**
     * @param {Amount} amount
     * @returns {SwapAmount}
     */
    static newReceiving(amount) {
        _assertClass(amount, Amount);
        const ret = wasm.allowanceamount_exact(amount.__wbg_ptr);
        return SwapAmount.__wrap(ret);
    }
}

const SwapMatchFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_swapmatch_free(ptr >>> 0, 1));

export class SwapMatch {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SwapMatch.prototype);
        obj.__wbg_ptr = ptr;
        SwapMatchFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SwapMatchFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_swapmatch_free(ptr, 0);
    }
    /**
     * The matched sending amount (paid or received by the taker)
     * @returns {Amount}
     */
    get sendingAmount() {
        const ret = wasm.currencybalance_sending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The matched receiving amount (paid or received by the taker)
     * @returns {Amount}
     */
    get receivingAmount() {
        const ret = wasm.currencybalance_receiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The fee paid by the taker in the receiving currency (if negative, the taker earns the fee)
     * @returns {Amount}
     */
    get receivingFee() {
        const ret = wasm.currencybalance_lockedSending(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * The slippage of the swap ((average price of swap - best price of swap) / best price of swap)
     * @returns {Amount}
     */
    get slippage() {
        const ret = wasm.currencybalance_lockedReceiving(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
}

const SwapProgressFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_swapprogress_free(ptr >>> 0, 1));

export class SwapProgress {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(SwapProgress.prototype);
        obj.__wbg_ptr = ptr;
        SwapProgressFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        SwapProgressFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_swapprogress_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get receivingAmount() {
        const ret = wasm.swapprogress_receivingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get payingAmount() {
        const ret = wasm.swapprogress_payingAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {SwapStatus}
     */
    get status() {
        const ret = wasm.swapprogress_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get error() {
        const ret = wasm.swapprogress_error(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
}

const TradeFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_trade_free(ptr >>> 0, 1));

export class Trade {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Trade.prototype);
        obj.__wbg_ptr = ptr;
        TradeFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TradeFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_trade_free(ptr, 0);
    }
    /**
     * @returns {Amount}
     */
    get baseAmount() {
        const ret = wasm.trade_baseAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get quoteAmount() {
        const ret = wasm.trade_quoteAmount(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {Amount}
     */
    get price() {
        const ret = wasm.trade_price(this.__wbg_ptr);
        return Amount.__wrap(ret);
    }
    /**
     * @returns {bigint}
     */
    get timestampMillis() {
        const ret = wasm.trade_timestampMillis(this.__wbg_ptr);
        return (BigInt.asUintN(64, ret[0]) | (BigInt.asUintN(64, ret[1]) << BigInt(64)));
    }
    /**
     * @returns {OrderSide}
     */
    get takerOrderSide() {
        const ret = wasm.trade_takerOrderSide(this.__wbg_ptr);
        return ret;
    }
}

const TransactionFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_transaction_free(ptr >>> 0, 1));

export class Transaction {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Transaction.prototype);
        obj.__wbg_ptr = ptr;
        TransactionFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        TransactionFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_transaction_free(ptr, 0);
    }
    /**
     * @returns {Network}
     */
    get network() {
        const ret = wasm.transaction_network(this.__wbg_ptr);
        return Network.__wrap(ret);
    }
    /**
     * @returns {Layer}
     */
    get layer() {
        const ret = wasm.transaction_layer(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Protocol}
     */
    get protocol() {
        const ret = wasm.transaction_protocol(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {TxType}
     */
    get type() {
        const ret = wasm.transaction_type(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string}
     */
    get id() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.transaction_id(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
    /**
     * @returns {TxStatus}
     */
    get status() {
        const ret = wasm.transaction_status(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {bigint | undefined}
     */
    get blockHeight() {
        const ret = wasm.transaction_blockHeight(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get timestamp() {
        const ret = wasm.transaction_timestamp(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {bigint | undefined}
     */
    get confirmations() {
        const ret = wasm.transaction_confirmations(this.__wbg_ptr);
        return ret[0] === 0 ? undefined : BigInt.asUintN(64, ret[1]);
    }
    /**
     * @returns {Amount | undefined}
     */
    get fee() {
        const ret = wasm.transaction_fee(this.__wbg_ptr);
        return ret === 0 ? undefined : Amount.__wrap(ret);
    }
    /**
     * @returns {Map<Asset, Amount>}
     */
    get spent() {
        const ret = wasm.transaction_spent(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<Asset, Amount>}
     */
    get received() {
        const ret = wasm.transaction_received(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Map<Asset, Amount>}
     */
    get balanceChanges() {
        const ret = wasm.transaction_balanceChanges(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {Operation[]}
     */
    get operations() {
        const ret = wasm.transaction_operations(this.__wbg_ptr);
        var v1 = getArrayJsValueFromWasm0(ret[0], ret[1]).slice();
        wasm.__wbindgen_free(ret[0], ret[1] * 4, 4);
        return v1;
    }
    /**
     * @returns {Map<Asset, Amount>}
     */
    get assetsAmountsToShow() {
        const ret = wasm.transaction_assetsAmountsToShow(this.__wbg_ptr);
        return ret;
    }
    /**
     * @returns {string | undefined}
     */
    get explorerLink() {
        const ret = wasm.transaction_explorerLink(this.__wbg_ptr);
        let v1;
        if (ret[0] !== 0) {
            v1 = getStringFromWasm0(ret[0], ret[1]).slice();
            wasm.__wbindgen_free(ret[0], ret[1] * 1, 1);
        }
        return v1;
    }
    /**
     * @returns {BitcoinTransaction | undefined}
     */
    asBitcoinTransaction() {
        const ret = wasm.transaction_asBitcoinTransaction(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinTransaction.__wrap(ret);
    }
    /**
     * @returns {BitcoinLightningPayment | undefined}
     */
    asBitcoinLightningPayment() {
        const ret = wasm.transaction_asBitcoinLightningPayment(this.__wbg_ptr);
        return ret === 0 ? undefined : BitcoinLightningPayment.__wrap(ret);
    }
    /**
     * @returns {EvmTransaction | undefined}
     */
    asEvmTransaction() {
        const ret = wasm.transaction_asEvmTransaction(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmTransaction.__wrap(ret);
    }
    /**
     * @returns {EvmLithiumPayment | undefined}
     */
    asEvmLithiumPayment() {
        const ret = wasm.transaction_asEvmLithiumPayment(this.__wbg_ptr);
        return ret === 0 ? undefined : EvmLithiumPayment.__wrap(ret);
    }
}

const U256Finalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_u256_free(ptr >>> 0, 1));

export class U256 {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(U256.prototype);
        obj.__wbg_ptr = ptr;
        U256Finalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        U256Finalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_u256_free(ptr, 0);
    }
    /**
     * @returns {U256}
     */
    static zero() {
        const ret = wasm.u256_zero();
        return U256.__wrap(ret);
    }
    /**
     * @returns {boolean}
     */
    isZero() {
        const ret = wasm.u256_isZero(this.__wbg_ptr);
        return ret !== 0;
    }
    /**
     * @param {string} value
     * @returns {U256}
     */
    static fromString(value) {
        const ptr0 = passStringToWasm0(value, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.u256_fromString(ptr0, len0);
        if (ret[2]) {
            throw takeFromExternrefTable0(ret[1]);
        }
        return U256.__wrap(ret[0]);
    }
    /**
     * @returns {string}
     */
    toString() {
        let deferred1_0;
        let deferred1_1;
        try {
            const ret = wasm.u256_toString(this.__wbg_ptr);
            deferred1_0 = ret[0];
            deferred1_1 = ret[1];
            return getStringFromWasm0(ret[0], ret[1]);
        } finally {
            wasm.__wbindgen_free(deferred1_0, deferred1_1, 1);
        }
    }
}

async function __wbg_load(module, imports) {
    if (typeof Response === 'function' && module instanceof Response) {
        if (typeof WebAssembly.instantiateStreaming === 'function') {
            try {
                return await WebAssembly.instantiateStreaming(module, imports);

            } catch (e) {
                if (module.headers.get('Content-Type') != 'application/wasm') {
                    console.warn("`WebAssembly.instantiateStreaming` failed because your server does not serve Wasm with `application/wasm` MIME type. Falling back to `WebAssembly.instantiate` which is slower. Original error:\n", e);

                } else {
                    throw e;
                }
            }
        }

        const bytes = await module.arrayBuffer();
        return await WebAssembly.instantiate(bytes, imports);

    } else {
        const instance = await WebAssembly.instantiate(module, imports);

        if (instance instanceof WebAssembly.Instance) {
            return { instance, module };

        } else {
            return instance;
        }
    }
}

function __wbg_get_imports() {
    const imports = {};
    imports.wbg = {};
    imports.wbg.__wbg_TAURIINVOKE_ae7d4db4ed75220e = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = window.__TAURI_INVOKE__(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_abort_410ec47a64ac6117 = function(arg0, arg1) {
        arg0.abort(arg1);
    };
    imports.wbg.__wbg_abort_775ef1d17fc65868 = function(arg0) {
        arg0.abort();
    };
    imports.wbg.__wbg_abort_99fc644e2c79c9fb = function() { return handleError(function (arg0) {
        arg0.abort();
    }, arguments) };
    imports.wbg.__wbg_addEventListener_84ae3eac6e15480a = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        arg0.addEventListener(getStringFromWasm0(arg1, arg2), arg3, arg4);
    }, arguments) };
    imports.wbg.__wbg_addEventListener_90e553fdce254421 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        arg0.addEventListener(getStringFromWasm0(arg1, arg2), arg3);
    }, arguments) };
    imports.wbg.__wbg_amount_new = function(arg0) {
        const ret = Amount.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_append_8c7dd8d641a5f01b = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        arg0.append(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    }, arguments) };
    imports.wbg.__wbg_appevent_new = function(arg0) {
        const ret = AppEvent.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_arrayBuffer_d1b44c4390db422f = function() { return handleError(function (arg0) {
        const ret = arg0.arrayBuffer();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_asset_new = function(arg0) {
        const ret = Asset.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_asset_unwrap = function(arg0) {
        const ret = Asset.__unwrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_assetbalance_new = function(arg0) {
        const ret = AssetBalance.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_assetchannel_new = function(arg0) {
        const ret = AssetChannel.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_assetchannelamounts_new = function(arg0) {
        const ret = AssetChannelAmounts.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_assetchannelamounts_unwrap = function(arg0) {
        const ret = AssetChannelAmounts.__unwrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinaddress_new = function(arg0) {
        const ret = BitcoinAddress.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinasset_new = function(arg0) {
        const ret = BitcoinAsset.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinasset_unwrap = function(arg0) {
        const ret = BitcoinAsset.__unwrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinassetid_new = function(arg0) {
        const ret = BitcoinAssetId.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinblockheader_new = function(arg0) {
        const ret = BitcoinBlockHeader.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinevent_new = function(arg0) {
        const ret = BitcoinEvent.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinfeeestimate_new = function(arg0) {
        const ret = BitcoinFeeEstimate.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinlightningassetchannel_new = function(arg0) {
        const ret = BitcoinLightningAssetChannel.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinlightninginvoice_new = function(arg0) {
        const ret = BitcoinLightningInvoice.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinoffchainoperation_new = function(arg0) {
        const ret = BitcoinOffchainOperation.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinonchainoperation_new = function(arg0) {
        const ret = BitcoinOnchainOperation.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinpublickey_new = function(arg0) {
        const ret = BitcoinPublicKey.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcoinsignedtransactionrequest_new = function(arg0) {
        const ret = BitcoinSignedTransactionRequest.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcointransactionrequest_new = function(arg0) {
        const ret = BitcoinTransactionRequest.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_bitcointxid_new = function(arg0) {
        const ret = BitcoinTxid.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_body_0b8fd1fe671660df = function(arg0) {
        const ret = arg0.body;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_buffer_09165b52af8c5237 = function(arg0) {
        const ret = arg0.buffer;
        return ret;
    };
    imports.wbg.__wbg_buffer_609cc3eee51ed158 = function(arg0) {
        const ret = arg0.buffer;
        return ret;
    };
    imports.wbg.__wbg_byobRequest_77d9adf63337edfb = function(arg0) {
        const ret = arg0.byobRequest;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_byteLength_e674b853d9c77e1d = function(arg0) {
        const ret = arg0.byteLength;
        return ret;
    };
    imports.wbg.__wbg_byteOffset_fd862df290ef848d = function(arg0) {
        const ret = arg0.byteOffset;
        return ret;
    };
    imports.wbg.__wbg_call_672a4d21634d4a24 = function() { return handleError(function (arg0, arg1) {
        const ret = arg0.call(arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_call_7cccdd69e0791ae2 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.call(arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_cancel_8a308660caa6cadf = function(arg0) {
        const ret = arg0.cancel();
        return ret;
    };
    imports.wbg.__wbg_candlestick_new = function(arg0) {
        const ret = Candlestick.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_catch_a6e601879b2610e9 = function(arg0, arg1) {
        const ret = arg0.catch(arg1);
        return ret;
    };
    imports.wbg.__wbg_channel_new = function(arg0) {
        const ret = Channel.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_clearTimeout_5a54f8841c30079a = function(arg0) {
        const ret = clearTimeout(arg0);
        return ret;
    };
    imports.wbg.__wbg_clearTimeout_86721db0036bea98 = function(arg0) {
        const ret = clearTimeout(arg0);
        return ret;
    };
    imports.wbg.__wbg_clearTimeout_96804de0ab838f26 = function(arg0) {
        const ret = clearTimeout(arg0);
        return ret;
    };
    imports.wbg.__wbg_clienttrade_new = function(arg0) {
        const ret = ClientTrade.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_close_26fc2e6856d8567a = function(arg0) {
        arg0.close();
    };
    imports.wbg.__wbg_close_2893b7d056a0627d = function() { return handleError(function (arg0) {
        arg0.close();
    }, arguments) };
    imports.wbg.__wbg_close_304cc1fef3466669 = function() { return handleError(function (arg0) {
        arg0.close();
    }, arguments) };
    imports.wbg.__wbg_close_5ce03e29be453811 = function() { return handleError(function (arg0) {
        arg0.close();
    }, arguments) };
    imports.wbg.__wbg_code_cfd8f6868bdaed9b = function(arg0) {
        const ret = arg0.code;
        return ret;
    };
    imports.wbg.__wbg_code_f4ec1e6e2e1b0417 = function(arg0) {
        const ret = arg0.code;
        return ret;
    };
    imports.wbg.__wbg_createObjectStore_d2f9e1016f4d81b9 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = arg0.createObjectStore(getStringFromWasm0(arg1, arg2), arg3);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_crypto_ed58b8e10a292839 = function(arg0) {
        const ret = arg0.crypto;
        return ret;
    };
    imports.wbg.__wbg_currencybalance_new = function(arg0) {
        const ret = CurrencyBalance.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_data_432d9c3df2630942 = function(arg0) {
        const ret = arg0.data;
        return ret;
    };
    imports.wbg.__wbg_debug_3cb59063b29f58c1 = function(arg0) {
        console.debug(arg0);
    };
    imports.wbg.__wbg_debug_e17b51583ca6a632 = function(arg0, arg1, arg2, arg3) {
        console.debug(arg0, arg1, arg2, arg3);
    };
    imports.wbg.__wbg_deleteDatabase_b8f234aeb7ad3452 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.deleteDatabase(getStringFromWasm0(arg1, arg2));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_delete_200677093b4cf756 = function() { return handleError(function (arg0, arg1) {
        const ret = arg0.delete(arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_dexevent_new = function(arg0) {
        const ret = DexEvent.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_dispatchEvent_9e259d7c1d603dfb = function() { return handleError(function (arg0, arg1) {
        const ret = arg0.dispatchEvent(arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_done_769e5ede4b31c67b = function(arg0) {
        const ret = arg0.done;
        return ret;
    };
    imports.wbg.__wbg_enqueue_bb16ba72f537dc9e = function() { return handleError(function (arg0, arg1) {
        arg0.enqueue(arg1);
    }, arguments) };
    imports.wbg.__wbg_entries_3265d4158b33e5dc = function(arg0) {
        const ret = Object.entries(arg0);
        return ret;
    };
    imports.wbg.__wbg_error_524f506f44df1645 = function(arg0) {
        console.error(arg0);
    };
    imports.wbg.__wbg_error_7534b8e9a36f1ab4 = function(arg0, arg1) {
        let deferred0_0;
        let deferred0_1;
        try {
            deferred0_0 = arg0;
            deferred0_1 = arg1;
            console.error(getStringFromWasm0(arg0, arg1));
        } finally {
            wasm.__wbindgen_free(deferred0_0, deferred0_1, 1);
        }
    };
    imports.wbg.__wbg_error_80de38b3f7cc3c3c = function(arg0, arg1, arg2, arg3) {
        console.error(arg0, arg1, arg2, arg3);
    };
    imports.wbg.__wbg_error_ff4ddaabdfc5dbb3 = function() { return handleError(function (arg0) {
        const ret = arg0.error;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments) };
    imports.wbg.__wbg_evmaddress_new = function(arg0) {
        const ret = EvmAddress.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmasset_new = function(arg0) {
        const ret = EvmAsset.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmasset_unwrap = function(arg0) {
        const ret = EvmAsset.__unwrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmblockheader_new = function(arg0) {
        const ret = EvmBlockHeader.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmevent_new = function(arg0) {
        const ret = EvmEvent.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmfeeestimate_new = function(arg0) {
        const ret = EvmFeeEstimate.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmlithiumassetchannel_new = function(arg0) {
        const ret = EvmLithiumAssetChannel.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmlithiuminvoice_new = function(arg0) {
        const ret = EvmLithiumInvoice.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmoffchainoperation_new = function(arg0) {
        const ret = EvmOffchainOperation.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmonchainoperation_new = function(arg0) {
        const ret = EvmOnchainOperation.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmsignedtransactionrequest_new = function(arg0) {
        const ret = EvmSignedTransactionRequest.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmtransactionrequest_new = function(arg0) {
        const ret = EvmTransactionRequest.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_evmtxid_new = function(arg0) {
        const ret = EvmTxid.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_fetch_07cd86dd296a5a63 = function(arg0, arg1, arg2) {
        const ret = arg0.fetch(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_fetch_509096533071c657 = function(arg0, arg1) {
        const ret = arg0.fetch(arg1);
        return ret;
    };
    imports.wbg.__wbg_fetch_d36a73832f0a45e8 = function(arg0) {
        const ret = fetch(arg0);
        return ret;
    };
    imports.wbg.__wbg_fetch_ddda04abd7e9916b = function(arg0, arg1) {
        const ret = fetch(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_fetch_f1856afdb49415d1 = function(arg0) {
        const ret = fetch(arg0);
        return ret;
    };
    imports.wbg.__wbg_fiatbalance_new = function(arg0) {
        const ret = FiatBalance.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_forEach_e1cf6f7c8ecb7dae = function(arg0, arg1, arg2) {
        try {
            var state0 = {a: arg1, b: arg2};
            var cb0 = (arg0, arg1) => {
                const a = state0.a;
                state0.a = 0;
                try {
                    return __wbg_adapter_1277(a, state0.b, arg0, arg1);
                } finally {
                    state0.a = a;
                }
            };
            arg0.forEach(cb0);
        } finally {
            state0.a = state0.b = 0;
        }
    };
    imports.wbg.__wbg_from_2a5d3e218e67aa85 = function(arg0) {
        const ret = Array.from(arg0);
        return ret;
    };
    imports.wbg.__wbg_getAllKeys_b11d8835dc4be0e8 = function() { return handleError(function (arg0) {
        const ret = arg0.getAllKeys();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_getItem_17f98dee3b43fa7e = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = arg1.getItem(getStringFromWasm0(arg2, arg3));
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    }, arguments) };
    imports.wbg.__wbg_getRandomValues_21a0191e74d0e1d3 = function() { return handleError(function (arg0, arg1) {
        globalThis.crypto.getRandomValues(getArrayU8FromWasm0(arg0, arg1));
    }, arguments) };
    imports.wbg.__wbg_getRandomValues_bcb4912f16000dc4 = function() { return handleError(function (arg0, arg1) {
        arg0.getRandomValues(arg1);
    }, arguments) };
    imports.wbg.__wbg_getReader_48e00749fe3f6089 = function() { return handleError(function (arg0) {
        const ret = arg0.getReader();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_getTime_46267b1c24877e30 = function(arg0) {
        const ret = arg0.getTime();
        return ret;
    };
    imports.wbg.__wbg_get_4f73335ab78445db = function(arg0, arg1, arg2) {
        const ret = arg1[arg2 >>> 0];
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_get_67b2ba62fc30de12 = function() { return handleError(function (arg0, arg1) {
        const ret = Reflect.get(arg0, arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_get_74b8744f6a23f4fa = function(arg0, arg1, arg2) {
        const ret = arg0[getStringFromWasm0(arg1, arg2)];
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_get_8da03f81f6a1111e = function() { return handleError(function (arg0, arg1) {
        const ret = arg0.get(arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_get_b9b93047fe3cf45b = function(arg0, arg1) {
        const ret = arg0[arg1 >>> 0];
        return ret;
    };
    imports.wbg.__wbg_getdone_d47073731acd3e74 = function(arg0) {
        const ret = arg0.done;
        return isLikeNone(ret) ? 0xFFFFFF : ret ? 1 : 0;
    };
    imports.wbg.__wbg_getvalue_009dcd63692bee1f = function(arg0) {
        const ret = arg0.value;
        return ret;
    };
    imports.wbg.__wbg_getwithrefkey_1dc361bd10053bfe = function(arg0, arg1) {
        const ret = arg0[arg1];
        return ret;
    };
    imports.wbg.__wbg_has_a5ea9117f258a0ec = function() { return handleError(function (arg0, arg1) {
        const ret = Reflect.has(arg0, arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_headers_9cb51cfd2ac780a4 = function(arg0) {
        const ret = arg0.headers;
        return ret;
    };
    imports.wbg.__wbg_indexedDB_b1f49280282046f8 = function() { return handleError(function (arg0) {
        const ret = arg0.indexedDB;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments) };
    imports.wbg.__wbg_indexedDB_f6b47b0dc333fd2f = function() { return handleError(function (arg0) {
        const ret = arg0.indexedDB;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments) };
    imports.wbg.__wbg_info_033d8b8a0838f1d3 = function(arg0, arg1, arg2, arg3) {
        console.info(arg0, arg1, arg2, arg3);
    };
    imports.wbg.__wbg_info_3daf2e093e091b66 = function(arg0) {
        console.info(arg0);
    };
    imports.wbg.__wbg_instanceof_ArrayBuffer_e14585432e3737fc = function(arg0) {
        let result;
        try {
            result = arg0 instanceof ArrayBuffer;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Blob_ca721ef3bdab15d1 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Blob;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_DomException_ed1ccb7aaf39034c = function(arg0) {
        let result;
        try {
            result = arg0 instanceof DOMException;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Error_4d54113b22d20306 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Error;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_IdbDatabase_a3ef009ca00059f9 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof IDBDatabase;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_IdbOpenDbRequest_a3416e156c9db893 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof IDBOpenDBRequest;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_IdbRequest_4813c3f207666aa4 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof IDBRequest;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Map_f3469ce2244d2430 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Map;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Response_f2cc20d9f7dfd644 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Response;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Uint8Array_17156bcf118086a9 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Uint8Array;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_Window_def73ea0955fc569 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof Window;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_instanceof_WorkerGlobalScope_dbdbdea7e3b56493 = function(arg0) {
        let result;
        try {
            result = arg0 instanceof WorkerGlobalScope;
        } catch (_) {
            result = false;
        }
        const ret = result;
        return ret;
    };
    imports.wbg.__wbg_isArray_a1eab7e0d067391b = function(arg0) {
        const ret = Array.isArray(arg0);
        return ret;
    };
    imports.wbg.__wbg_isSafeInteger_343e2beeeece1bb0 = function(arg0) {
        const ret = Number.isSafeInteger(arg0);
        return ret;
    };
    imports.wbg.__wbg_iterator_9a24c88df860dc65 = function() {
        const ret = Symbol.iterator;
        return ret;
    };
    imports.wbg.__wbg_key_c5e0a01cf450dca2 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg1.key(arg2 >>> 0);
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    }, arguments) };
    imports.wbg.__wbg_length_52b6c4580c5ec934 = function(arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_length_a446193dc22c12f8 = function(arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_length_e2d2a49132c1b256 = function(arg0) {
        const ret = arg0.length;
        return ret;
    };
    imports.wbg.__wbg_length_ed4a84b02b798bda = function() { return handleError(function (arg0) {
        const ret = arg0.length;
        return ret;
    }, arguments) };
    imports.wbg.__wbg_lightningchannelid_new = function(arg0) {
        const ret = LightningChannelId.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_liquidity_new = function(arg0) {
        const ret = Liquidity.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_listen_fb970f2314c9789c = function(arg0, arg1, arg2) {
        const ret = window.__TAURI__.event.listen(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    };
    imports.wbg.__wbg_lithiumchannelid_new = function(arg0) {
        const ret = LithiumChannelId.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_localStorage_1406c99c39728187 = function() { return handleError(function (arg0) {
        const ret = arg0.localStorage;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    }, arguments) };
    imports.wbg.__wbg_marketdailystats_new = function(arg0) {
        const ret = MarketDailyStats.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_marketevent_new = function(arg0) {
        const ret = MarketEvent.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_marketinfo_new = function(arg0) {
        const ret = MarketInfo.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_message_97a2af9b89d693a3 = function(arg0) {
        const ret = arg0.message;
        return ret;
    };
    imports.wbg.__wbg_msCrypto_0a36e2ec3a343d26 = function(arg0) {
        const ret = arg0.msCrypto;
        return ret;
    };
    imports.wbg.__wbg_name_0b327d569f00ebee = function(arg0) {
        const ret = arg0.name;
        return ret;
    };
    imports.wbg.__wbg_name_f2d27098bfd843e7 = function(arg0, arg1) {
        const ret = arg1.name;
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_network_new = function(arg0) {
        const ret = Network.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_new0_f788a2397c7ca929 = function() {
        const ret = new Date();
        return ret;
    };
    imports.wbg.__wbg_new_018dcc2d6c8c2f6a = function() { return handleError(function () {
        const ret = new Headers();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_new_23a2665fac83c611 = function(arg0, arg1) {
        try {
            var state0 = {a: arg0, b: arg1};
            var cb0 = (arg0, arg1) => {
                const a = state0.a;
                state0.a = 0;
                try {
                    return __wbg_adapter_1277(a, state0.b, arg0, arg1);
                } finally {
                    state0.a = a;
                }
            };
            const ret = new Promise(cb0);
            return ret;
        } finally {
            state0.a = state0.b = 0;
        }
    };
    imports.wbg.__wbg_new_405e22f390576ce2 = function() {
        const ret = new Object();
        return ret;
    };
    imports.wbg.__wbg_new_5e0be73521bc8c17 = function() {
        const ret = new Map();
        return ret;
    };
    imports.wbg.__wbg_new_78feb108b6472713 = function() {
        const ret = new Array();
        return ret;
    };
    imports.wbg.__wbg_new_8a6f238a6ece86ea = function() {
        const ret = new Error();
        return ret;
    };
    imports.wbg.__wbg_new_92c54fc74574ef55 = function() { return handleError(function (arg0, arg1) {
        const ret = new WebSocket(getStringFromWasm0(arg0, arg1));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_new_a12002a7f91c75be = function(arg0) {
        const ret = new Uint8Array(arg0);
        return ret;
    };
    imports.wbg.__wbg_new_c68d7209be747379 = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return ret;
    };
    imports.wbg.__wbg_new_e25e5aab09ff45db = function() { return handleError(function () {
        const ret = new AbortController();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_newnoargs_105ed471475aaf50 = function(arg0, arg1) {
        const ret = new Function(getStringFromWasm0(arg0, arg1));
        return ret;
    };
    imports.wbg.__wbg_newwithbyteoffsetandlength_d97e637ebe145a9a = function(arg0, arg1, arg2) {
        const ret = new Uint8Array(arg0, arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwitheventinitdict_502dbfa1b3d2fcbc = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = new CloseEvent(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_newwitheventinitdict_fae2ef218b85b0d2 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = new CustomEvent(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_newwithlength_a381634e90c276d4 = function(arg0) {
        const ret = new Uint8Array(arg0 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithlength_c4c419ef0bc8a1f8 = function(arg0) {
        const ret = new Array(arg0 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_newwithstrandinit_06c535e0a867c635 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = new Request(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_newwithstrsequence_6e9d6479e1cf978d = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = new WebSocket(getStringFromWasm0(arg0, arg1), arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_next_25feadfc0913fea9 = function(arg0) {
        const ret = arg0.next;
        return ret;
    };
    imports.wbg.__wbg_next_6574e1a8a62d1055 = function() { return handleError(function (arg0) {
        const ret = arg0.next();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_node_02999533c4ea02e3 = function(arg0) {
        const ret = arg0.node;
        return ret;
    };
    imports.wbg.__wbg_now_2c95c9de01293173 = function(arg0) {
        const ret = arg0.now();
        return ret;
    };
    imports.wbg.__wbg_now_807e54c39636c349 = function() {
        const ret = Date.now();
        return ret;
    };
    imports.wbg.__wbg_now_fb0466b5460cff09 = function(arg0) {
        const ret = arg0.now();
        return ret;
    };
    imports.wbg.__wbg_objectStoreNames_9bb1ab04a7012aaf = function(arg0) {
        const ret = arg0.objectStoreNames;
        return ret;
    };
    imports.wbg.__wbg_objectStore_21878d46d25b64b6 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.objectStore(getStringFromWasm0(arg1, arg2));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_of_66b3ee656cbd962b = function(arg0, arg1) {
        const ret = Array.of(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_open_88b1390d99a7c691 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.open(getStringFromWasm0(arg1, arg2));
        return ret;
    }, arguments) };
    imports.wbg.__wbg_open_e0c0b2993eb596e1 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        const ret = arg0.open(getStringFromWasm0(arg1, arg2), arg3 >>> 0);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_operation_new = function(arg0) {
        const ret = Operation.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_order_new = function(arg0) {
        const ret = Order.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_orderbook_new = function(arg0) {
        const ret = Orderbook.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_ordermatch_new = function(arg0) {
        const ret = OrderMatch.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_partiallymatchedorder_new = function(arg0) {
        const ret = PartiallyMatchedOrder.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_performance_71b063e177862740 = function(arg0) {
        const ret = arg0.performance;
        return ret;
    };
    imports.wbg.__wbg_performance_7a3ffd0b17f663ad = function(arg0) {
        const ret = arg0.performance;
        return ret;
    };
    imports.wbg.__wbg_preventDefault_c2314fd813c02b3c = function(arg0) {
        arg0.preventDefault();
    };
    imports.wbg.__wbg_process_5c1d670bc53614b8 = function(arg0) {
        const ret = arg0.process;
        return ret;
    };
    imports.wbg.__wbg_push_737cfc8c1432c2c6 = function(arg0, arg1) {
        const ret = arg0.push(arg1);
        return ret;
    };
    imports.wbg.__wbg_put_066faa31a6a88f5b = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.put(arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_queueMicrotask_97d92b4fcc8a61c5 = function(arg0) {
        queueMicrotask(arg0);
    };
    imports.wbg.__wbg_queueMicrotask_d3219def82552485 = function(arg0) {
        const ret = arg0.queueMicrotask;
        return ret;
    };
    imports.wbg.__wbg_randomFillSync_ab2cfe79ebbf2740 = function() { return handleError(function (arg0, arg1) {
        arg0.randomFillSync(arg1);
    }, arguments) };
    imports.wbg.__wbg_read_a2434af1186cb56c = function(arg0) {
        const ret = arg0.read();
        return ret;
    };
    imports.wbg.__wbg_readyState_7ef6e63c349899ed = function(arg0) {
        const ret = arg0.readyState;
        return ret;
    };
    imports.wbg.__wbg_reason_49f1cede8bcf23dd = function(arg0, arg1) {
        const ret = arg1.reason;
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_releaseLock_091899af97991d2e = function(arg0) {
        arg0.releaseLock();
    };
    imports.wbg.__wbg_removeEventListener_056dfe8c3d6c58f9 = function() { return handleError(function (arg0, arg1, arg2, arg3) {
        arg0.removeEventListener(getStringFromWasm0(arg1, arg2), arg3);
    }, arguments) };
    imports.wbg.__wbg_removeItem_9d2669ee3bba6f7d = function() { return handleError(function (arg0, arg1, arg2) {
        arg0.removeItem(getStringFromWasm0(arg1, arg2));
    }, arguments) };
    imports.wbg.__wbg_rentableassetinfo_new = function(arg0) {
        const ret = RentableAssetInfo.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_rentalassetconfig_new = function(arg0) {
        const ret = RentalAssetConfig.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_rentalnodeinfo_new = function(arg0) {
        const ret = RentalNodeInfo.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_require_79b1e9274cde3c87 = function() { return handleError(function () {
        const ret = module.require;
        return ret;
    }, arguments) };
    imports.wbg.__wbg_resolve_4851785c9c5f573d = function(arg0) {
        const ret = Promise.resolve(arg0);
        return ret;
    };
    imports.wbg.__wbg_respond_1f279fa9f8edcb1c = function() { return handleError(function (arg0, arg1) {
        arg0.respond(arg1 >>> 0);
    }, arguments) };
    imports.wbg.__wbg_result_f29afabdf2c05826 = function() { return handleError(function (arg0) {
        const ret = arg0.result;
        return ret;
    }, arguments) };
    imports.wbg.__wbg_send_0293179ba074ffb4 = function() { return handleError(function (arg0, arg1, arg2) {
        arg0.send(getStringFromWasm0(arg1, arg2));
    }, arguments) };
    imports.wbg.__wbg_send_fc0c204e8a1757f4 = function() { return handleError(function (arg0, arg1, arg2) {
        arg0.send(getArrayU8FromWasm0(arg1, arg2));
    }, arguments) };
    imports.wbg.__wbg_sendamount_unwrap = function(arg0) {
        const ret = SendAmount.__unwrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_setItem_212ecc915942ab0a = function() { return handleError(function (arg0, arg1, arg2, arg3, arg4) {
        arg0.setItem(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    }, arguments) };
    imports.wbg.__wbg_setTimeout_25eabdb2fc442ea2 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.setTimeout(arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_setTimeout_2e707715f8cc9497 = function(arg0, arg1) {
        const ret = setTimeout(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbg_setTimeout_b4ee584b3f982e97 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.setTimeout(arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_setTimeout_db2dbaeefb6f39c7 = function() { return handleError(function (arg0, arg1) {
        const ret = setTimeout(arg0, arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_setTimeout_eefe7f4c234b0c6b = function() { return handleError(function (arg0, arg1) {
        const ret = setTimeout(arg0, arg1);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_setTimeout_f2fe5af8e3debeb3 = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.setTimeout(arg1, arg2);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_set_37837023f3d740e8 = function(arg0, arg1, arg2) {
        arg0[arg1 >>> 0] = arg2;
    };
    imports.wbg.__wbg_set_3f1d0b984ed272ed = function(arg0, arg1, arg2) {
        arg0[arg1] = arg2;
    };
    imports.wbg.__wbg_set_65595bdd868b3009 = function(arg0, arg1, arg2) {
        arg0.set(arg1, arg2 >>> 0);
    };
    imports.wbg.__wbg_set_8fc6bf8a5b1071d1 = function(arg0, arg1, arg2) {
        const ret = arg0.set(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_setbinaryType_92fa1ffd873b327c = function(arg0, arg1) {
        arg0.binaryType = __wbindgen_enum_BinaryType[arg1];
    };
    imports.wbg.__wbg_setbody_5923b78a95eedf29 = function(arg0, arg1) {
        arg0.body = arg1;
    };
    imports.wbg.__wbg_setcache_12f17c3a980650e4 = function(arg0, arg1) {
        arg0.cache = __wbindgen_enum_RequestCache[arg1];
    };
    imports.wbg.__wbg_setcode_156060465a2f8f79 = function(arg0, arg1) {
        arg0.code = arg1;
    };
    imports.wbg.__wbg_setcredentials_c3a22f1cd105a2c6 = function(arg0, arg1) {
        arg0.credentials = __wbindgen_enum_RequestCredentials[arg1];
    };
    imports.wbg.__wbg_setdetail_fc5160ccbec4ee24 = function(arg0, arg1) {
        arg0.detail = arg1;
    };
    imports.wbg.__wbg_setheaders_834c0bdb6a8949ad = function(arg0, arg1) {
        arg0.headers = arg1;
    };
    imports.wbg.__wbg_setintegrity_564a2397cf837760 = function(arg0, arg1, arg2) {
        arg0.integrity = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setmethod_3c5280fe5d890842 = function(arg0, arg1, arg2) {
        arg0.method = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setmode_5dc300b865044b65 = function(arg0, arg1) {
        arg0.mode = __wbindgen_enum_RequestMode[arg1];
    };
    imports.wbg.__wbg_setonce_0cb80aea26303a35 = function(arg0, arg1) {
        arg0.once = arg1 !== 0;
    };
    imports.wbg.__wbg_setonclose_14fc475a49d488fc = function(arg0, arg1) {
        arg0.onclose = arg1;
    };
    imports.wbg.__wbg_setonerror_8639efe354b947cd = function(arg0, arg1) {
        arg0.onerror = arg1;
    };
    imports.wbg.__wbg_setonerror_d7e3056cc6e56085 = function(arg0, arg1) {
        arg0.onerror = arg1;
    };
    imports.wbg.__wbg_setonmessage_6eccab530a8fb4c7 = function(arg0, arg1) {
        arg0.onmessage = arg1;
    };
    imports.wbg.__wbg_setonopen_2da654e1f39745d5 = function(arg0, arg1) {
        arg0.onopen = arg1;
    };
    imports.wbg.__wbg_setonsuccess_afa464ee777a396d = function(arg0, arg1) {
        arg0.onsuccess = arg1;
    };
    imports.wbg.__wbg_setonupgradeneeded_fcf7ce4f2eb0cb5f = function(arg0, arg1) {
        arg0.onupgradeneeded = arg1;
    };
    imports.wbg.__wbg_setreason_d29ac0402eeeb81a = function(arg0, arg1, arg2) {
        arg0.reason = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setredirect_40e6a7f717a2f86a = function(arg0, arg1) {
        arg0.redirect = __wbindgen_enum_RequestRedirect[arg1];
    };
    imports.wbg.__wbg_setreferrer_fea46c1230e5e29a = function(arg0, arg1, arg2) {
        arg0.referrer = getStringFromWasm0(arg1, arg2);
    };
    imports.wbg.__wbg_setreferrerpolicy_b73612479f761b6f = function(arg0, arg1) {
        arg0.referrerPolicy = __wbindgen_enum_ReferrerPolicy[arg1];
    };
    imports.wbg.__wbg_setsignal_75b21ef3a81de905 = function(arg0, arg1) {
        arg0.signal = arg1;
    };
    imports.wbg.__wbg_signal_aaf9ad74119f20a4 = function(arg0) {
        const ret = arg0.signal;
        return ret;
    };
    imports.wbg.__wbg_simpleswapestimate_new = function(arg0) {
        const ret = SimpleSwapEstimate.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_simpleswapoutput_new = function(arg0) {
        const ret = SimpleSwapOutput.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_simpleswapupdate_new = function(arg0) {
        const ret = SimpleSwapUpdate.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_stack_0ed75d68575b0f3c = function(arg0, arg1) {
        const ret = arg1.stack;
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_static_accessor_GLOBAL_88a902d13a557d07 = function() {
        const ret = typeof global === 'undefined' ? null : global;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_static_accessor_GLOBAL_THIS_56578be7e9f832b0 = function() {
        const ret = typeof globalThis === 'undefined' ? null : globalThis;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_static_accessor_SELF_37c5d418e4bf5819 = function() {
        const ret = typeof self === 'undefined' ? null : self;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_static_accessor_WINDOW_5de37043a91a9c40 = function() {
        const ret = typeof window === 'undefined' ? null : window;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_status_f6360336ca686bf0 = function(arg0) {
        const ret = arg0.status;
        return ret;
    };
    imports.wbg.__wbg_stringify_f7ed6987935b4a24 = function() { return handleError(function (arg0) {
        const ret = JSON.stringify(arg0);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_subarray_aa9065fa9dc5df96 = function(arg0, arg1, arg2) {
        const ret = arg0.subarray(arg1 >>> 0, arg2 >>> 0);
        return ret;
    };
    imports.wbg.__wbg_swapmatch_new = function(arg0) {
        const ret = SwapMatch.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_target_0a62d9d79a2a1ede = function(arg0) {
        const ret = arg0.target;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_text_7805bea50de2af49 = function() { return handleError(function (arg0) {
        const ret = arg0.text();
        return ret;
    }, arguments) };
    imports.wbg.__wbg_then_44b73946d2fb3e7d = function(arg0, arg1) {
        const ret = arg0.then(arg1);
        return ret;
    };
    imports.wbg.__wbg_then_48b406749878a531 = function(arg0, arg1, arg2) {
        const ret = arg0.then(arg1, arg2);
        return ret;
    };
    imports.wbg.__wbg_toString_5285597960676b7b = function(arg0) {
        const ret = arg0.toString();
        return ret;
    };
    imports.wbg.__wbg_toString_c813bbd34d063839 = function(arg0) {
        const ret = arg0.toString();
        return ret;
    };
    imports.wbg.__wbg_trade_new = function(arg0) {
        const ret = Trade.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_transaction_d6d07c3c9963c49e = function() { return handleError(function (arg0, arg1, arg2) {
        const ret = arg0.transaction(arg1, __wbindgen_enum_IdbTransactionMode[arg2]);
        return ret;
    }, arguments) };
    imports.wbg.__wbg_transaction_e713aa7b07ccaedd = function(arg0) {
        const ret = arg0.transaction;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_transaction_new = function(arg0) {
        const ret = Transaction.__wrap(arg0);
        return ret;
    };
    imports.wbg.__wbg_url_ae10c34ca209681d = function(arg0, arg1) {
        const ret = arg1.url;
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_url_ce9ab75bf9627ae4 = function(arg0, arg1) {
        const ret = arg1.url;
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbg_value_cd1ffa7b1ab794f1 = function(arg0) {
        const ret = arg0.value;
        return ret;
    };
    imports.wbg.__wbg_version_a70a33e5bbc6d6db = function(arg0) {
        const ret = arg0.version;
        return ret;
    };
    imports.wbg.__wbg_versions_c71aa1626a93e0a1 = function(arg0) {
        const ret = arg0.versions;
        return ret;
    };
    imports.wbg.__wbg_view_fd8a56e8983f448d = function(arg0) {
        const ret = arg0.view;
        return isLikeNone(ret) ? 0 : addToExternrefTable0(ret);
    };
    imports.wbg.__wbg_warn_4ca3906c248c47c4 = function(arg0) {
        console.warn(arg0);
    };
    imports.wbg.__wbg_warn_aaf1f4664a035bd6 = function(arg0, arg1, arg2, arg3) {
        console.warn(arg0, arg1, arg2, arg3);
    };
    imports.wbg.__wbg_wasClean_605b4fd66d44354a = function(arg0) {
        const ret = arg0.wasClean;
        return ret;
    };
    imports.wbg.__wbindgen_array_new = function() {
        const ret = [];
        return ret;
    };
    imports.wbg.__wbindgen_array_push = function(arg0, arg1) {
        arg0.push(arg1);
    };
    imports.wbg.__wbindgen_as_number = function(arg0) {
        const ret = +arg0;
        return ret;
    };
    imports.wbg.__wbindgen_bigint_from_i64 = function(arg0) {
        const ret = arg0;
        return ret;
    };
    imports.wbg.__wbindgen_bigint_from_u128 = function(arg0, arg1) {
        const ret = BigInt.asUintN(64, arg0) << BigInt(64) | BigInt.asUintN(64, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_bigint_from_u64 = function(arg0) {
        const ret = BigInt.asUintN(64, arg0);
        return ret;
    };
    imports.wbg.__wbindgen_bigint_get_as_i64 = function(arg0, arg1) {
        const v = arg1;
        const ret = typeof(v) === 'bigint' ? v : undefined;
        getDataViewMemory0().setBigInt64(arg0 + 8 * 1, isLikeNone(ret) ? BigInt(0) : ret, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
    };
    imports.wbg.__wbindgen_boolean_get = function(arg0) {
        const v = arg0;
        const ret = typeof(v) === 'boolean' ? (v ? 1 : 0) : 2;
        return ret;
    };
    imports.wbg.__wbindgen_cb_drop = function(arg0) {
        const obj = arg0.original;
        if (obj.cnt-- == 1) {
            obj.a = 0;
            return true;
        }
        const ret = false;
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper31326 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 7343, __wbg_adapter_62);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper32808 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 7662, __wbg_adapter_65);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper33347 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 7732, __wbg_adapter_68);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper42557 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 8916, __wbg_adapter_71);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper42598 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 8916, __wbg_adapter_74);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper42797 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9013, __wbg_adapter_77);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper42968 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9063, __wbg_adapter_80);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper43736 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9181, __wbg_adapter_83);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper44052 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9241, __wbg_adapter_86);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper44054 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9241, __wbg_adapter_89);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper44056 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9241, __wbg_adapter_89);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper44058 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9241, __wbg_adapter_89);
        return ret;
    };
    imports.wbg.__wbindgen_closure_wrapper44142 = function(arg0, arg1, arg2) {
        const ret = makeMutClosure(arg0, arg1, 9271, __wbg_adapter_96);
        return ret;
    };
    imports.wbg.__wbindgen_debug_string = function(arg0, arg1) {
        const ret = debugString(arg1);
        const ptr1 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_error_new = function(arg0, arg1) {
        const ret = new Error(getStringFromWasm0(arg0, arg1));
        return ret;
    };
    imports.wbg.__wbindgen_in = function(arg0, arg1) {
        const ret = arg0 in arg1;
        return ret;
    };
    imports.wbg.__wbindgen_init_externref_table = function() {
        const table = wasm.__wbindgen_export_2;
        const offset = table.grow(4);
        table.set(0, undefined);
        table.set(offset + 0, undefined);
        table.set(offset + 1, null);
        table.set(offset + 2, true);
        table.set(offset + 3, false);
        ;
    };
    imports.wbg.__wbindgen_is_bigint = function(arg0) {
        const ret = typeof(arg0) === 'bigint';
        return ret;
    };
    imports.wbg.__wbindgen_is_function = function(arg0) {
        const ret = typeof(arg0) === 'function';
        return ret;
    };
    imports.wbg.__wbindgen_is_object = function(arg0) {
        const val = arg0;
        const ret = typeof(val) === 'object' && val !== null;
        return ret;
    };
    imports.wbg.__wbindgen_is_string = function(arg0) {
        const ret = typeof(arg0) === 'string';
        return ret;
    };
    imports.wbg.__wbindgen_is_undefined = function(arg0) {
        const ret = arg0 === undefined;
        return ret;
    };
    imports.wbg.__wbindgen_jsval_eq = function(arg0, arg1) {
        const ret = arg0 === arg1;
        return ret;
    };
    imports.wbg.__wbindgen_jsval_loose_eq = function(arg0, arg1) {
        const ret = arg0 == arg1;
        return ret;
    };
    imports.wbg.__wbindgen_memory = function() {
        const ret = wasm.memory;
        return ret;
    };
    imports.wbg.__wbindgen_number_get = function(arg0, arg1) {
        const obj = arg1;
        const ret = typeof(obj) === 'number' ? obj : undefined;
        getDataViewMemory0().setFloat64(arg0 + 8 * 1, isLikeNone(ret) ? 0 : ret, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, !isLikeNone(ret), true);
    };
    imports.wbg.__wbindgen_number_new = function(arg0) {
        const ret = arg0;
        return ret;
    };
    imports.wbg.__wbindgen_shr = function(arg0, arg1) {
        const ret = arg0 >> arg1;
        return ret;
    };
    imports.wbg.__wbindgen_string_get = function(arg0, arg1) {
        const obj = arg1;
        const ret = typeof(obj) === 'string' ? obj : undefined;
        var ptr1 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len1 = WASM_VECTOR_LEN;
        getDataViewMemory0().setInt32(arg0 + 4 * 1, len1, true);
        getDataViewMemory0().setInt32(arg0 + 4 * 0, ptr1, true);
    };
    imports.wbg.__wbindgen_string_new = function(arg0, arg1) {
        const ret = getStringFromWasm0(arg0, arg1);
        return ret;
    };
    imports.wbg.__wbindgen_throw = function(arg0, arg1) {
        throw new Error(getStringFromWasm0(arg0, arg1));
    };
    imports.wbg.__wbindgen_try_into_number = function(arg0) {
        let result;
        try { result = +arg0 } catch (e) { result = e }
        const ret = result;
        return ret;
    };

    return imports;
}

function __wbg_init_memory(imports, memory) {

}

function __wbg_finalize_init(instance, module) {
    wasm = instance.exports;
    __wbg_init.__wbindgen_wasm_module = module;
    cachedDataViewMemory0 = null;
    cachedInt32ArrayMemory0 = null;
    cachedUint8ArrayMemory0 = null;


    wasm.__wbindgen_start();
    return wasm;
}

function initSync(module) {
    if (wasm !== undefined) return wasm;


    if (typeof module !== 'undefined') {
        if (Object.getPrototypeOf(module) === Object.prototype) {
            ({module} = module)
        } else {
            console.warn('using deprecated parameters for `initSync()`; pass a single object instead')
        }
    }

    const imports = __wbg_get_imports();

    __wbg_init_memory(imports);

    if (!(module instanceof WebAssembly.Module)) {
        module = new WebAssembly.Module(module);
    }

    const instance = new WebAssembly.Instance(module, imports);

    return __wbg_finalize_init(instance, module);
}

async function __wbg_init(module_or_path) {
    if (wasm !== undefined) return wasm;


    if (typeof module_or_path !== 'undefined') {
        if (Object.getPrototypeOf(module_or_path) === Object.prototype) {
            ({module_or_path} = module_or_path)
        } else {
            console.warn('using deprecated parameters for the initialization function; pass a single object instead')
        }
    }

    if (typeof module_or_path === 'undefined') {
        module_or_path = new URL('hydra_node_bg.wasm', import.meta.url);
    }
    const imports = __wbg_get_imports();

    if (typeof module_or_path === 'string' || (typeof Request === 'function' && module_or_path instanceof Request) || (typeof URL === 'function' && module_or_path instanceof URL)) {
        module_or_path = fetch(module_or_path);
    }

    __wbg_init_memory(imports);

    const { instance, module } = await __wbg_load(await module_or_path, imports);

    return __wbg_finalize_init(instance, module);
}

export { initSync };
export default __wbg_init;
