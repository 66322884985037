import { z } from 'zod';
import { ORDER_TYPES } from '~/enums/orders';
import type { LiquidityZodContext } from '~/types/ZSchemas/swap';

/**
 * We use the superRefine method to chain custom validation and
 * prevent further validation if one of them fails.
 * @param context
 * @constructor
 */
export const LiquidityAmountSchema = (context: LiquidityZodContext) =>
  z.object({
    // Validation for selected base amount (ETH in this example)
    selectedBaseAmount: z
      .number({
        invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
        required_error: context.t('form-schemas.errors.amount.required_error')
      })
      // Check for enough sending balance
      .refine((val) => {
        if (context.orderType === ORDER_TYPES.BUY) {
          return true;
        }
        return val > 0;
      }, {
        message: context.t('form-schemas.errors.amount.positive_error')
      })
    // Check for enough sending balance
      .refine((val) => {
        if (context.orderType === ORDER_TYPES.BUY) {
          return true;
        }
        return hasEnoughSendingTradingBalance(context.baseAssetBalance, val);
      }, {
        message: context.t('generics.not-enough-balance')
      })
    // Check for enough receiving balance based on base amount
      .refine(() => {
        if (context.orderType === ORDER_TYPES.BUY) {
          return true;
        }
        const { price, toPrice, baseAmount, quoteAssetBalance } = context;

        // Calculate the required quote amount (USDC) for the given base amount
        const averagePrice = (price + toPrice) / 2;
        const requiredReceivingAmount = baseAmount * averagePrice;

        // Ensure the user has enough quote asset balance (USDC)
        return hasEnoughReceivingTradingBalance(quoteAssetBalance, requiredReceivingAmount);
      }, {
        message: context.t('generics.not-enough-receiving-balance', { symbol: context.quoteAssetBalance?.asset?.symbol })
      }),

    // Validation for selected quote amount (USDC in this example)
    selectedQuoteAmount: z
      .number({
        invalid_type_error: context.t('form-schemas.errors.amount.invalid_type_error'),
        required_error: context.t('form-schemas.errors.amount.required_error')
      })
      .refine((val) => {
        if (context.orderType === ORDER_TYPES.SELL) {
          return true;
        }
        return val > 0;
      }, {
        message: context.t('form-schemas.errors.amount.positive_error')
      })
      .refine((val) => {
        if (context.orderType === ORDER_TYPES.SELL) {
          return true;
        }
        return hasEnoughSendingTradingBalance(context.quoteAssetBalance, val);
      }, {
        message: context.t('generics.not-enough-balance')
      })
    // Check for enough receiving balance based on quote amount
      .refine(() => {
        if (context.orderType === ORDER_TYPES.SELL) {
          return true;
        }
        const { price, fromPrice, baseAssetBalance, quoteAmount } = context;

        // Calculate the required base amount (ETH) for the given quote amount
        const averagePrice = (fromPrice + price) / 2;
        const requiredReceivingAmount = quoteAmount / averagePrice;

        // Ensure the user has enough base asset balance (ETH)
        return hasEnoughReceivingTradingBalance(baseAssetBalance, requiredReceivingAmount);
      }, {
        message: context.t('generics.not-enough-receiving-balance', { symbol: context.baseAssetBalance?.asset?.symbol })
      })
  });
