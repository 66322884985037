<script lang="ts" setup>
import type { Network } from 'hydra-node';
import { getNetworkName } from '~/utils';

interface Props {
  name: string;
  symbol: string;
  network?: Network;
  logoUrl: string;
  tokenValue?: number;
  fiatValue?: number;
  align?: 'left' | 'right' | 'center' | 'between';
  size?: 'sm' | 'md' | 'lg';
  displayTokenName?: boolean;
  displayNetworkName?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
  align: 'between',
  displayTokenName: true,
  displayNetworkName: true
});

const { formatFiat, formatCrypto } = useIntlFormats();

const getAvatarSize = (() => {
  return {
    sm: 'xs',
    md: 'sm',
    lg: 'md'
  }[props.size];
})();
</script>

<template>
  <div
    :class="
      ['flex w-full items-center',
       {
         'justify-start': align === 'left',
         'justify-center': align === 'center',
         'justify-between': align === 'between',
         'justify-end': align === 'right'
       }
      ]"
  >
    <div class="flex items-center gap-x-2">
      <slot name="avatar">
        <div class="relative flex items-center">
          <UAvatar
            :src="logoUrl"
            :text="symbol"
            :size="getAvatarSize"
            class="u-bg-gray-200 relative"
          />
          <div v-if="getNetworkName(network)" class="absolute right-0 top-0">
            <img
              :src="network?.logoUrl"
              :alt="getNetworkName(network)"
              class="u-bg-gray-200 rounded-full"
              :class="{
                'size-2': size === 'sm',
                'size-3': size === 'md',
                'size-4': size === 'lg'
              }"
            />
          </div>
        </div>
      </slot>
      <dl>
        <div class="flex items-center justify-start" :class="size === 'sm' ? 'gap-x-1' : 'gap-x-2'">
          <dt
            v-show="displayTokenName"
            :data-testid="`${symbol}-token-symbol`"
            class="text-gray-secondary font-medium uppercase"
            :class="size === 'lg' ? 'text-lg' : 'text-sm'"
          >
            {{ symbol }}
          </dt>
        </div>
        <dd
          v-show="displayNetworkName"
          class="text-gray-secondary truncate font-normal capitalize"
          :class="size === 'sm' ? 'text-xs' : 'text-sm'"
        >
          {{ getNetworkName(network) }}
        </dd>
      </dl>
    </div>
    <dl v-if="tokenValue">
      <div class="flex items-center justify-end" :class="size === 'sm' ? 'gap-x-1' : 'gap-x-2'">
        <dt
          class="text-gray-secondary font-medium uppercase"
          :class="size === 'lg' ? 'text-lg' : 'text-sm'"
        >
          {{ formatCrypto(tokenValue, symbol) }}
        </dt>
      </div>
      <dd v-if="fiatValue" class="text-gray-secondary flex justify-end text-sm font-normal capitalize">
        {{ formatFiat(fiatValue) }}
      </dd>
    </dl>
  </div>
</template>
