import isUndefined from 'lodash/isUndefined';
import { useTimeAgo } from '@vueuse/core';
import isNil from 'lodash/isNil';
import { convertSafelyBigIntToNumber } from '~/utils';
import { SupportedLocale } from '~/enums';

type DateLike = number | bigint | undefined;

export function convertToDate (value: number | bigint | undefined | string) {
  if (isNil(value)) {
    return new Date();
  }

  if (typeof value === 'bigint') {
    return new Date(convertSafelySecondsToMilliseconds(convertSafelyBigIntToNumber(value)));
  }

  return new Date(value);
}

export function relativeTime (value: DateLike | Date):string {
  if (isUndefined(value)) {
    return '';
  }

  if (value instanceof Date) {
    return useTimeAgo(value).value || '';
  }

  const date = convertToDate(value);

  return useTimeAgo(date).value || '';
}

export function precisRelativeTime (value: DateLike, locale = SupportedLocale.en): string {
  const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
  const now = new Date();
  const date = convertToDate(value);

  const secondsAgo = Math.round((now.getTime() - date.getTime()) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);
  const monthsAgo = Math.floor(daysAgo / 30); // Average number of days in a month
  const yearsAgo = Math.floor(daysAgo / 365); // Average number of days in a year

  if (yearsAgo > 0) {
    return relativeTimeFormatter.format(-yearsAgo, 'year');
  } else if (monthsAgo > 0) {
    return relativeTimeFormatter.format(-monthsAgo, 'month');
  } else if (daysAgo > 0) {
    return relativeTimeFormatter.format(-daysAgo, 'day');
  } else if (hoursAgo > 0) {
    return relativeTimeFormatter.format(-hoursAgo, 'hour');
  } else if (minutesAgo > 0) {
    return relativeTimeFormatter.format(-minutesAgo, 'minute');
  } else {
    return relativeTimeFormatter.format(-secondsAgo, 'second');
  }
}

export const convertSafelySecondsToMilliseconds = (number: number) => {
  const newNumber = number * 1000;
  if (Number.isSafeInteger(newNumber)) {
    return newNumber;
  } else {
    console.error('Value is too large to safely multiplied');
    return -1;
  }
};

export const convertSafelyDaysToSeconds = (number: number) => {
  const newNumber = Math.round(number * 86400); // Ensure integer conversion
  if (Number.isSafeInteger(Math.round(newNumber))) {
    return newNumber;
  } else {
    console.error('Value is too large to safely multiply');
    return -1;
  }
};

export const convertSafelySecondsToDays = (value: number | bigint) => {
  const secondsInDay = 86400;
  let _value;

  if (typeof value === 'bigint') {
    _value = Number(value);
  } else {
    _value = value;
  }
  const days = _value / secondsInDay;

  return days;
};
