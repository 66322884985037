import { defineStore } from 'pinia';
import { ref, reactive, computed, watch } from 'vue';
import isNil from 'lodash/isNil';
import type { z } from 'zod';

import { useSwapStore } from '~/stores/shared/swap';
import { LiquiditySteps, PiniaStoresId, StepStatus } from '~/enums';
import CreateLiquidityModal from '~/components/pro/templates/Modals/CreateLiquidityModal.vue';
import type { MappedDexTradingBalance } from '~/types/asset';
import { Amount, Asset, cancelOrder, createOrder, getAllOrders, getOrderbook, initMarket, OrderAmount, OrderType, OrderVariant } from 'hydra-node';
import type { Step } from '~/types';
import type { MappedOrder } from '~/types/orders';
import { ORDER_TYPES } from '~/enums/orders';
import type { LiquidityZodContext } from '~/types/ZSchemas/swap';
import { LiquidityAmountSchema } from '~/constants/ZSchemas/orders';

export const useLiquidityStore = defineStore(PiniaStoresId.LiquidityStore, () => {
  const isCreateLiquidityModalOpen = ref(false);
  const modal = useModal();
  const swapStore = useSwapStore();
  const toast = useToast();
  const alertBuilder = useAlertBuilder();
  const { t } = useI18n();

  const { tradingBalances } = storeToRefs(swapStore);

  const orders = ref<MappedOrder[]>([]);
  const lastFetched = ref<number>(0);
  const secondLiquidityAssetBalancesOptions = computed(() => {
    const selectedFrom = formState.selectedFromAsset;
    return selectedFrom
      ? tradingBalances.value?.filter(asset => asset.asset.id !== selectedFrom.asset.id || asset.asset.network.id !== selectedFrom.asset.network.id)
      : tradingBalances.value;
  });
  // Form state
  const formState = reactive({
    selectedFromAsset: null as MappedDexTradingBalance | null,
    selectedToAsset: null as MappedDexTradingBalance | null,
    selectedBaseAsset: null as MappedDexTradingBalance | null,
    selectedQuoteAsset: null as MappedDexTradingBalance | null,
    selectedFromPrice: 0,
    selectedToPrice: 0,
    selectedBaseAmount: 0,
    selectedQuoteAmount: 0,
    currentPrice: 0
  });

  const isLoading = ref<boolean>(false);
  const adjustingBase = ref<boolean>(false);

  // Determine order type
  const orderType = computed(() => {
    const { selectedFromPrice, currentPrice, selectedToPrice } = formState;
    if (selectedFromPrice <= currentPrice && currentPrice <= selectedToPrice) {
      return ORDER_TYPES.BOTH; // Both sell and buy liquidity
    }
    if (selectedFromPrice >= currentPrice) {
      return ORDER_TYPES.SELL; // Only sell liquidity
    }
    if (selectedToPrice <= currentPrice) {
      return ORDER_TYPES.BUY; // Only buy liquidity
    }
    return ORDER_TYPES.NONE; // No valid liquidity scenario
  });

  const watchedValues = computed(() => ({
    fromAssetBalance: formState.selectedFromAsset,
    toAssetBalance: formState.selectedToAsset
  }));

  const computedSchemaObject = computed(() => {
    const currentContext : LiquidityZodContext = {
      baseAssetBalance: formState.selectedBaseAsset,
      quoteAssetBalance: formState.selectedQuoteAsset,
      price: formState.currentPrice,
      baseAmount: formState.selectedBaseAmount,
      quoteAmount: formState.selectedQuoteAmount,
      fromPrice: formState.selectedFromPrice,
      toPrice: formState.selectedToPrice,
      orderType: orderType.value,
      t
    };
    return LiquidityAmountSchema(currentContext);
  });

  // Asset selection logic
  function selectAssets () {
    selectBaseAsset(tradingBalances.value);
  }

  function selectBaseAsset (newBalances: MappedDexTradingBalance[]) {
    formState.selectedFromAsset = findMappedTradingBalance(newBalances, formState.selectedFromAsset, 'ETH');
  }

  async function fetchOrders () {
    const _allOrders = await getAllOrders();
    if (!_allOrders) {
      console.debug('no orders');
      return;
    }
    const _orders: MappedOrder[] = [];
    const orderbookCache = new Map();

    for (const [assetPair, orderMap] of _allOrders.entries()) {
      if (!orderMap.size) {
        continue;
      }
      const [assetOne, assetTwo] = assetPair;
      const cacheKey = `${assetOne.symbol}-${assetTwo.symbol}`;

      const orderbook = orderbookCache.get(cacheKey) || await getOrderbook(assetOne, assetTwo);
      orderbookCache.set(cacheKey, orderbook);

      const currentAskPrice = orderbook?.asks[0]?.minPrice?.asFloat() || Number.POSITIVE_INFINITY;
      const currentBidPrice = orderbook?.bids[0]?.maxPrice?.asFloat() || 0;

      for (const [orderId, order] of orderMap.entries()) {
        if (order.orderType === OrderType.Liquidity) {
          const minBuyPrice = order.liquidityOrder?.minBuyPrice?.asFloat() || 0;
          const maxSellPrice = order.liquidityOrder?.maxSellPrice?.asFloat() || 0;
          _orders.push({ assetOne, assetTwo, orderId: String(orderId), order, inRange: minBuyPrice <= currentBidPrice && maxSellPrice >= currentAskPrice, fromPrice: minBuyPrice, toPrice: maxSellPrice });
        }
      }
    }
    orders.value = _orders;
    lastFetched.value = Date.now();
  }

  function findMappedTradingBalance (balances: MappedDexTradingBalance[], currentBalance: MappedDexTradingBalance | null, defaultSymbol: string) {
    return (
      balances.find(balance =>
        currentBalance
          ? balance.asset.symbol === currentBalance.asset.symbol &&
            balance.asset.network.symbol === currentBalance.asset.network.symbol
          : balance.asset.symbol === defaultSymbol
      ) || null
    );
  }

  function findAsset (balances: MappedDexTradingBalance[], asset: Asset) {
    return (
      balances.find(balance =>
        balance.asset.symbol === asset.symbol &&
            balance.asset.network.symbol === asset.network.symbol
      ) || null
    );
  }

  function openCreateLiquidityModal () {
    modal.open(CreateLiquidityModal);
    isCreateLiquidityModalOpen.value = true;
  }

  function closeCreateLiquidityModal () {
    modal.close();
    isCreateLiquidityModalOpen.value = false;
    resetLiquidityModal();
  };

  function resetLiquidityModal () {
    formState.selectedBaseAsset = null;
    formState.selectedQuoteAsset = null;
    formState.selectedFromAsset = null;
    formState.selectedToAsset = null;
    formState.selectedFromPrice = 0;
    formState.selectedToPrice = 0;
    formState.selectedBaseAmount = 0;
    formState.selectedQuoteAmount = 0;
    formState.currentPrice = 0;
    resetSteps(allSteps);
  };

  // Place order
  async function setOrder () {
    const { selectedBaseAsset, selectedQuoteAsset, selectedBaseAmount, selectedQuoteAmount, selectedToPrice, selectedFromPrice } = formState;

    if (!selectedBaseAsset || !selectedQuoteAsset) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.fetch.title'),
        description: t('liquidity.order.errors.fetch.description')
      }));
      return;
    }

    if (selectedBaseAsset.balance.sending.asFloat() <= 0) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.offchain-balance.title'),
        description: t('liquidity.order.errors.offchain-balance.description')
      }));
      return;
    }

    isLoading.value = true;

    try {
      if (orderType.value === ORDER_TYPES.BOTH) {
        await createSellAndBuyOrders(selectedBaseAsset, selectedQuoteAsset, selectedQuoteAmount, selectedFromPrice, selectedToPrice);
      } else if (orderType.value === ORDER_TYPES.BUY) {
        await createBuyOrder(selectedBaseAsset, selectedQuoteAsset, selectedQuoteAmount, selectedFromPrice, selectedToPrice);
      } else if (orderType.value === ORDER_TYPES.SELL) {
        await createSellOrder(selectedBaseAsset, selectedQuoteAsset, selectedBaseAmount, selectedFromPrice, selectedToPrice);
      }
      toast.add(alertBuilder.success({
        title: t('liquidity.order.success.liquidity.title'),
        description: t('liquidity.order.success.liquidity.description')
      }));
      closeCreateLiquidityModal();
    } catch (error) {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.liquidity.title'),
        description: t('liquidity.order.errors.liquidity.description')
      }));
    } finally {
      isLoading.value = false;
    }
  }

  async function createSellAndBuyOrders (baseAsset: MappedDexTradingBalance, quoteAsset: MappedDexTradingBalance, quoteAmount: number, fromPrice: number, toPrice:number) {
    const quoteDecimals = quoteAsset?.asset.decimals || 18;
    const orderAmount = OrderAmount.newQuote(Amount.fromString(quoteAmount.toFixed(quoteDecimals)));
    const minPrice = Amount.fromString(fromPrice.toFixed(quoteDecimals));
    const midPrice = Amount.fromString(formState.currentPrice.toFixed(quoteDecimals));
    const maxPrice = Amount.fromString(toPrice.toFixed(quoteDecimals));
    const orderVariant = OrderVariant.newAddLiquidity(orderAmount, minPrice, midPrice, maxPrice, false);
    await createOrder(baseAsset.asset, quoteAsset.asset, orderVariant);
  }

  async function createBuyOrder (baseAsset: MappedDexTradingBalance, quoteAsset: MappedDexTradingBalance, quoteAmount: number, fromPrice: number, toPrice:number) {
    const buyOrder = OrderVariant.newAddBuyLiquidity(Amount.fromString(quoteAmount.toFixed(quoteAsset.asset.decimals)), Amount.fromFloat(fromPrice), Amount.fromFloat(toPrice), false);
    await createOrder(baseAsset.asset, quoteAsset.asset, buyOrder);
  }

  async function createSellOrder (baseAsset: MappedDexTradingBalance, quoteAsset: MappedDexTradingBalance, baseAmount: number, fromPrice: number, toPrice:number) {
    const sellOrder = OrderVariant.newAddSellLiquidity(Amount.fromString(baseAmount.toFixed(baseAsset.asset.decimals)), Amount.fromFloat(fromPrice), Amount.fromFloat(toPrice), false);
    await createOrder(baseAsset.asset, quoteAsset.asset, sellOrder);
  }

  async function cancelExistingOrder (orderId: string) {
    let successfulCancel;
    try {
      successfulCancel = await cancelOrder(orderId);
    } catch (error) {
      console.debug(error);
    }
    fetchOrders();
    swapStore.fetchTradingBalances();
    if (successfulCancel) {
      toast.add(alertBuilder.success({
        title: t('liquidity.order.success.cancel.title'),
        description: t('liquidity.order.success.cancel.description', { id: orderId })
      }));
    } else {
      toast.add(alertBuilder.error({
        title: t('liquidity.order.errors.cancel.title'),
        description: t('liquidity.order.errors.cancel.description', { id: orderId })
      }));
    }
  }

  /**
     * Steps
     */
  const allSteps = reactive<Record<LiquiditySteps, Step>>({
    [LiquiditySteps.ASSETS]: {
      id: LiquiditySteps.ASSETS,
      name: t('liquidity-page.steps.assets.title'),
      status: StepStatus.Current,
      isFirst: true
    },
    [LiquiditySteps.RANGE]: {
      id: LiquiditySteps.RANGE,
      name: t('liquidity-page.steps.range.title'),
      status: StepStatus.Upcoming
    },
    [LiquiditySteps.AMOUNT]: {
      id: LiquiditySteps.AMOUNT,
      name: t('liquidity-page.steps.amount.title'),
      status: StepStatus.Upcoming,
      isLast: true
    }
  });

  const steps = computed<Step[]>(() => {
    const _steps = [allSteps.ASSETS, allSteps.RANGE];

    return [..._steps, allSteps.AMOUNT];
  });

  const currentStep = computed<Step>(() => {
    return steps.value.find(step => step.status === StepStatus.Current) || steps.value[0];
  });

  const currentStepIndex = computed<number>(() => {
    return (
      steps.value.findIndex(step => step.status === StepStatus.Current) || 0
    );
  });

  /**
     * Navigation
     */
  const canGoNext = computed(() => {
    //
    const currentStepId = currentStep.value?.id;
    if (currentStepId === LiquiditySteps.ASSETS) {
      return (!isNil(formState.selectedBaseAsset) && !isNil(formState.selectedQuoteAsset));
    } else if (currentStepId === LiquiditySteps.RANGE) {
      return (
        formState.selectedFromPrice > 0 &&
        formState.selectedToPrice > 0 &&
        formState.selectedToPrice > formState.selectedFromPrice
      );
    } else if (currentStepId === LiquiditySteps.AMOUNT) {
      const { success, errors } = _validateForm();
      const isSuccess = success && !errors;
      return isSuccess;
    }
    return false;
  });

  function _validateForm () {
    return validateForm<z.infer<typeof computedSchemaObject.value>>(
      formState,
      computedSchemaObject.value,
      t
    );
  }

  function onNext () {
    if (!canGoNext.value) {
      return;
    }
    const currentStepId = currentStep.value?.id;
    if (currentStepId === LiquiditySteps.ASSETS) {
      allSteps[currentStepId].status = StepStatus.Complete;
      allSteps[LiquiditySteps.RANGE].status = StepStatus.Current;
    }
    if (currentStepId === LiquiditySteps.RANGE) {
      allSteps[currentStepId].status = StepStatus.Complete;
      allSteps[LiquiditySteps.AMOUNT].status = StepStatus.Current;
    }
    return true;
  }

  function onBack () {
    const currentStepId = currentStep.value.id;

    if (currentStepId === LiquiditySteps.RANGE) {
      allSteps.RANGE.status = StepStatus.Upcoming;
      allSteps.ASSETS.status = StepStatus.Current;
    } else if (currentStepId === LiquiditySteps.AMOUNT) {
      allSteps.AMOUNT.status = StepStatus.Upcoming;
      allSteps.RANGE.status = StepStatus.Current;
    }
  }

  watch(tradingBalances, selectAssets, { immediate: true, deep: true });

  // Watchers
  watch(
    () => [watchedValues.value.fromAssetBalance, watchedValues.value.toAssetBalance],
    async ([fromAsset, toAsset]) => {
      if (!fromAsset || !toAsset) {
        return;
      }

      try {
        const marketInfo = await initMarket(toAsset.asset, fromAsset.asset);
        formState.selectedBaseAsset = findAsset(tradingBalances.value, marketInfo.base);
        formState.selectedQuoteAsset = findAsset(tradingBalances.value, marketInfo.quote);
        const _orderbook = await getOrderbook(marketInfo.base, marketInfo.quote);
        const currentAskPrice = _orderbook?.asks[0]?.minPrice?.asFloat() || 0;
        const currentBidPrice = _orderbook?.bids[0]?.maxPrice?.asFloat() || 0;
        formState.currentPrice = (currentAskPrice + currentBidPrice) / 2;
      } catch (error) {
        toast.add(alertBuilder.error({
          title: t('liquidity.order.errors.init-pair.title'),
          description: t('liquidity.order.errors.init-pair.description')
        }));
        console.error('Error initializing pairs:', error);
      }
    }, { deep: true });

  function adjustAmounts (baseAmountChanged: boolean) {
    const { selectedFromPrice, selectedToPrice, currentPrice, selectedBaseAmount, selectedQuoteAmount, selectedBaseAsset, selectedQuoteAsset } = formState;

    if (orderType.value !== ORDER_TYPES.BOTH) {
      return;
    }

    const baseDecimals = selectedBaseAsset?.asset.decimals || 18;
    const quoteDecimals = selectedQuoteAsset?.asset.decimals || 18;

    const minPrice = Amount.fromString(selectedFromPrice.toFixed(quoteDecimals));
    const midPrice = Amount.fromString(currentPrice.toFixed(quoteDecimals));
    const maxPrice = Amount.fromString(selectedToPrice.toFixed(quoteDecimals));

    if (baseAmountChanged) {
      if (selectedToPrice > currentPrice && selectedBaseAmount > 0 && adjustingBase.value) {
        const orderAmount = OrderAmount.newBase(Amount.fromString(selectedBaseAmount.toFixed(baseDecimals)));
        const order = OrderVariant.newAddLiquidity(orderAmount, minPrice, midPrice, maxPrice, false).toOrder(baseDecimals, quoteDecimals);
        formState.selectedQuoteAmount = order.liquidityOrder?.remainingQuoteAmount.asFloat() || 0;
      }
    } else if (selectedFromPrice < currentPrice && selectedQuoteAmount > 0 && !adjustingBase.value) {
      const orderAmount = OrderAmount.newQuote(Amount.fromString(selectedQuoteAmount.toFixed(quoteDecimals)));
      const order = OrderVariant.newAddLiquidity(orderAmount, minPrice, midPrice, maxPrice, false).toOrder(baseDecimals, quoteDecimals);
      formState.selectedBaseAmount = order.liquidityOrder?.remainingBaseAmount.asFloat() || 0;
    }
  }

  return {
    steps,
    onBack,
    onNext,
    canGoNext,
    currentStep,
    openCreateLiquidityModal,
    isCreateLiquidityModalOpen,
    closeCreateLiquidityModal,
    formState,
    secondLiquidityAssetBalancesOptions,
    tradingBalances,
    setOrder,
    fetchOrders,
    currentStepIndex,
    orders,
    adjustAmounts,
    resetLiquidityModal,
    orderType,
    computedSchemaObject,
    lastFetched,
    cancelExistingOrder,
    isLoading,
    adjustingBase
  };
});
