import { defineStore } from 'pinia';
import { useFuse } from '@vueuse/integrations/useFuse';
import { getTransactions, type Transaction } from 'hydra-node';
import {
  filterFns,
  getFirstOperation,
  getNetworkName
} from '~/utils';
import { useCurrencyStore } from '~/stores/shared/currency';
import { PRO_APP_ROUTES } from '~/constants';
import { PiniaStoresId } from '~/enums';

export const useTransactionsHistoryStore = defineStore(PiniaStoresId.TransactionsHistoryStore, () => {
  const { resolveFiatValue } = useCurrencyStore();

  const filters = reactive({
    layer: null,
    types: [],
    statuses: [],
    networks: [],
    globalSearch: ''
  });
  const transactions = ref<Transaction[]>([]);
  const hasFetched = ref(false);
  const lastFetched = ref(0);

  const hasActiveFilters = computed(() => {
    return Object.values(filters).some(x => x?.length);
  });
  const filteredTransactions = computed(() => {
    let dataToFilter = transactions.value;

    if (filters.globalSearch.length) {
      const { results } = useFuse(
        filters.globalSearch,
        dataToFilter as Transaction[],
        {
          fuseOptions: {
            keys: [
              {
                name: 'name',
                getFn: transaction =>
                  getFirstOperation(transaction)?.asset?.name || ''
              },
              {
                name: 'symbol',
                getFn: transaction =>
                  getFirstOperation(transaction)?.asset?.symbol || ''
              }
            ],
            threshold: 0.3
          }
        }
      );

      dataToFilter = results.value.map(x => x.item);
    }

    return dataToFilter
      .map((row) => {
        let isMatchingLayer = true;
        let isMatchingStatus = true;
        let isMatchingType = true;
        let isMatchingNetwork = true;

        if (filters.layer !== null) {
          isMatchingLayer = filterFns.equals(filters.layer, row.layer);
        }

        if (filters?.statuses?.length) {
          isMatchingStatus = filterFns.arrIncludesSome(
            filters.statuses.map(x => x.value),
            [row.status]
          );
        }

        if (filters?.networks?.length) {
          isMatchingNetwork = filterFns.arrIncludesSome(
            filters.networks.map(x => x.label),
            [getNetworkName(row.network)]
          );
        }

        if (filters?.types?.length) {
          isMatchingType = filterFns.arrIncludesSome(
            filters.types.map(x => x.value),
            [row.type]
          );
        }

        if (
          isMatchingLayer &&
          isMatchingStatus &&
          isMatchingType &&
          isMatchingNetwork
        ) {
          return row as Transaction;
        }
        return undefined;
      })
      .filter(Boolean) as Transaction[];
  });

  function findTransactionById (id: string):Transaction {
    return transactions.value.find(x => x.id === id);
  }
  function resetFilters () {
    filters.layer = null;
    filters.types = [];
    filters.statuses = [];
    filters.networks = [];
    filters.globalSearch = '';
  }
  async function fetchTransactions () {
    const _transactions = await getTransactions();
    hasFetched.value = true;
    lastFetched.value = Date.now();
    transactions.value = _transactions ?? [];
  }

  function sumUpValuesInFiat (tx: Transaction) {
    if (!tx) { return 0; }

    let sum = 0;
    for (const [asset, amount] of tx.assetsAmountsToShow) {
      sum += resolveFiatValue(amount.asFloat(), asset);
    }
    return sum;
  }

  function toDetails (tx: Transaction) {
    if (!tx) { return undefined; }

    return {
      name: PRO_APP_ROUTES.transactions.children.details.name,
      params: {
        id: tx.id
      }
    };
  }

  return {
    transactions,
    hasFetched,
    lastFetched,
    filters,
    hasActiveFilters,
    filteredTransactions,
    findTransactionById,
    resetFilters,
    fetchTransactions,
    sumUpValuesInFiat,
    toDetails
  };
});
